<template>
  <!-- <svg :class="svgClass" aria-hidden="true"> -->
  <!-- <use :xlink:href="iconName" /> -->
  <!-- </svg> -->
  <component :is="componentName" :height="size" :width="size" />
</template>

<script>
import {
  BoxIcon,
  DiscIcon,
  DollarSignIcon,
  SunIcon,
  MoonIcon,
  TrendingUpIcon,
  PieChartIcon,
  UserIcon,
  RepeatIcon,
} from 'vue-feather-icons'
var componentMap = {
  box: 'BoxIcon',
  disc: 'DiscIcon',
  dollar: 'DollarSignIcon',
  sun: 'SunIcon',
  moon: 'MoonIcon',
  up: 'TrendingUpIcon',
  pie: 'PieChartIcon',
  user: 'UserIcon',
  bridge: 'RepeatIcon',
}
export default {
  components: {
    BoxIcon,
    DiscIcon,
    DollarSignIcon,
    SunIcon,
    MoonIcon,
    TrendingUpIcon,
    PieChartIcon,
    UserIcon,
    RepeatIcon,
  },
  name: 'FeatherIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    small: { type: Boolean },
    large: { type: Boolean },
  },
  computed: {
    size() {
      if (this.small) {
        return '16px'
      }
      if (this.large) {
        return '28px'
      }
      return '22px'
    },
    componentName() {
      if (this.name in componentMap) {
        return componentMap[this.name]
      }
      return 'BoxIcon'
    },
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
  },
}
</script>

<style scoped>
.svg-icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  overflow: hidden;
}
</style>
