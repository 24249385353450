<template>
  <v-dialog v-model="computedShowTxLoadModal" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <crypto-icon :token="tx ? tx.symbol : ''" :name="tx ? tx.title : ''" />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-progress-circular
            v-if="['confirm', 'pending'].includes(txStatus)"
            :size="100"
            :width="5"
            color="#adbec4"
            indeterminate
          ></v-progress-circular>
          <v-icon v-if="txStatus === 'fail'" color="red" :style="{ fontSize: '150px' }">mdi-close</v-icon>
          <v-icon v-if="txStatus === 'ok'" color="green" :style="{ fontSize: '150px' }">mdi-check</v-icon>
        </div>
        <div class="d-flex justify-center py-5">
          <span class="text-caption">{{ txMsg }}</span>
        </div>
        <v-alert v-if="addTimelockQueueTip" type="warning" dense>{{ addTimelockQueueTip }}</v-alert>
        <section class="d-flex justify-center pt-8">
          <v-btn :disabled="!txHash" depressed block color="secondary" @click="viewTx">View On Explorer</v-btn>
        </section>
        <section v-if="txTitle === 'ccipTransfer'" class="d-flex justify-center pt-4">
          <v-btn :disabled="!txHash" depressed block color="secondary" @click="viewCCIPTx">View On CCIPExplorer</v-btn>
        </section>
        <section v-if="txTitle === 'transfer'" class="d-flex justify-center pt-4">
          <v-btn :disabled="!txHash" depressed block color="secondary" @click="viewLayerzeroTx"
            >View On Layerzeroscan</v-btn
          >
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { CCIP_EXPLORER, LAYERZERO_SCAN, LAYERZERO_TESTNET_SCAN, UnknowError } from '../../../constants'

const AutoCloseTitles = ['Deposit', 'RepayBorrow', 'Enter Markets', 'Exit Markets', 'Payback', 'Liquidate', 'Claim']

export default {
  name: 'TxLoadModal',
  props: {},
  data() {
    return {
      isAddedToTimelock: false
    }
  },
  computed: {
    ...mapState('tx', ['txInfos']),
    ...mapState('wallet', ['currentNetwork', 'web3Provider']),
    tx() {
      return this.txInfos.find((t) => t.show)
    },
    computedShowTxLoadModal: {
      get() {
        return this.tx ? this.tx.show : false
      },
      set() {
        this.close()
      },
    },
    txStatus() {
      return this.tx ? this.tx.status : ''
    },
    txHash() {
      return this.tx ? this.tx.hash : null
    },
    txMsg() {
      return this.tx ? this.tx.msg : ''
    },
    txSymbol() {
      return this.tx ? this.tx.symbol : ''
    },
    txTitle() {
      return this.tx ? this.tx.title : ''
    },
    addTimelockQueueTip() {
      let info = ''
      if (this.tx && this.tx.timelocked) {
        info = 'Due to pool limit, This transaction may be added to your timelock queue as a protocol security measure.'
      }

      return info
    },
  },
  watch: {
    txStatus(newVal, oldVal) {
      if (newVal === 'pending' && oldVal === 'confirm') {
        
        if (AutoCloseTitles.includes(this.txTitle)) {
          this.close()
        }
      }
    },
    txMsg(val) {
      if (val === UnknowError) {
        this.close()
      }
    }
  },
  methods: {
    ...mapMutations({
      setTxInfos: 'tx/setTxInfos',
      clearTxInfos: 'tx/clearTxInfos',
    }),
    viewTx() {
      window.open(`${this.currentNetwork.blockExplorer}/tx/${this.txHash}`)
    },
    viewCCIPTx() {
      window.open(`${CCIP_EXPLORER}/tx/${this.txHash}`)
    },
    viewLayerzeroTx() {
      const baseURL = this.currentNetwork.isTestnet ? LAYERZERO_TESTNET_SCAN : LAYERZERO_SCAN
      window.open(`${baseURL}/tx/${this.txHash}`)
    },
    close() {
      // this.setTxInfos({ id: this.tx.id, show: false })
      this.isAddedToTimelock = false
      this.clearTxInfos()
    },
  },
}
</script>
<style lang="scss" scoped></style>
