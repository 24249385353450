import BigNumber from 'bignumber.js'
import { EXP_SCALE, getNetworkConfig, getGroups, UTILIZATION_RATE, YEAR_MS } from '../../constants'
import * as Sumer from '@meterio/sumer-js'
import {
  getHypoLiquidity,
  getSDRTokenMetaDataAll,
  getSDRTokenBalancesAll,
  getUnderlyingPrices,
  getEqAssetGroup,
  getRatePerBlock,
  getMarkets,
} from '../../api'
import { getTokenPairs } from '@meterio/sumer-js'
import dayjs from '../../utils/dayjs'
import { ethers } from 'ethers'

const namespaced = true

const state = {
  marketList: [],
  marketStats: {
    allMarketsDepositVal: '0',
    allMarketsBorrowVal: '0',
    allMarketsSynIssuedVal: '0', // FIXME: update me
  },
  hypoLiquidityMap: {},

  /**
   * sdrToken => {
      cToken: '0x50eD264F4e46836E4a06d3C4474d99a8BE679779',
      balanceOf: '0',
      borrowBalanceCurrent: '0',
      balanceOfUnderlying: '0',
      tokenBalance: '0',
      tokenAllowance: '0'
    }
   */
  balanceMap: {},

  /**
     * sdrToken => {
        cToken: '0xaE1d4F4F3D203753Cb9eb9181CAAFbb99e529A92',
        exchangeRateCurrent: '10000000000000000',
        supplyRatePerBlock: '0',
        borrowRatePerBlock: '23782343987',
        reserveFactorMantissa: '0',
        totalBorrows: '0',
        totalReserves: '0',
        totalSupply: '380000000000',
        totalCash: '3800000000',
        isListed: true,
        collateralFactorMantissa: '800000000000000000',
        underlyingAssetAddress: '0x7622c9E4bF223cc53c7228bE3A79073b95dD55e6',
        cTokenDecimals: '8',
        underlyingDecimals: '6'
      }
    */
  metadataMap: {},

  /**
     * sdrToken =>
       price (deicmals: 36 - underlyingDecimals)
     */
  priceMap: {},

  /**
     * sdrToken => {
        groupId,
        groupName,
        inGroupCTokenRateMantissa,
        inGroupSuTokenRateMantissa,
        interGroupCTokenRateMantissa,
        interGroupSuTokenRateMantissa
        }
     */
  eqAssetMap: {},

  /**
   * sdrToken => {
      liquidity: '0',
      shortfall: '0'
   }
   */

  netAPY: '%',
  remainBorrowLimitMax: '',
  remainBorrowLimitMin: '',
  remainMintLimitMin: '',
  remainMintLimitMax: '',
  /**
   * {
   *  name: string; // JumpRateModelV2,WhitePaperInterestRateModel,ZeroInterestRateModel
   *  address: string; // contract address
   *  tokens: string[]; // reflect underlying tokens
   *  baseRatePerBlock: BigNumber | string;
   *  jumpMultiplierPerBlock: BigNumber | string | undifined
   *  multiplierPerBlock: BigNumber | string
   *  kink: number
   * }
   */
  rateModel: [],

  marketListLoading: true,

  userAgreements: [],

  errorInfo: '',

  redeemFaceValuePreviewResult: {},
  cMinBorrowAmount: '0',
  minSuBorrowValue: new BigNumber(0),
}

const getters = {
  rateModelBySymbol: (state) => (symbol) => {
    for (const r of state.rateModel) {
      if (r.tokens.includes(symbol)) {
        return r
      }
    }
  },
  marketBySDRToken: (state) => (sdrToken) => {
    for (const m of state.marketList) {
      if (m.sdrTokenAddress.toLowerCase() === sdrToken.toLowerCase()) {
        return m
      }
    }
  },
  userDeposits: (state) => state.marketList.filter((m) => m.supplied.isGreaterThan(0)),
  userBorrows: (state) => state.marketList.filter((m) => m.borrowed.isGreaterThan(0)),
  userTotalCollateralVal: (state) => {
    let sum = new BigNumber(0)
    for (const m of state.marketList) {
      sum = sum.plus(m.suppliedVal)
    }

    const x = state.marketList
      .filter((m) => m.enteredMarket)
      .reduce((sum, m) => sum.plus(m.suppliedVal), new BigNumber(0))
    return x.toFixed()
  },
  userSuppliedTotalVal: (state) => {
    let sum = new BigNumber(0)
    for (const m of state.marketList) {
      sum = sum.plus(m.suppliedVal)
    }
    // console.log(state.marketList)
    const x = state.marketList.reduce((sum, m) => sum.plus(m.suppliedVal), new BigNumber(0))
    return x
  },

  userBorrowedTotalVal: (state) => state.marketList.reduce((sum, m) => sum.plus(m.borrowedVal), new BigNumber(0)),

  balanceBySDRToken: (state) => (sdrToken) => {
    return state.balanceMap[sdrToken.toLowerCase()]
  },
  metadataBySDRToken: (state) => (sdrToken) => {
    return state.metadataMap[sdrToken.toLowerCase()]
  },
  priceBySDRToken: (state) => (sdrToken) => {
    return state.priceMap[sdrToken.toLowerCase()]
  },
  eqAssetBySDRToken: (state) => (sdrToken) => {
    return state.eqAssetMap[sdrToken.toLowerCase()]
  },
  hypoLiquidityBySDRToken: (state) => (sdrToken) => {
    return state.hypoLiquidityMap[sdrToken.toLowerCase()]
  },
}

const mutations = {
  setMinSuBorrowValue(state, value) {
    state.minSuBorrowValue = value
  },
  setMarketList(state, marketList) {
    state.marketList = marketList
  },
  setMarketStats(state, stats) {
    state.marketStats = stats
  },

  setBalanceMap(state, balanceMap) {
    state.balanceMap = balanceMap
  },
  setMetadataMap(state, metadataMap) {
    state.metadataMap = metadataMap
  },
  setPriceMap(state, priceMap) {
    state.priceMap = priceMap
  },
  setEqAssetMap(state, eqAssetMap) {
    state.eqAssetMap = eqAssetMap
  },
  setHypoLiquidityMap(state, hypoLiquidityMap) {
    state.hypoLiquidityMap = hypoLiquidityMap
  },
  setNetAPY(state, netAPY) {
    state.netAPY = netAPY
  },
  setRemains(state, { remainBorrowLimitMin, remainBorrowLimitMax, remainMintLimitMin, remainMintLimitMax }) {
    state.remainBorrowLimitMax = remainBorrowLimitMax.toFixed(2, 1)
    state.remainBorrowLimitMin = remainBorrowLimitMin.toFixed(2, 1)
    state.remainMintLimitMin = remainMintLimitMin.toFixed(2, 1)
    state.remainMintLimitMax = remainMintLimitMax.toFixed(2, 1)
  },
  setRateModel(state, rateModel) {
    state.rateModel = rateModel
  },
  clearMarkets(state) {
    state.marketList = []
    state.marketStats = {
      allMarketsDepositVal: '0',
      allMarketsBorrowVal: '0',
      allMarketsSynIssuedVal: '0',
    }
  },
  setMarketListLoading(state, loading) {
    state.marketListLoading = loading
  },

  setUserAgreements(state, userAgreements) {
    state.userAgreements = userAgreements
  },

  setErrorInfo(state, info) {
    state.errorInfo = info
  },

  setRedeemFaceValuePreviewResult(state, sdrSymbol, result) {
    console.log('result:', result)
    state.redeemFaceValuePreviewResult[sdrSymbol] = result
  },

  setCMinBorrowAmount(state, cMinBorrowAmount) {
    state.cMinBorrowAmount = cMinBorrowAmount
  },
}

const actions = {
  async updateCMinBorrowAmount({ rootState, commit }, { underlySymbol, collateralSymbol, mintAmount }) {
    try {
      const decimals = Sumer.getDecimals(rootState.wallet.sumer._network.name, collateralSymbol)
      const result = await rootState.wallet.sumer.calcBorrowAmountForProtectedMint(
        underlySymbol,
        collateralSymbol,
        mintAmount,
      )
      const correct = new BigNumber(result[0]).div(`1e${decimals}`).toNumber()
      commit('setCMinBorrowAmount', correct)
      console.log('cMinBorrowAmount', correct)
      return correct
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
  async updateRedeemFaceValuePreview({ rootState, commit, dispatch }, { underlySymbol, sdrSymbol, amount, decimals }) {
    console.log('entered redeem face value trial')
    console.log(`underly: ${underlySymbol}, sdrSymbol:${sdrSymbol}, amount: ${amount}`)

    try {
      const redeemer = rootState.wallet.account
      console.log('redeemer: ', redeemer)
      const result = await rootState.wallet.sumer.redeemFaceValuePreview(
        redeemer,
        sdrSymbol,
        BigNumber(amount).times(`1e${decimals}`).toFixed(0),
      )
      console.log('RESULT: ', result)
      commit('setRedeemFaceValuePreviewResult', sdrSymbol, result)
      console.log('result:', result)
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },
  async updateHypoLiquidityMap({ commit, rootState }) {
    let hypoLiquidityMap = {}
    const { sumer, account, contracts } = rootState.wallet
    const supportedPairs = getTokenPairs(sumer._network.name)
    const batchGet = []
    const sdrTokenAddrs = []
    for (const pair of supportedPairs) {
      const sdrTokenAddr = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)
      const hypoLiquidity = getHypoLiquidity(contracts, account, sdrTokenAddr, '0', '0')
      batchGet.push(hypoLiquidity)
      sdrTokenAddrs.push(sdrTokenAddr.toLowerCase())
      hypoLiquidityMap[sdrTokenAddr.toLowerCase()] = {
        sdrSymbol: pair.ctokenSym,
      }
    }

    const batchLiquidity = await Promise.all(batchGet)
    console.log('batchLiquidity', batchLiquidity)
    for (let i = 0; i < sdrTokenAddrs.length; i++) {
      const sdrTokenAddr = sdrTokenAddrs[i]
      let liquidity = new BigNumber(0)
      let shortfall = BigNumber(0)
      if (batchLiquidity[i].length == 3) {
        liquidity = BigNumber(String(batchLiquidity[i][1])).div(1e18)
        shortfall = BigNumber(String(batchLiquidity[i][2])).div(1e18)
      } else if (batchLiquidity[i].length == 2) {
        liquidity = BigNumber(String(batchLiquidity[i][0])).div(1e18)
        shortfall = BigNumber(String(batchLiquidity[i][1])).div(1e18)
      }
      hypoLiquidityMap[sdrTokenAddr] = {
        ...hypoLiquidityMap[sdrTokenAddr],
        liquidity,
        shortfall,
      }
    }

    console.log('hypoLiquidityMap', hypoLiquidityMap)
    commit('setHypoLiquidityMap', hypoLiquidityMap)
  },
  async updateNetAPY({ commit, rootState, state }) {
    const { sumer } = rootState.wallet
    const bmap = state.balanceMap
    let principle = new BigNumber(0)
    let earning = new BigNumber(0)

    for (const cTokenAddr in bmap) {
      const b = bmap[cTokenAddr]
      const underlyDecimals = Sumer.util.getUnderlyDecimals(b.sdrSymbol, sumer._network.name)

      const metaData = getters.metadataBySDRToken(state)(cTokenAddr)
      if (!metaData) continue
      const price = getters.priceBySDRToken(state)(cTokenAddr) || 1
      const depositInUSD = new BigNumber(b.balanceOfUnderlying.toString()).times(price).div(`1e${underlyDecimals}`)
      const borrowInUSD = new BigNumber(b.borrowBalanceCurrent.toString()).times(price).div(`1e${underlyDecimals}`)

      const blocksPerYear = Sumer.getNetworkBlocksPerYear(sumer._network.name)

      const depositAPY = new BigNumber(String(metaData.supplyRatePerBlock)).div(EXP_SCALE).times(blocksPerYear)

      const borrowAPY = new BigNumber(String(metaData.borrowRatePerBlock)).div(EXP_SCALE).times(blocksPerYear)
      earning = earning.plus(depositInUSD.times(depositAPY)).minus(borrowInUSD.times(borrowAPY))
      principle = principle.plus(depositInUSD)
    }

    const netAPY = earning.isZero() ? '0' : earning.div(principle).toFixed(4)
    console.log('netAPY', netAPY)
    commit('setNetAPY', netAPY)
  },
  async updateBorrowLimits({ commit, rootState, state }) {
    let borrowLimits = []
    let mintLimits = []
    const { balanceMap } = state
    for (const cTokenAddr in balanceMap) {
      const metaData = getters.metadataBySDRToken(state)(cTokenAddr)
      const hypo = getters.hypoLiquidityBySDRToken(state)(cTokenAddr)

      if (metaData.isCToken) {
        borrowLimits.push(hypo.liquidity)
      } else {
        mintLimits.push(hypo.liquidity)
      }
    }

    let remainBorrowLimitMin = new BigNumber(0)
    let remainBorrowLimitMax = new BigNumber(0)
    let remainMintLimitMin = new BigNumber(0)
    let remainMintLimitMax = new BigNumber(0)

    for (const l of borrowLimits) {
      if (remainBorrowLimitMax.isLessThan(l)) {
        remainBorrowLimitMax = l
      }
      if (remainBorrowLimitMin.isEqualTo(0)) {
        remainBorrowLimitMin = l
      } else if (remainBorrowLimitMin.isGreaterThan(l)) {
        remainBorrowLimitMin = l
      }
    }
    for (const l of mintLimits) {
      if (remainMintLimitMin.isEqualTo(0)) {
        remainMintLimitMin = l
      } else if (remainMintLimitMin.isGreaterThan(l)) {
        remainMintLimitMin = l
      }
      if (remainMintLimitMax.isLessThan(l)) {
        remainMintLimitMax = l
      }
    }

    commit('setRemains', { remainBorrowLimitMin, remainBorrowLimitMax, remainMintLimitMin, remainMintLimitMax })
  },
  async updateInterestModel({ rootState, commit }) {
    const { web3Provider, currentNetwork } = rootState.wallet
    const rateModel = []
    for (const rateContract of currentNetwork.rateModel) {
      const res = await getRatePerBlock(web3Provider, rateContract)
      rateModel.push(res)
    }
    commit('setRateModel', rateModel)
  },
  async updateBalanceMap({ commit, rootState }) {
    const { sumer, contracts } = rootState.wallet
    const supportedPairs = getTokenPairs(sumer._network.name)
    const ctokenAddrs = supportedPairs
      .map((p) => {
        return Sumer.util.getAddress(p.ctokenSym, sumer._network.name)
      })
      .filter((a) => !!a)
    const balancesAll = await getSDRTokenBalancesAll(contracts, ctokenAddrs, rootState.wallet.account)
    console.log('balancesAll', balancesAll)
    const balanceMap = balancesAll.reduce((map, obj) => {
      let sdrSymbol = ''
      for (const pair of supportedPairs) {
        const sdrTokenAddr = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)
        if (sdrTokenAddr.toLowerCase() === obj.cToken.toLowerCase()) {
          sdrSymbol = pair.ctokenSym
          break
        }
      }
      map[obj.cToken.toLowerCase()] = {
        ...obj,
        sdrSymbol,
      }
      return map
    }, {})
    console.log('balanceMap: ', balanceMap)
    commit('setBalanceMap', balanceMap)
  },
  async updateMetadataMap({ commit, rootState, state }) {
    const { sumer, contracts } = rootState.wallet
    const supportedPairs = getTokenPairs(sumer._network.name)
    const ctokenAddrs = supportedPairs
      .map((p) => {
        return Sumer.util.getAddress(p.ctokenSym, sumer._network.name)
      })
      .filter((a) => !!a)
    const metadataAll = await getSDRTokenMetaDataAll(contracts, ctokenAddrs)
    const metadataMap = metadataAll.reduce((map, obj) => {
      let sdrSymbol = ''
      for (const pair of supportedPairs) {
        const sdrTokenAddr = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)
        if (sdrTokenAddr.toLowerCase() === obj.cToken.toLowerCase()) {
          sdrSymbol = pair.ctokenSym
          break
        }
      }
      map[obj.cToken.toLowerCase()] = {
        ...obj,
        sdrSymbol,
      }
      return map
    }, {})
    console.log('metadataMap: ', metadataMap)
    commit('setMetadataMap', metadataMap)
  },
  async updatePriceMap({ rootState, commit }) {
    const { sumer, contracts } = rootState.wallet
    const supportedPairs = Sumer.getTokenPairs(sumer._network.name)

    // const priceMap = {}
    const tokens = []
    for (const pair of supportedPairs) {
      const sdrTokenAddress = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)

      const underlyDecimals = Sumer.util.getUnderlyDecimals(pair.underlySym, sumer._network.name)

      tokens.push({
        address: sdrTokenAddress,
        decimals: underlyDecimals,
      })

      // const tokenPrice = await getUnderlyingPrice(sumer, sdrTokenAddress, underlyDecimals)
      // priceMap[sdrTokenAddress.toLowerCase()] = String(tokenPrice)
      // console.log(`got price for ${pair.ctokenSym}/${pair.underlySym}: ${String(tokenPrice)} USD`)
    }
    // get underlying prices
    console.log('contracts', contracts, 'tokens', tokens)
    const prices = await getUnderlyingPrices(contracts, tokens)
    console.log('prices', prices)
    commit('setPriceMap', prices)
  },
  async updateEqAssetMap({ rootState, commit }) {
    const { sumer, contracts } = rootState.wallet
    const supportedPairs = Sumer.getTokenPairs(sumer._network.name)

    const eqAssetMap = {}
    const batchGetMarkets = []
    const sdrTokenAddrs = []
    for (const pair of supportedPairs) {
      const sdrTokenAddress = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)
      // const eqAssetGroup = await getEqAssetGroup(contracts, sdrTokenAddress)
      batchGetMarkets.push(getMarkets(contracts, sdrTokenAddress))
      sdrTokenAddrs.push(sdrTokenAddress.toLowerCase())
      eqAssetMap[sdrTokenAddress.toLowerCase()] = {
        // ...eqAssetGroup,
        sdrSymbol: pair.ctokenSym,
      }
    }

    const batchMarkets = (await Promise.all(batchGetMarkets)).map((bm) => bm.toObject())
    console.log('batchMarkets', batchMarkets)
    const batchGetAssetGroup = []
    for (const market of batchMarkets) {
      batchGetAssetGroup.push(getEqAssetGroup(contracts, market.assetGroupId))
    }
    const batchAssetGroup = await Promise.all(batchGetAssetGroup)
    console.log('batchAssetGroup', batchAssetGroup)
    for (let i = 0; i < sdrTokenAddrs.length; i++) {
      const sdrTokenAddr = sdrTokenAddrs[i]
      eqAssetMap[sdrTokenAddr] = {
        ...eqAssetMap[sdrTokenAddr],
        ...batchAssetGroup[i],
      }
    }

    console.log('eqAssetMap:', eqAssetMap)
    commit('setEqAssetMap', eqAssetMap)
  },

  async getUserAgreements({ rootState, commit }) {
    const { sumer, account, multiProvider } = rootState.wallet

    const timelockAddr = Sumer.getAddress(sumer._network.name, 'Timelock')

    const timelockContract = new Sumer._ethers.Contract(timelockAddr, Sumer.abi.Timelock, multiProvider)
    const res = await timelockContract.userAgreements(account)
    console.log('userAgreements res', res)
    const estimateReleaseTime = await Promise.all(
      res.map((item) => timelockContract.getMinWaitInSeconds(item.agreementId)),
    )
    console.log('estimateReleaseTime', estimateReleaseTime)
    const formatData = res.map((_item, idx) => {
      const item = _item.toObject()
      // console.log('userAgreements item', item)
      const sdrSymbol = Sumer.getName(sumer._network.name, item.cToken)
      const underlySymbol = sdrSymbol.substring(3)
      const decimals = Sumer.getDecimals(sumer._network.name, underlySymbol)
      const releaseTimestamp = new BigNumber(String(item.timestamp))
        .plus(estimateReleaseTime[idx])
        .times(1000)
        .toNumber()
      const releaseTime = dayjs(releaseTimestamp).fromNow()
      return {
        ...item,
        underlySymbol,
        formatAmount: new BigNumber(String(item.underlyAmount)).div(`1e${decimals}`).toFixed(),
        createdTime: dayjs(new BigNumber(String(item.timestamp)).times(1000).toNumber()).fromNow(),
        releaseTimestamp,
        releaseTime,
      }
    })
    console.log('userAgreements', formatData)
    commit('setUserAgreements', formatData)
  },
  async updateMarketList({ rootState, rootGetters, state, commit }) {
    commit('setMarketListLoading', false)
    const markets = []

    let sumDepositVal = new BigNumber(0)
    let sumBorrowVal = new BigNumber(0)
    let sumSynIssuedVal = new BigNumber(0)

    const { chainId } = rootState.wallet

    for (const tb of Object.values(state.balanceMap)) {
      const sdrTokenAddr = tb.cToken.toLowerCase()
      const metaData = getters.metadataBySDRToken(state)(sdrTokenAddr)
      const price = getters.priceBySDRToken(state)(sdrTokenAddr) || 1
      // const groupAsset = getters.eqAssetBySDRToken(state)(sdrTokenAddr)
      // console.log('groupAsset',groupAsset)

      if (!metaData) continue

      const sdrSymbol = tb.sdrSymbol
      const underlyAddr = metaData.underlyingAssetAddress.toLowerCase()
      const sumer = rootState.wallet.sumer
      const underlyName = Sumer.getName(sumer._network.name, underlyAddr)
      const underlySymbol = Sumer.getName(sumer._network.name, underlyAddr)

      const underlyDecimals = `1e${metaData.underlyingDecimals}`
      const sdrTokenDecimals = `1e${metaData.cTokenDecimals}`

      const blocksPerYear = Sumer.getNetworkBlocksPerYear(sumer._network.name)

      const depositAPY = new BigNumber(String(metaData.supplyRatePerBlock))
        .div(EXP_SCALE)
        .times(blocksPerYear)
        .toFixed()
      ;('%')
      const underlyBalance = new BigNumber(String(tb.tokenBalance)).div(underlyDecimals)
      const underlyBalanceVal = underlyBalance.times(price)

      const borrowAPY = new BigNumber(String(metaData.borrowRatePerBlock)).div(EXP_SCALE).times(blocksPerYear).toFixed()
      ;('%')

      const exRateMantissa = new BigNumber(String(metaData.exchangeRateCurrent)).div(1e18)
      const reserveFactorMantissa = new BigNumber(String(metaData.reserveFactorMantissa)).div(1e18)

      const totalDeposit = new BigNumber(String(metaData.totalSupply)).div(sdrTokenDecimals).times(exRateMantissa)
      const totalDepositVal = totalDeposit.times(price)
      const totalBorrow = new BigNumber(String(metaData.totalBorrows)).div(underlyDecimals)
      const totalBorrowVal = totalBorrow.times(price)
      const totalCash = new BigNumber(String(metaData.totalCash))
        .minus(String(metaData.totalReserves))
        .div(underlyDecimals)
      const totalCashVal = totalCash.times(price)
      const totalReserves = new BigNumber(String(metaData.totalReserves)).div(underlyDecimals)
      const totalReservesVal = totalReserves.times(price)

      const earned = '0' // TODO: need to calculate earned interest
      const accrued = '0' // TODO: need to calcuate accrued interest

      const cTokenBalance = new BigNumber(String(tb.balanceOf)).div(sdrTokenDecimals)
      const supplied = new BigNumber(String(tb.balanceOfUnderlying)).div(underlyDecimals)
      const suppliedVal = supplied.times(price)
      const borrowed = new BigNumber(String(tb.borrowBalanceCurrent)).div(underlyDecimals)
      const borrowedVal = borrowed.times(price)

      sumDepositVal = sumDepositVal.plus(totalDepositVal)
      if (!tb.isCToken) {
        sumSynIssuedVal = sumSynIssuedVal.plus(totalBorrowVal)
      } else {
        sumBorrowVal = sumBorrowVal.plus(totalBorrowVal)
      }
      const enteredMarket = rootGetters['wallet/enteredBySDRToken'](sdrTokenAddr)

      const hypoLiquidity = getters.hypoLiquidityBySDRToken(state)(sdrTokenAddr)

      let rateModels = {}

      try {
        rateModels = Sumer.getRateModals(sumer._network.name)
        console.log('RATE MODELS:', rateModels)
      } catch (e) {
        console.log('get rateModels err', e)
      }

      const rateModel = rateModels.find((r) => r.tokens.includes(underlySymbol)) || {}
      // console.log('rateModel', rateModel)
      const utilizationArray = UTILIZATION_RATE.map((u) => (u * 100).toFixed(0))
      let depositAPYArray = []
      let borrowAPYArray = []

      if (rateModel.name === 'JumpRateModelV2') {
        for (const util of UTILIZATION_RATE) {
          if (util <= rateModel.kink) {
            const borrowAPY = new BigNumber(String(rateModel.baseRatePerYear)).plus(
              BigNumber(String(rateModel.multiplierPerYear)).times(util),
            )

            const depositAPY = borrowAPY
              .times(BigNumber(EXP_SCALE).minus(String(metaData.reserveFactorMantissa)).div(EXP_SCALE))
              .times(util)

            borrowAPYArray.push(borrowAPY.times(100).toFixed(2))
            depositAPYArray.push(depositAPY.times(100).toFixed(2))
          } else {
            const borrowAPY = new BigNumber(String(rateModel.jumpMultiplierPerYear))
              .times(`${util - rateModel.kink}`)
              .plus(BigNumber(String(rateModel.multiplierPerYear)).times(rateModel.kink))
              .plus(String(rateModel.baseRatePerYear))

            const depositAPY = borrowAPY
              .times(BigNumber(EXP_SCALE).minus(String(metaData.reserveFactorMantissa)).div(EXP_SCALE))
              .times(util)

            borrowAPYArray.push(borrowAPY.times(100).toFixed(2))
            depositAPYArray.push(depositAPY.times(100).toFixed(2))
          }
        }
      } else if (rateModel.name === 'WhitePaperInterestRateModel') {
        for (const util of UTILIZATION_RATE) {
          const borrowAPY = new BigNumber(String(rateModel.baseRatePerYear)).plus(
            BigNumber(String(rateModel.multiplierPerYear)).times(util),
          )
          const depositAPY = borrowAPY
            .times(BigNumber(EXP_SCALE).minus(String(metaData.reserveFactorMantissa)).div(EXP_SCALE))
            .times(util)

          borrowAPYArray.push(borrowAPY.times(100).toFixed(2))
          depositAPYArray.push(depositAPY.times(100).toFixed(2))
        }
      } else {
        depositAPYArray = new Array(UTILIZATION_RATE.length).fill(0)
        borrowAPYArray = new Array(UTILIZATION_RATE.length).fill(0)
      }

      const borrowCap = new BigNumber(String(metaData.borrowCap)).div(underlyDecimals) // await getMarketBorrowCap(sumer, sdrTokenAddr)
      const depositCap = new BigNumber(String(metaData.depositCap)).div(underlyDecimals) // await getMerketDepositCap(sumer, underlyAddr)
      const borrowCapVal = borrowCap.times(price)
      const depositCapVal = depositCap.times(price)

      const InterestPaidPerDay = totalDeposit.times(depositAPY).div(365)

      const heteroliquidationFee = new BigNumber(String(metaData.heteroLiquidationIncentive)).div(1e18).times(100) // await getLiquidationIncentiveMantissa(sumer)
      const homoliquidationFee = new BigNumber(String(metaData.homoLiquidationIncentive)).div(1e18).times(100)
      const sutokenliquidationFee = new BigNumber(String(metaData.sutokenLiquidationIncentive)).div(1e18).times(100)

      const group = getGroups(chainId).find(g => g.id === Number(metaData.groupId))

      markets.push({
        // info
        sdrSymbol,
        underlyName,
        underlySymbol,
        underlyDecimals: metaData.underlyingDecimals,
        underlyAddr: underlyAddr,
        ctokenSymbol: 'sdr' + underlySymbol,
        ctokenAddr: sdrTokenAddr,
        ctokenDecimals: metaData.cTokenDecimals,

        sdrTokenAddress: sdrTokenAddr,
        sdrTokenDecimals: metaData.cTokenDecimals,

        tokenAllowance: BigNumber(String(tb.tokenAllowance)).div(underlyDecimals),
        price,
        isCToken: metaData.isCToken,
        isCEther: metaData.isCEther,

        // params
        depositAPY,
        borrowAPY,

        // facts already calibrated by decimals
        totalDeposit,
        totalDepositVal,
        totalBorrow,
        totalBorrowVal,
        totalCash,
        totalCashVal,
        totalReserves,
        totalReservesVal,
        reserveFactorMantissa,

        exRate: exRateMantissa,

        //
        earned,
        accrued,

        // user facts calibrated by decimals
        underlyBalance, // underlying token balance for current wallet
        underlyBalanceVal, // in USD
        cTokenBalance,
        supplied,
        suppliedVal, // in USD
        borrowed,
        borrowedVal, // in USD
        enteredMarket,
        hypoLiquidity,

        // interest model
        utilizationArray,
        depositAPYArray,
        borrowAPYArray,
        kink: rateModel.kink,

        // info in market page
        borrowCap,
        borrowCapVal,
        depositCap,
        depositCapVal,
        InterestPaidPerDay,
        heteroliquidationFee,
        homoliquidationFee,
        sutokenliquidationFee,

        // group asset
        groupId: metaData.groupId,
        groupName: group?.name,
        interRate: metaData.interRate,
        intraRate: metaData.intraRate,
        mintRate: metaData.mintRate,
        interMintAllowed: metaData.interMintAllowed,
        discountRate: metaData.discountRate,
      })
    }

    console.log('market list: ', markets)
    commit('setMarketList', markets)
    commit('setMarketListLoading', false)
    commit('setMarketStats', {
      allMarketsDepositVal: sumDepositVal.toFixed(2, 1),
      allMarketsBorrowVal: sumBorrowVal.toFixed(2, 1),
      allMarketsSynIssuedVal: sumSynIssuedVal.toFixed(2, 1),
    })
  },
  async getMinSuBorrowValue({ rootState, commit }) {
    const { contracts } = rootState.wallet
    let value = '0'
    try {
      const globalConfig = await contracts.Comptroller.globalConfig()
      value = Number(globalConfig.minSuBorrowValue.toString()) * 1e18
    } catch (e) {
      value = await contracts.Comptroller.minSuBorrowValue()
    }
    console.log('minSuBorrowValue', value)
    commit('setMinSuBorrowValue', new BigNumber(value).div(1e18))
  },
  async initMarket({ dispatch, commit, rootState }, isClear) {
    commit('loading/setSystemLoading', true, { root: true })
    const { sumer, chainId } = rootState.wallet
    // console.log('sumer', sumer)
    const network = getNetworkConfig(chainId)

    if (network.nameInCompoundjs !== sumer._network.name) {
      return window.location.reload()
    }
    if (isClear) {
      commit('clearMarkets')
    }
    commit('setErrorInfo', '')

    try {
      await Promise.all([
        // dispatch('wallet/updateBalances', null, { root: true }),
        dispatch('wallet/updateLimits', null, { root: true }),
        dispatch('updateMetadataMap'),
        dispatch('updateBalanceMap'),
        dispatch('updatePriceMap'),
        dispatch('updateHypoLiquidityMap'),
        // dispatch('updateEqAssetMap'),
      ])
      await dispatch('updateMarketList')
      await dispatch('updateNetAPY')
      await dispatch('updateBorrowLimits')
      await dispatch('getUserAgreements')
      await dispatch('getMinSuBorrowValue')
    } catch (e) {
      console.log('ERROR: ', { ...e })
      let msg = ''
      if (e.error) {
        if (e.error.event === 'changed') {
          return dispatch(
            'wallet/initWallet',
            {
              accounts: [
                {
                  address: ethers.ZeroAddress,
                },
              ],
              chainId: 82,
              provider: new ethers.JsonRpcProvider('https://rpc.meter.io'),
              icon: '',
            },
            { root: true },
          )
        }

        msg = `${e.error.code} ${e.error.method ? 'at ' + e.error.method : ''} ${e.error.reason ? ':' : ''} ${
          e.error.reason || ''
        }`
      } else {
        console.log(e)
        msg = e.message
      }
      commit('setErrorInfo', msg)
    } finally {
      commit('loading/setSystemLoading', false, { root: true })
    }
  },
}

export const market = { namespaced, state, getters, mutations, actions }
