import { _ethers } from '@meterio/sumer-js'
import * as Sumer from '@meterio/sumer-js'

const addrs = {
  82: '',
  83: '0x874da8a98798ce3ef058a5b012d49858b9dbaaf2',
}

export const calculateComp = async (holder, provider, chainId) => {
  const addr = addrs[Number(chainId)]

  try {
    if (addr) {
      const c = new _ethers.Contract(addr, Sumer.abi.ERC20MinterBurnerPauser, provider)
      const res = await c.calculateComp(holder)
      return res
    } else {
      return 0
    }
  } catch (e) {
    console.log('calculateComp', e)
    return 0
  }
}

export const claimSumer = async (holder, provider, chainId) => {
  const addr = addrs[Number(chainId)]
  try {
    if (addr) {
      const c = new _ethers.Contract(addr, Sumer.abi.ERC20MinterBurnerPauser, provider)
      const tx = await c['claimSumer(address)'].apply(null, [holder])
      await tx.wait()
    }
  } catch (e) {
    console.log('claimSumer', e)
    return
  }
}
