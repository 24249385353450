<template>
  <v-app>
    <v-overlay
      v-if="showOverlay"
      :z-index="100"
      :value="true"
    >
      <v-alert type="info" rounded="lg" color="primary">upgrading</v-alert>
    </v-overlay>

    

    <system-loading />
    <deposit-modal />
    <borrow-modal />
    <!-- <mint-modal /> -->
    <!-- <payback-modal /> -->
    <faucet-modal />
    <switch-network-modal />
    <enter-markets-modal />
    <mint-syn-modal />
    <tx-load-modal />
    <redemption-modal />
    <protected-mint-syn-modal />
    <!-- <liquidate-modal /> -->

    <top-bar />

    <v-snackbars :objects.sync="snackbarsObj">
      <template v-slot:action="{ index }">
        <v-btn text @click="viewScan(index)">view scan</v-btn>
      </template>
    </v-snackbars>

    <v-main class="grey lighten-3">
      <div class="outer-container px-md-4">
        <v-container>
          <v-alert v-if="errorInfo" type="warning" rounded="lg">{{ errorInfo }}</v-alert>
          <router-view />
        </v-container>
      </div>
      <div class="loop-tag" v-if="whiteListRoute.includes(routeName) && isSupportNetwork">
        <v-chip class="ma-2" color="#987f61" text-color="white" link @click="doInitMarket">
          <span v-if="!systemLoading">{{ loopTagText }}</span>
          <span v-else><v-progress-circular size="18" width="3" indeterminate></v-progress-circular></span>
        </v-chip>
      </div>
    </v-main>
    <bottom-bar />
  </v-app>
</template>

<script>
import TopBar from './top.vue'
import BottomBar from './bottom.vue'

import SystemLoading from '@/components/SystemLoading'
import DepositModal from '../Modal/DepositModal'
import BorrowModal from '../Modal/BorrowModal'
// import MintModal from '../Modal/MintModal'
// import PaybackModal from '../Modal/PaybackModal'
import FaucetModal from '../Modal/FaucetModal'
import SwitchNetworkModal from '../Modal/SwitchNetworkModal'
import EnterMarketsModal from '../Modal/EnterMarketsModal'
import MintSynModal from '../Modal/MintSynModal'
import TxLoadModal from '../Modal/TxLoadModal'
import RedemptionModal from '../Modal/RedemptionModal'
import ProtectedMintSynModal from '../Modal/ProtectedMintSynModal'
// import LiquidateModal from '../Modal/LiquidateModal'

import VSnackbars from 'v-snackbars'
import { mapActions, mapState } from 'vuex'
import { upgradingNetwork } from '../../constants'
import { ethers } from 'ethers'
import mixins from '../../mixins'

const LOOP_TIME = 30

export default {
  name: 'Layout',
  mixins: [mixins],
  components: {
    TopBar,
    BottomBar,

    SystemLoading,
    DepositModal,
    BorrowModal,
    // MintModal,
    // PaybackModal,
    FaucetModal,
    SwitchNetworkModal,
    EnterMarketsModal,
    MintSynModal,
    TxLoadModal,
    RedemptionModal,
    ProtectedMintSynModal,
    // LiquidateModal,

    'v-snackbars': VSnackbars,
  },
  data() {
    return {
      overlay: true,
      snackbarsObj: [],
      snackbarsAlreadyShow: [],
      loopTime: LOOP_TIME,
      routeName: '',
      whiteListRoute: ['Dashboard', 'TokenList', 'TokenDetail'],
    }
  },
  watch: {
    txInfos(tx) {
      const txOk = tx.filter((t) => t.status === 'ok')
      for (const t of txOk) {
        if (!this.snackbarsAlreadyShow.includes(t.hash)) {
          const message = `${t.title} ${t.amount ? t.amount : ''} ${t.symbol} success`
          this.snackbarsAlreadyShow.push(t.hash)
          this.snackbarsObj.push({
            message,
            hash: t.hash,
            top: true,
            right: true,
            color: 'primary',
            timeout: 10000,
          })
        }
      }
    },
    '$route.name': {
      handler(val) {
        this.routeName = val
      },
      immediate: true,
    },
    loopTime(val) {
      if (val === 0) {
        this.initMarket()
      }
    },
  },
  computed: {
    ...mapState('wallet', ['currentNetwork', 'isSupportNetwork', 'account']),
    ...mapState('tx', ['txInfos']),
    ...mapState('market', ['errorInfo']),
    ...mapState('loading', ['systemLoading']),
    isTxPending() {
      return this.txInfos.find((t) => t.status === 'pending')
    },
    loopTagText() {
      if (this.mobile) {
        return `${this.loopTime}s`
      }
      return `refresh in ${this.loopTime}s`
    },
    showOverlay() {
      if (this.currentNetwork && upgradingNetwork.includes(this.currentNetwork.networkId)) {
        return true
      }
      return false
    },
  },
  mounted() {
    setInterval(() => {
      this.loopTime--
      if (
        this.loopTime === -1 ||
        this.isTxPending ||
        this.systemLoading ||
        !this.isSupportNetwork ||
        !this.whiteListRoute.includes(this.routeName) ||
        this.showOverlay ||
        this.account ===  ethers.ZeroAddress
      ) {
        this.loopTime = LOOP_TIME
      }
    }, 1000)
  },
  methods: {
    ...mapActions({
      initMarket: 'market/initMarket',
    }),
    viewScan(index) {
      const data = this.snackbarsObj[index]
      window.open(`${this.currentNetwork.blockExplorer}/tx/${data.hash}`)
    },
    doInitMarket() {
      if (this.systemLoading || this.isTxPending || !this.isSupportNetwork) return
      this.initMarket()
    },
  },
}
</script>

<style lang="scss">
.v-btn {
  text-transform: none;
}
.outer-container {
  height: 100%;
  background: url(../../assets/images/bg.png) left center no-repeat;
  // background-color: darken(#f8f5f2, 25%);
  background-size: cover;
  background-attachment: fixed;
}
.loop-tag {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  // opacity: 0.8;
}
.v-sheet {
  box-shadow: rgba(58, 78, 88, 0.09) 0px 0px 16px !important;
  border: 1px solid #eaddcf;
}
</style>
