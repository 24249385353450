<template>
  <v-dialog v-if="showRedemption" v-model="computedShow" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <crypto-icon :token="market.underlySymbol" :name="market.underlyName" action="" />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="my-4">
        <!-- Amount Section -->
        <amount-input v-model="amount" label="Redemption Amount" :maxVal="maxVal" :focus="true"></amount-input>

        <table v-if="this.redeemFaceValuePreviewResult && this.redeemFaceValuePreviewResult[this.redemptionToken]">
          <tr>
            <td>Redeem</td>
            <td>Receive</td>
            <td>Receive Value (USD)</td>
            <td>Protocol Seize Value (USD)</td>
          </tr>
          <tr v-for="(r, index) in this.redeemFaceValuePreviewResult[this.redemptionToken].details" :key="index">
            <td>{{ r.redeemAmount }} {{ market.underlySymbol }}</td>
            <td>{{ r.seizeAmount }} {{ r.seizeSymbol }}</td>
            <td>{{ r.seizeVal }}</td>
            <td>{{ r.protocolSeizeVal }}</td>
          </tr>
        </table>

        <v-btn @click="redeemFaceValuePreview" depressed large block elevation="0">
          Redeem {{ market.underlySymbol }} Trial
        </v-btn>
        <v-btn class="mt-2" color="#D4A85F" @click="redeemFaceValue" depressed large block elevation="0">
          Redeem {{ market.underlySymbol }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BigNumber from 'bignumber.js'

import CryptoIcon from '@/components/CryptoIcon'
export default {
  name: 'Redemption',
  components: {
    CryptoIcon,
  },
  props: {},
  data() {
    return {
      amount: '0',
      maxVal: '100000',
    }
  },
  computed: {
    ...mapState('modal', ['showRedemption', 'redemptionToken']),
    ...mapState('market', ['redeemFaceValuePreviewResult']),
    ...mapGetters('market', ['marketBySDRToken']),

    computedShow: {
      get() {
        return this.showRedemption
      },
      set() {
        this.close()
      },
    },
    market() {
      return this.marketBySDRToken(this.redemptionToken)
    },
  },
  methods: {
    ...mapActions({
      redeemFaceValueAction: 'tx/redeemFaceValueAction',
      updateRedeemFaceValuePreview: 'market/updateRedeemFaceValuePreview',
    }),
    close() {
      this.$store.commit('modal/HIDE_REDEMPTION')
    },
    async redeemFaceValue() {
      try {
        if (this.amount.trim() === '' && Number(this.amount) > 0) {
          return (this.notEmpty = true)
        }
        this.notEmpty = false

        console.log('market', this.market)

        const result = this.redeemFaceValuePreviewResult[this.redemptionToken]
        console.log(`result: `, result)
        await this.redeemFaceValueAction({
          underlySymbol: this.market.underlySymbol,
          amount: new BigNumber(this.amount).toFixed(),
          decimals: this.market.sdrTokenDecimals,
          providers: result.providers,
          deadline: result.deadline,
          signature: result.signature,
        })
      } catch (e) {
        console.log('redeemFaceValue error: ', e)
      }
    },
    async redeemFaceValuePreview() {
      try {
        if (this.amount.trim() === '' && Number(this.amount) > 0) {
          return (this.notEmpty = true)
        }
        this.notEmpty = false

        console.log('market', this.market)
        console.log({
          underlySymbol: this.market.underlySymbol,
          sdrSymbol: this.market.sdrSymbol,
          amount: new BigNumber(this.amount).toFixed(),
          decimals: this.market.sdrTokenDecimals,
        })
        await this.updateRedeemFaceValuePreview({
          underlySymbol: this.market.underlySymbol,
          sdrSymbol: this.market.sdrSymbol,
          amount: new BigNumber(this.amount).toFixed(),
          decimals: this.market.sdrTokenDecimals,
        })
      } catch (e) {
        console.log('redeemFaceValue error: ', e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
