import axios from "axios"

const BASE_URL = 'https://app.sumer.money/api'

export const getSummary = async () => {
  return await axios.get(`${BASE_URL}/metadata`)
}

export const getSummaryByChainid = async (chainId) => {
  return await axios.get(`${BASE_URL}/${chainId}/metadata`)
}