<template>
  <!-- Amount Section -->
  <v-text-field
    :class="classes"
    color="primary"
    outlined
    persistent-hint
    @input="handleInput"
    :hint="hint"
    :value="content"
    :label="label"
    :autofocus="focus"
    dense
    :solo="solo"
    :hide-details="hideDetails"
  >
    <template v-slot:append>
      <v-btn :disabled="!Number(maxVal)" text color="primary" @click="maxClicked">
        <tooltip-label :tooltip="maxBtnTitle" size="small">
          <span>{{ maxText }}</span>
        </tooltip-label>
      </v-btn>
    </template>
  </v-text-field>
</template>
<script>
import { BigNumber } from 'bignumber.js'

export default {
  name: 'AmountInput',
  props: {
    value: { type: String, require: true },
    focus: { type: Boolean, default: false, require: true },
    label: { type: String, require: false, default: 'Amount' },
    maxVal: { type: String, required: true },
    maxText: { type: String, required: false, default: 'MAX' },
    hint: { type: String, default: '' },
    maxBtnTitle: { type: String, default: '' },
    hideDetails: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    classes: { type: String, default: 'mt-2 mb-6'}
  },
  data() {
    return {
      content: this.value,
    }
  },
  watch: {
    value(v) {
      this.content = v
    },
    content: {
      handler: function (val) {
        // console.log('update input: ', val)
        const newNum = BigNumber(val)
        const maxNum = BigNumber(this.maxVal)
        // console.log('>>> ', newNum, maxNum, newNum > maxNum)
        let newContent = '0'
        if (Number.isNaN(newNum)) {
          newContent = '0'
        } else if (newNum.isGreaterThan(maxNum)) {
          // newContent = this.maxVal
          newContent = val
        } else if (!BigNumber(this.content).isEqualTo(newNum)) {
          newContent = val
        } else {
          newContent = val
        }

        this.$nextTick(() => {
          // console.log('set content = ', newContent)
          this.content = newContent
        })
        // console.log('set value = ', newContent)
        this.$emit('input', newContent)
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleInput(val) {
      this.content = val
    },
    maxClicked() {
      this.$nextTick(() => {
        this.content = this.maxVal
      })
      this.$emit('input', this.maxVal)
      this.$emit('maxClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--dense ::v-deep .v-input__append-inner {
  margin-top: 2px !important;
}
</style>
