<template>
  <div class="pt-6">
    <div class="d-flex justify-space-between font-weight-bold">
      <div>Mint Capacity:</div>
      <div>{{ accountLiquidityBalance }}({{ accountLiquidity | usd }})</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      v-model="amount"
      label="Mint Amount"
      :maxVal="safeLimitBalance"
      maxText="Safe Limit"
      :hint="amountHint"
      hideDetails
      :focus="true"
    ></amount-input>

    <div class="d-flex justify-space-between my-3">
      <div>Wallet balance</div>
      <div>{{ walletBalance }} {{ value.underlySymbol }}</div>
    </div>

    <connect-wallet></connect-wallet>

    <div
      v-if="minMintAmount.gt(0)"
      class="cursor-pointer d-flex justify-space-between mb-1 text-error"
      @click="inputBorrowMini"
    >
      <div>Minimum Mint:</div>
      <div>{{ computedMinMintAmount }} {{ value.underlySymbol }}</div>
    </div>
    <v-btn color="accent" @click="mint" depressed large block :disabled="mintBtnDisabled" elevation="0">
      Mint {{ value.underlySymbol }}
    </v-btn>

    <section v-if="canProtectMint" class="mt-5">
      <custom-select
        label="Borrow collateral to handle redemptions"
        :value="borrowToken"
        :options="assetOptions"
        @selected="tokenSelected"
      />
      <amount-input
        v-model="borrowAmount"
        label="Borrow Amount"
        maxVal="0"
        :focus="false"
        :hideDetails="true"
        classes="my-2"
      ></amount-input>
      <div class="d-flex justify-space-between align-center">
        <div>Recommended minimum protection:</div>
        <div>{{ computedBorrowRange }} {{ borrowToken.symbol }}</div>
      </div>

      <v-btn
        class="mt-4"
        color="#D4A85F"
        @click="protectedMint"
        :disabled="proMintBtnDisabled"
        depressed
        large
        block
        elevation="0"
      >
        Protected Mint {{ value.underlySymbol }}
      </v-btn>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BigNumber } from 'bignumber.js'
import { formatNumReadable, formatNumReadable1, formatNum } from '../../../utils'
import { getAccountSafeLimit, getMintedSuToken } from '../../../api'
import { getDecimals, getTokenPairs } from '@meterio/sumer-js'

export default {
  name: 'MintTab',
  props: {
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      amount: '',
      safeLimit: 0,
      borrowToken: {},
      assetOptions: [],
      borrowAmount: '',

      minBorrowAmount: '0',
      maxBorrowAmount: '0',
      alreadyMinted: '0',
    }
  },
  mounted() {
    console.log('this.value mint: ', this.value) 
    getAccountSafeLimit(this.sumer, this.account, this.value.sdrTokenAddress).then((res) => {
      this.safeLimit = new BigNumber(String(res)).div(1e18).toFixed()
    })

    getMintedSuToken(this.sumer, this.account, this.value.sdrTokenAddress).then((res) => {
      console.log('getMintedSuToken', res)
      this.alreadyMinted = new BigNumber(res).div(`1e${String(this.value.underlyDecimals)}`)
    })

    const pairs = getTokenPairs(this.sumer._network.name)
    const currGroupId = pairs.find((p) => p.underlySym === this.value.underlySymbol)?.assetGroupId
    const options = pairs
      .filter((p) => !p.underlySym.startsWith('su') && p.assetGroupId === currGroupId)
      .map((p) => p.underlySym)
      .filter((p) => p != this.value.underlySymbol)
      .map((p) => ({ name: p, symbol: p }))

    this.borrowToken = options[0]
    this.assetOptions = options
  },
  watch: {
    amount(val) {
      console.log('HEYHYEHYE', val)
      if (Number(val) > 0) {
        console.log(this.borrowToken.symbol)
        console.log(this.amount)
        this.calcBorrowAmount(this.borrowToken.symbol, this.amount)
      }
    },
    borrowToken(val) {
      if (Number(this.amount) > 0) {
        this.calcBorrowAmount(this.borrowToken.symbol, this.amount)
      }
    },
    assetOptions(val) {
      if (val && val.length) {
        this.borrowToken = val[0]
      }
    },
  },
  computed: {
    ...mapState('wallet', ['sumer', 'account', 'contracts']),
    ...mapState('market', ['minSuBorrowValue']),
    canProtectMint() {
      if (BigNumber(this.minBorrowAmount) > 0) {
        return true
      }
      return false
    },
    minMintAmount() {
      return new BigNumber(this.minSuBorrowValue).div(this.value.price).minus(this.alreadyMinted)
    },
    computedMinMintAmount() {
      return formatNumReadable1(this.minMintAmount, 5)
    },
    computedBorrowRange() {
      // return `${formatNumReadable1(this.minBorrowAmount, 2)} - ${formatNumReadable1(this.maxBorrowAmount, 2)}`
      const precision = new BigNumber(this.minBorrowAmount).toFixed().split('.')[1]?.length || 2
      return `${formatNumReadable1(this.minBorrowAmount, precision)}`
    },
    mintBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) > Number(this.accountLiquidityBalance) ||
        Number(this.amount) <= 0 ||
        this.minMintAmount.gt(this.amount) ||
        (Number(this.minBorrowAmount) > 0 && !this.value?.interMintAllowed) 
      )
    },
    proMintBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) <= 0 ||
        Number(this.borrowAmount) < Number(this.minBorrowAmount) ||
        Number(this.borrowAmount) > Number(this.maxBorrowAmount)
      )
    },
    accountLiquidity() {
      return this.value.hypoLiquidity.liquidity
    },
    accountLiquidityBalance() {
      return formatNumReadable1(new BigNumber(this.accountLiquidity).div(this.value.price), 4)
    },
    amountHint() {
      return `Mint Capacity: ${formatNumReadable(this.accountLiquidityBalance)} ${this.value.underlySymbol}`
    },
    safeLimitBalance() {
      return new BigNumber(this.safeLimit).div(this.value.price).toFixed()
    },
    walletBalance() {
      return formatNumReadable(this.value.underlyBalance)
    },
  },
  methods: {
    ...mapActions({
      mintAction: 'tx/mintAction',
      protectedMintAction: 'tx/protectedMintSynAction',
    }),
    inputBorrowMini() {
      this.amount = this.minMintAmount.toFixed()
    },
    async calcBorrowAmount(collateralSymbol, amount) {
      console.log('collateralSymbol', collateralSymbol, amount)
      if (!collateralSymbol) return
      const result = await this.sumer.calcBorrowAmountForProtectedMint(
        this.value.underlySymbol,
        collateralSymbol,
        amount,
      )
      console.log('result', result)
      const collateralDecimals = getDecimals(this.sumer._network.name, collateralSymbol)
      this.minBorrowAmount = new BigNumber(result[0]).div(`1e${collateralDecimals}`)
      this.maxBorrowAmount = new BigNumber(result[1]).div(`1e${collateralDecimals}`)
      this.borrowAmount = this.minBorrowAmount.toFixed()
    },
    async protectedMint() {
      await this.protectedMintAction({
        underlySymbol: this.value.underlySymbol,
        collateralSymbol: this.borrowToken.symbol,
        borrowAmount: this.borrowAmount,
        mintAmount: this.amount,
      })

      this.borrowAmount = ''
      this.calcBorrowAmount(this.borrowToken.symbol, this.amount)
    },
    tokenSelected(data) {
      this.borrowToken = data
    },
    async mint() {
      try {
        if (this.amount.trim() === '' && Number(this.amount) > 0) {
          return (this.notEmpty = true)
        }
        this.notEmpty = false

        await this.mintAction({
          underlySymbol: this.value.underlySymbol,
          sdrTokenAddress: this.value.sdrTokenAddress,
          amount: new BigNumber(this.amount).toFixed(),
          decimals: this.value.ctokenDecimals,
        })
      } catch (e) {
        console.log('mint error: ', e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
