<template>
  <div></div>
</template>
<script>
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import gnosisModule from '@web3-onboard/gnosis'
import okxWallet from '@web3-onboard/okx'
import metamask from '@web3-onboard/metamask'
import Vue from 'vue'

export const WalletBoardBus = new Vue()

const injected = injectedModule()
const walletConnect = walletConnectModule({
  projectId: 'f1a20cc2a388265ee87c64d0eb8a7bf4',
  dappUrl: 'https://app.sumer.money',
})
const coinbaseWalletSdk = coinbaseWalletModule()

const options = {
  whitelistedDomains: [
    /^https:\/\/safe\.meter\.io/,
    /^https:\/\/gnosis-safe\.io$/,
    /^https:\/\/app\.safe\.global$/,
    /^https:\/\/safe\.global$/,
  ],
}
const gnosis = gnosisModule(options)

const okx = okxWallet()

const metamaskWallet = metamask({options: {
  extensionOnly: false,
  dappMetadata: {
    name: 'Sumer.Money'
  }
}})

export default {
  name: 'WalletBoard',
  props: {
    chains: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      onboard: null,
      unsubscribe: null,
    }
  },
  created() {
    this.initOnboard()
  },
  mounted() {
    WalletBoardBus.$on('setChain', this.setChain)
    WalletBoardBus.$on('connect', this.connect)
    WalletBoardBus.$on('disconnect', this.disconnectWallet)
  },
  methods: {
    async initOnboard() {
      this.onboard = Onboard({
        wallets: [injected, walletConnect, coinbaseWalletSdk, gnosis, okx, metamaskWallet],
        chains: this.chains,
        accountCenter: {
          desktop: {
            enabled: false,
          },
          mobile: {
            enabled: false,
          }
        },
        appMetadata: {
          name: 'Sumer.Money',
          icon: '<svg></svg>',
          description: 'Highly capital efficient liquidity infra that creates money multipliers, allowing users to earn competitive DeFi yields across chains'
        }
      })

      if (this.onboard) {

        // this.connect()

        this.subscribe()
      }
    },
    async connect(flag = false) {
      const previouslyConnectedWallets = JSON.parse(
        window.localStorage.getItem('connectedWallets')
      )
      console.log('previouslyConnectedWallets', previouslyConnectedWallets)
      
      if (this.onboard) {
        // await this.onboard.connectWallet()
        if (previouslyConnectedWallets && previouslyConnectedWallets.length && !flag) {
          // Connect the most recently connected wallet (first in the array)
          // await onboard.connectWallet({ autoSelect: previouslyConnectedWallets[0] })

          // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load
          await this.onboard.connectWallet({
            autoSelect: { label: previouslyConnectedWallets[0], disableModals: true }
          })
        } else {
          await this.onboard.connectWallet()
        }
      }
    },
    updateState(wallets) {
      if (wallets.length) {
        this.$emit('wallets', wallets)
      } else {
        this.$emit('disconnected')
        // this.connect()
      }
    },
    subscribe() {
      const state = this.onboard.state.select("wallets")
      const { unsubscribe } = state.subscribe(wallets => {
        console.log('state update: ', wallets);
        this.updateState(wallets)

        const connectedWallets = wallets.map(({ label }) => label)
        window.localStorage.setItem(
          'connectedWallets',
          JSON.stringify(connectedWallets)
        )
        
        // this.disconnectOtherWallet()
      })
      this.unsubscribe = unsubscribe
    },
    setChain(chainId) {
      if (this.onboard) {
        try {
          this.onboard.setChain({ chainId: `0x${Number(chainId).toString(16)}`})
        } catch(e) {
          console.log('e..', e)
        }
      }
    },
    disconnectWallet() {
      if (this.onboard) {
        // const wallets = this.onboard.state.get().wallets
        // if (wallets.length) {
        //   // this.unsubscribe()
        //   this.onboard.disconnectWallet({ label: wallets[0].label })
        // }
        // disconnect the first wallet in the wallets array
        const [primaryWallet] = this.onboard.state.get().wallets
        this.onboard.disconnectWallet({ label: primaryWallet.label })
      }
    },
    disconnectOtherWallet() {
      if (this.onboard) {
        const wallets = this.onboard.state.get().wallets
        if (wallets.length > 1) {
          this.onboard.disconnectWallet({ label: wallets[1].label })
        }
      }
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>

<style lang="scss" scoped></style>
