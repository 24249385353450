export default {
  computed: {
    mobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    iconWidth() {
      const breakpointName = this.$vuetify.breakpoint.name
      switch (breakpointName) {
        case 'xs': return '70'
        case 'sm': return '150'
        case 'md': return '150'
        case 'lg': return '80'
        case 'xl': return '150'
      }
    }
  }
}