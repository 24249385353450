export const SHOW_DEPOSIT = 'SHOW_DEPOSIT'
export const HIDE_DEPOSIT = 'HIDE_DEPOSIT'
export const SHOW_MINT = 'SHOW_MINT'
export const HIDE_MINT = 'HIDE_MINT'
export const SHOW_MINT_SYN = 'SHOW_MINT_SYN'
export const HIDE_MINT_SYN = 'HIDE_MINT_SYN'
export const SHOW_PAYBACK = 'SHOW_PAYBACK'
export const HIDE_PAYBACK = 'HIDE_PAYBACK'
export const SHOW_BORROW = 'SHOW_BORROW'
export const HIDE_BORROW = 'HIDE_BORROW'
export const SHOW_COLLATERAL = 'SHOW_COLLATERAL'
export const HIDE_COLLATERAL = 'HIDE_COLLATERAL'
export const SHOW_FAUCET = 'SHOW_FAUCET'
export const HIDE_FAUCET = 'HIDE_FAUCET'
export const SHOW_CHECK_NETWORK = 'SHOW_CHECK_NETWORK'
export const HIDE_CHECK_NETWORK = 'HIDE_CHECK_NETWORK'
export const SHOW_LIQUIDATE = 'SHOW_LIQUIDATE'
export const HIDE_LIQUIDATE = 'HIDE_LIQUIDATE'
export const SHOW_REDEMPTION = 'SHOW_REDEMPTION'
export const HIDE_REDEMPTION = 'HIDE_REDEMPTION'
export const SHOW_PROTECTED_MINT_SYN = 'SHOW_PROTECTED_MINT_SYN'
export const HIDE_PROTECTED_MINT_SYN = 'HIDE_PROTECTED_MINT_SYN'

const namespaced = true
const state = {
  showDeposit: false,
  showBorrow: false,
  showCollateral: false,
  showMint: false,
  showPayback: false,
  showMintSyn: false,
  mintTab: 0,
  showFaucet: false,
  showCheckNetwork: false,
  showLiquidate: false,
  showRedemption: false,
  showProtectedMintSyn: false,
  liquidateAddress: '',
  depositSDRToken: '',
  mintSDRToken: '',
  paybackSDRToken: '',
  mintSynSDRToken: '',
  borrowSDRToken: '',
  collateralSDRToken: '',
  redemptionToken: '',
  protectedMintSynToken: '',
}

const getters = {}

const mutations = {
  [SHOW_LIQUIDATE](state, { liquidateAddress }) {
    state.showLiquidate = true
    state.liquidateAddress = liquidateAddress
  },
  [HIDE_LIQUIDATE](state) {
    state.showLiquidate = false
  },
  [SHOW_REDEMPTION](state, { redemptionToken }) {
    state.showRedemption = true
    state.redemptionToken = redemptionToken
  },
  [HIDE_REDEMPTION](state) {
    state.showRedemption = false
  },
  [SHOW_PROTECTED_MINT_SYN](state, { protectedMintSynToken }) {
    state.showProtectedMintSyn = true
    state.protectedMintSynToken = protectedMintSynToken
  },
  [HIDE_PROTECTED_MINT_SYN](state) {
    state.showProtectedMintSyn = false
  },
  [SHOW_DEPOSIT](state, { sdrTokenAddress }) {
    state.showDeposit = true
    state.depositSDRToken = sdrTokenAddress
  },
  [HIDE_DEPOSIT](state) {
    state.showDeposit = false
  },
  [SHOW_MINT](state, { sdrTokenAddress }) {
    state.showMint = true
    state.mintSDRToken = sdrTokenAddress
  },
  [HIDE_MINT](state) {
    state.showMint = false
  },
  [SHOW_MINT_SYN](state, { sdrTokenAddress, tab }) {
    console.log('tab', tab)
    state.showMintSyn = true
    state.mintSynSDRToken = sdrTokenAddress
    state.mintTab = tab
  },
  [HIDE_MINT_SYN](state) {
    state.showMintSyn = false
  },
  [SHOW_PAYBACK](state, { sdrTokenAddress }) {
    state.showPayback = true
    state.paybackSDRToken = sdrTokenAddress
  },
  [HIDE_PAYBACK](state) {
    state.showPayback = false
  },
  [SHOW_BORROW](state, { sdrTokenAddress }) {
    state.showBorrow = true
    state.borrowSDRToken = sdrTokenAddress
  },
  [HIDE_BORROW](state) {
    state.showBorrow = false
  },
  [SHOW_COLLATERAL](state, { sdrTokenAddress }) {
    state.showCollateral = true
    state.collateralSDRToken = sdrTokenAddress
  },
  [HIDE_COLLATERAL](state) {
    state.showCollateral = false
  },
  [SHOW_FAUCET](state) {
    state.showFaucet = true
  },
  [HIDE_FAUCET](state) {
    state.showFaucet = false
  },
  [SHOW_CHECK_NETWORK](state) {
    state.showCheckNetwork = true
  },
  [HIDE_CHECK_NETWORK](state) {
    state.showCheckNetwork = false
  },
}

const actions = {}

export const modal = { namespaced, state, getters, mutations, actions }
