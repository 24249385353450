<template>
  <div class="set-font pt-6">
    <div class="d-flex justify-space-between text-color font-weight-bold">
      <div>Supplied:</div>
      <div>{{ amountHint }}</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      label="Withdraw Amount"
      v-model="amount"
      :focus="focus"
      :hint="amountHint"
      hideDetails
      :maxVal="maxVal"
      @maxClicked="maxClicked"
    />

    <v-alert v-if="alert.type" dense :type="alert.type">
      {{ alert.msg }}
    </v-alert>

    <!-- Rates Section -->
    <!-- <deposit-rate-section :depositAPY="depositAPY" :rewardAPY="rewardAPY" /> -->

    <borrow-limit-section :depositAPY="depositAPY" :rewardAPY="rewardAPY" />

    <connect-wallet></connect-wallet>

    <v-btn color="accent" class="mt-8" :disabled="actionBtnDisabled" large block @click="withdraw(amount)">
      Withdraw {{ value.underlySymbol }}
    </v-btn>
  </div>
</template>
<script>
import { BigNumber } from 'bignumber.js'
import { mapActions } from 'vuex'
import DepositRateSection from './depositRateSection.vue'
import BorrowLimitSection from './borrowLimitSection.vue'
import { formatNumReadable } from '@/utils'

export default {
  name: 'WithdrawTab',
  components: { DepositRateSection, BorrowLimitSection },
  props: {
    value: {
      type: Object,
      require: true,
    },
    focus: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      amount: '',
      clickedMax: false,
      alert: {
        type: '',
        msg: '',
      },
    }
  },
  created() {
    this.clickedMax = false
  },
  watch: {
    amount(newVal) {
      if (Number(newVal) < Number(this.maxVal)) {
        this.clickedMax = false
      }
      if (this.value.isCToken && Number(newVal) > Number(this.value.totalCash.toFixed())) {
        this.alert = {
          type: 'error',
          msg: 'Insufficient available liquidity for withdraw',
        }
      } else {
        this.alert = {}
      }
    },
  },
  computed: {
    depositAPY() {
      return this.value.depositAPY
    },
    rewardAPY() {
      return '0'
    },
    actionBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) <= 0 ||
        Number(this.amount) > Number(this.value.supplied) ||
        (this.value.isCToken && Number(this.amount) > Number(this.value.totalCash.toFixed()))
      )
    },
    maxVal() {
      return new BigNumber(String(this.value.supplied)).toFixed()
    },
    amountHint() {
      return `${this.value.supplied} ${this.value.underlySymbol}`
    },
  },
  methods: {
    ...mapActions({
      redeem: 'tx/redeem',
    }),
    maxClicked() {
      this.clickedMax = true
    },
    async withdraw(amount) {
      const params = {
        underlySymbol: this.value.underlySymbol,
        underlyOrCTokenSymbol: this.value.underlySymbol,
        amount: new BigNumber(String(amount)).times(`1e${this.value.underlyDecimals}`),
        noDecimalsAmount: new BigNumber(String(amount)).toFixed(),
      }
      if (this.clickedMax) {
        params.underlyOrCTokenSymbol = this.value.sdrSymbol
        params.amount = new BigNumber(String(this.value.cTokenBalance)).times(`1e${this.value.sdrTokenDecimals}`)
      }
      this.redeem(params)
    },
  },
}
</script>
