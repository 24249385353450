<template>
  <div class="set-font pt-6">
    <div v-if="hint" class="deposit-tip-color my-4">{{ hint }}</div>
    <div class="d-flex justify-space-between text-color font-weight-bold">
      <div>Available:</div>
      <div>{{ amountHint }}</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      v-if="approved"
      v-model="amount"
      label="Deposit Amount"
      :maxVal="maxVal"
      :hint="amountHint"
      hideDetails
      :focus="focus"
    ></amount-input>

    <!-- Rates Section -->
    <!-- <deposit-rate-section :depositAPY="depositAPY" :rewardAPY="rewardAPY" /> -->

    <!-- Borrow Limit Section -->
    <borrow-limit-section v-if="approved" :depositAPY="depositAPY" :rewardAPY="rewardAPY" />

    <connect-wallet></connect-wallet>

    <!-- Deposit Btn -->
    <div class="mt-8" v-if="approved">
      <v-btn color="accent" :disabled="actionBtnDisabled" depressed large block @click="deposit(amount)">
        Deposit {{ value.underlySymbol }}
      </v-btn>
    </div>

    <!-- Enable Btn -->
    <v-btn color="accent" v-else class="mt-8" :loading="enableLoading" depressed large block @click="enable"
      >Enable {{ value.underlySymbol }}</v-btn
    >
  </div>
</template>
<script>
import { BigNumber } from 'bignumber.js'
import { mapActions } from 'vuex'
import DepositRateSection from './depositRateSection.vue'
import BorrowLimitSection from './borrowLimitSection.vue'
import { formatNumReadable1, formatNum } from '@/utils'
import { ethers } from 'ethers'

export default {
  name: 'DepositTab',
  components: { DepositRateSection, BorrowLimitSection },
  props: {
    value: {
      type: Object,
      require: true,
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      amount: '',
      depositLoading: false,
      enableLoading: false,
    }
  },
  computed: {
    depositAPY() {
      return this.value.depositAPY
    },
    rewardAPY() {
      return '0'
    },
    actionBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) <= 0 ||
        Number(this.amount) > Number(this.value.underlyBalance)
      )
    },
    amountHint() {
      return `${formatNumReadable1(this.value.underlyBalance, 4, 1)} ${this.value.underlySymbol}`
    },
    maxVal() {
      // return formatNum(new BigNumber(String(this.value.underlyBalance)), Number(this.value.underlyDecimals))
      if (ethers.ZeroAddress === this.value.underlyAddr) {
        const underlyBalance = new BigNumber(String(this.value.underlyBalance))
        return underlyBalance.gt(0.1) ? underlyBalance.minus(0.1).toFixed() : underlyBalance.toFixed()
      } else {
        return new BigNumber(String(this.value.underlyBalance)).toFixed()
      }
    },
    approved() {
      return true
      // return this.value && BigNumber(String(this.value.tokenAllowance)).isGreaterThan(0)
    },
    hint() {
      return this.approved ? '' : `Enable ${this.value.underlySymbol} to proceed with the deposit`
    },
  },
  methods: {
    ...mapActions({
      approve: 'tx/approve',
      supply: 'tx/supply',
    }),
    async enable() {
      this.enableLoading = true
      const params = {
        symbol: this.value.underlySymbol,
        token: this.value.underlyAddr,
        spender: this.value.sdrTokenAddress,
        flag: true
      }
      await this.approve(params)
      this.enableLoading = false
    },
    async deposit(amount) {
      const params = {
        underlySymbol: this.value.underlySymbol,
        sdrTokenAddress: this.value.sdrTokenAddress,
        amount,
      }
      await this.supply(params)
    },
  },
}
</script>
