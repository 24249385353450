<template>
  <v-footer color="#fffffe">
    <v-container class="py-2">
      <v-slide-group>
        <v-slide-item>
          <span>©{{ new Date().getFullYear() }} sumer.money |</span>
        </v-slide-item>
        <v-slide-item v-for="item in links" :key="item.name">
          <a class="mx-1" :href="item.href" target="_blank">{{ item.name }}</a>
        </v-slide-item>
      </v-slide-group>
    </v-container>
  </v-footer>
</template>

<script>
import { links } from '../../constants'

export default {
  name: 'Bottom',
  data() {
    return {
      links,
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__content {
  display: flex;
  justify-content: center;
}
</style>
