<template>
  <v-dialog v-if="showFaucet" v-model="computedShowFaucet" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <span>Faucet</span>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="my-2" />
      <v-card-text v-if="!!pendingTx">
        <section class="d-flex flex-column py-5">
          <div class="d-flex justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          </div>
        </section>
      </v-card-text>
      <v-card-text v-else>
        <v-text-field
          label="Account"
          dense
          outlined
          v-model="accountAddr"
          :rules="accountRules"
          :counter="42"
        ></v-text-field>
        <v-btn color="primary accent-3" depressed large block :disabled="faucetBtnStatus" @click="faucet">faucet</v-btn>
        <span class="caption">{{ info }}</span>
        <!-- <span> You should <v-btn color="primary" x-small @click="authorize">Authorize</v-btn> tweet first</span> -->
      </v-card-text>
      <v-card-text>
        <v-alert v-for="item in computedRes" :key="item.symbol" dense :type="item.type">{{
          item.symbol + ': ' + item.msg
        }}</v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { BigNumber } from 'bignumber.js'
import { faucetTokens } from '@/constants'
import { requestToken, accessToken, createTweet} from '@/api'

export default {
  name: 'FaucetModal',
  data() {
    return {
      borrowLimitDelta: new BigNumber(0),
      actionLoading: false,
      accountAddr: '',
      faucetAmount: '',
      accountRules: [
        (v) => !!v || 'Account is required',
        (v) => /^(0x)?[0-9a-fA-F]{40}$/.test(v) || 'Account must be valid',
      ],
      faucetAmountRules: [(v) => /^[1-9]\d*$/.test(v) || 'Amount must be valid'],

      oauth_token: '',
      oauth_verifier: '',

    }
  },
  computed: {
    ...mapState('modal', ['showFaucet']),
    ...mapState('faucet', ['faucetLoading', 'results']),
    ...mapState('wallet', ['chainId', 'account']),
    computedShowFaucet: {
      get() {
        return this.showFaucet
      },
      set() {
        this.close()
      },
    },
    pendingTx() {
      return this.faucetLoading
    },
    faucetBtnStatus() {
      return !this.accountAddr || !/^(0x)?[0-9a-fA-F]{40}$/.test(this.accountAddr) //|| !this.oauth_token || !this.oauth_verifier
    },
    info() {
      if (!this.chainId) {
        return ''
      }
      let _info = 'You will get '
      const format = faucetTokens[this.chainId].filter(token => token.symbol).map((token) => {
        return `${token.balance} ${token.symbol}`
      })
      return _info + format.join(',')
    },
    computedRes() {
      return Object.values(this.results)
    },
  },
  watch: {
    account(val) {
      if (val) {
        this.accountAddr = val
      }
    },
    // pendingTx(newValue, old) {
    //   if (newValue === false && old === true) {
    //     this.close()
    //   }
    // },
  },
  mounted() {
    const { oauth_token, oauth_verifier } = this.$route.query
    // console.log({ oauth_token, oauth_verifier })
    this.oauth_token = oauth_token
    this.oauth_verifier = oauth_verifier
  },
  methods: {
    ...mapActions({
      actionFaucet: 'faucet/faucet',
    }),
    faucet() {
      this.actionFaucet({
        toAccount: this.accountAddr,
        oauth_token: this.oauth_token,
        oauth_verifier: this.oauth_verifier
      })
    },
    close() {
      this.$store.commit('modal/HIDE_FAUCET')
    },
    async authorize() {
      const res = await requestToken()
      if (res.authorizeURL) {
        window.open(res.authorizeURL, '_self')
      } else {
        console.log('authorize res', res)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
