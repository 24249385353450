<template>
  <div class="set-font pt-6">
    <div class="d-flex justify-space-between text-color font-weight-bold">
      <div>Borrow Capacity:</div>
      <div>{{ amountHint }}</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      v-model="amount"
      label="Borrow Amount"
      maxText="80% Limit"
      maxBtnTitle="80% of your borrowing limit. Protocol considers borrowing above this threshold unsafe."
      :maxVal="maxVal"
      :hint="amountHint"
      hideDetails
      :focus="focus"
    />

    <v-alert v-if="alert.type" dense :type="alert.type">
      {{ alert.msg }}
    </v-alert>

    <div class="d-flex justify-space-between my-4 text-color">
      <div>Liquidity</div>
      <div>{{ readableTotalCash }}</div>
    </div>

    <!-- <div class="d-flex justify-space-between mt-2 text-color">
      <div class="rates-name-color">Wallet Balance</div>
      <div class="font-weight-bold">{{ computedWalletBalance }}</div>
    </div>

    <div class="d-flex justify-space-between mt-2 mb-4 text-color">
      <div class="rates-name-color">Current Price</div>
      <div class="font-weight-bold">${{ currentPrice }}</div>
    </div> -->

    <!-- Rates Section -->
    <!-- <borrow-rate-section  />d -->

    <!-- Borrow Limit Section-->
    <borrow-limit-section
      class="text-color"
      :borrowAPY="borrowAPY"
      :rewardAPY="rewardAPY"
      :usedDelta="usedDelta"
      :borrowedVal="String(borrowedVal)"
      :liquidity="String(liquidity)"
      :token="value"
    />

    <connect-wallet></connect-wallet>

    <v-btn color="accent" class="mt-8" :disabled="actionBtnDisabled" depressed large block @click="borrow(amount)">
      <span>Borrow {{ value.underlySymbol }}</span>
    </v-btn>
  </div>
</template>
<script>
import { BigNumber } from 'bignumber.js'
import { mapActions, mapGetters } from 'vuex'
import BorrowRateSection from './borrowRateSection.vue'
import BorrowLimitSection from './borrowLimitSection.vue'

import { formatNumReadable, formatNumReadable1, formatNum } from '@/utils'
export default {
  name: 'BorrowTab',
  components: { BorrowRateSection, BorrowLimitSection },
  props: {
    value: {
      type: Object,
      require: true,
    },
    focus: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      amount: '',
      alert: {
        type: '',
        msg: '',
      },
    }
  },
  computed: {
    ...mapGetters('wallet', ['borrowUsed', 'borrowFree']),
    ...mapGetters('market', ['priceBySDRToken', 'hypoLiquidityBySDRToken']),
    currentPrice() {
      const p = this.priceBySDRToken(this.value.sdrTokenAddress)
      return formatNumReadable(p)
    },
    computedWalletBalance() {
      return `${formatNumReadable(this.value.underlyBalance)} ${this.value.underlySymbol}`
    },
    rewardAPY() {
      return '0'
    },
    borrowAPY() {
      return this.value.borrowAPY
    },
    borrowedVal() {
      return this.value.borrowedVal
    },
    maxVal() {
      return this.liquidity.times(0.8).div(this.value.price).toFixed(Number(this.value.underlyDecimals), 1)
    },
    usedDelta() {
      return BigNumber(this.amount).times(this.value.price).toFixed()
    },
    amountHint() {
      return `${this.available} ${this.value.underlySymbol} ($${this.availableLiquidity})`
    },
    actionBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) <= 0 ||
        Number(this.amount) > Number(this.liquidity.div(this.value.price).toFixed()) ||
        Number(this.amount) > Number(this.value.totalCash.toFixed())
      )
    },
    newUsed() {
      let deltaAmount = new BigNumber(this.amount).times(this.value.price)
      if (deltaAmount.isNaN()) {
        return this.borrowUsed.toFixed()
      }
      return this.borrowUsed.plus(deltaAmount).toFixed()
    },
    liquidity() {
      const hypo = this.hypoLiquidityBySDRToken(this.value.sdrTokenAddress)
      return hypo.liquidity
    },
    available() {
      return formatNumReadable1(this.liquidity.div(this.value.price), 4, 1)
    },
    availableLiquidity() {
      return formatNumReadable(this.liquidity, 2, 1)
    },
    readableTotalCash() {
      return `${formatNumReadable(this.value.totalCash)} ${this.value.underlySymbol}`
    },
  },
  watch: {
    amount(val) {
      if (this.value.totalCash.lt(val)) {
        this.alert = {
          type: 'error',
          msg: 'Amount to be borrowed should be less than available liquidity',
        }
      } else if (this.value.totalCash.times(0.8).lt(val)) {
        this.alert = {
          type: 'warning',
          msg: 'CAUTION: The borrowing on this pool is over the optimal limit. The pool will be switched to a jump-kink interest model with a high interest rate',
        }
      } else {
        if (this.liquidity.div(this.value.price).lt(val)) {
          this.alert = {
            type: 'error',
            msg: 'Insufficient available liquidity for borrow',
          }
        } else if (this.liquidity.times(0.8).div(this.value.price).lt(val)) {
          this.alert = {
            type: 'warning',
            msg: 'Borrowing this amount will reduce your health factor and increase chance of liquidation',
          }
        } else {
          this.alert = {}
        }
      }
    },
  },
  methods: {
    ...mapActions({
      borrowToken: 'tx/borrowToken',
    }),

    async borrow(amount) {
      const params = {
        underlySymbol: this.value.underlySymbol,
        amount,
      }
      this.borrowToken(params)
    },
  },
}
</script>
