<template>
  <v-dialog v-if="showBorrow" v-model="computedShowBorrow" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between flex-nowrap">
        <div class="d-flex align-center">
          <crypto-icon :token="market.underlySymbol" :name="market.underlyName" :info="market" />
          <v-btn class="ml-1" outlined x-small color="secondary" @click="goDetail">detail</v-btn>
        </div>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-tabs fixed-tabs color="#7A573B" class="sumer" @change="tabChanged">
          <v-tab class="left-tab"><span class="font-weight-bold">Borrow</span></v-tab>
          <v-tab class="right-tab"><span class="font-weight-bold">Repay</span></v-tab>
          <v-tab-item>
            <borrow-tab v-model="market" :focus="selectedTab === 0" />
          </v-tab-item>
          <v-tab-item>
            <repay-tab v-model="market" :focus="selectedTab === 1" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import BorrowTab from './borrowTab.vue'
import RepayTab from './repayTab.vue'
export default {
  name: 'BorrowModal',
  components: {
    BorrowTab,
    RepayTab,
  },
  props: {},
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    ...mapState('modal', ['showBorrow', 'borrowSDRToken']),
    ...mapGetters('market', ['marketBySDRToken']),
    computedShowBorrow: {
      get() {
        return this.showBorrow
      },
      set() {
        this.close()
      },
    },
    market() {
      console.log('this.marketBySDRToken(this.borrowSDRToken)', this.marketBySDRToken(this.borrowSDRToken).borrowed.toFixed())
      return this.marketBySDRToken(this.borrowSDRToken)
    },
  },
  methods: {
    close() {
      this.$store.commit('modal/HIDE_BORROW')
    },
    tabChanged(index) {
      this.selectedTab = index
    },
    goDetail() {
      window.open(`${window.location.protocol}//${window.location.host}/market/detail/${this.market.underlyAddr}`)
      // this.close()
      // this.$router.push({
      //   name: 'TokenDetail',
      //   params: {
      //     address: this.market.underlyAddr
      //   }
      // })
    },
  },
}
</script>
<style lang="scss">
@import '@/style/_variable.scss';
.modal-card {
  overflow: hidden;
  .v-card__title {
    padding: 2rem 2.5rem 0.5rem 2.5rem !important;
  }
  .v-card__text {
    padding: 1.2rem 2.5rem 2.2rem 2.5rem !important;
  }
}
</style>
