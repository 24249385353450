<template>
  <v-dialog v-if="showMintSyn" v-model="computedShow" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <crypto-icon :token="market.underlySymbol" :name="market.underlyName" :info="market" />
          <v-btn class="ml-1" outlined x-small color="secondary" @click="goDetail">detail</v-btn>
        </div>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-tabs fixed-tabs color="#7A573B" class="sumer text-color" @change="tabChanged" v-model="selectedTab">
          <v-tab class="left-tab"><span class="font-weight-bold">Mint</span></v-tab>
          <v-tab class="right-tab"><span class="font-weight-bold">Repay</span></v-tab>

          <v-tab-item>
            <mint-tab v-model="market" :focus="selectedTab === 0" />
          </v-tab-item>
          <v-tab-item>
            <payback-tab v-model="market" :focus="selectedTab === 1" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import CryptoIcon from '@/components/CryptoIcon'
import MintTab from './mint.vue'
import PaybackTab from './pay.vue'
export default {
  name: 'MintSynModal',
  components: {
    CryptoIcon,
    MintTab,
    PaybackTab,
  },
  props: {},
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    ...mapState('modal', ['showMintSyn', 'mintTab', 'mintSynSDRToken']),
    ...mapGetters('market', ['marketBySDRToken']),
    computedShow: {
      get() {
        return this.showMintSyn
      },
      set() {
        this.close()
      },
    },
    market() {
      return this.marketBySDRToken(this.mintSynSDRToken)
    },
  },
  watch: {
    mintTab() {
      this.selectedTab = this.mintTab 
    }
  },
  methods: {
    close() {
      this.$store.commit('modal/HIDE_MINT_SYN')
    },
    tabChanged(index) {
      // console.log('change tab to ', index)
      this.selectedTab = index
    },
    goDetail() {
      window.open(`${window.location.protocol}//${window.location.host}/market/detail/${this.market.underlyAddr}`)
      // this.close()
      // this.$router.push({
      //   name: 'TokenDetail',
      //   params: {
      //     address: this.market.underlyAddr
      //   }
      // })
    },
  },
}
</script>
<style lang="scss">
@import '@/style/_variable.scss';

.v-tab {
  background-color: lighten(#eaddcf, 10%);
  &.v-tab--active {
    background-color: lighten(#8c7851, 12%);
    color: #fffffe !important;
    // border: 2px solid #8c7851 !important;
    // background-color: lighten(#8c7851, 50%) !important;
  }
}
.left-tab {
  border-radius: 5px 0 0 5px !important;
}
.right-tab {
  border-radius: 0 5px 5px 0 !important;
}
.v-tabs-slider-wrapper {
  height: 0 !important;
}
</style>
