<template>
  <v-dialog v-if="showCollateral" v-model="computedShowCollateral" max-width="450">
    <v-card outlined class="modal-card">
      <v-card-title class="d-flex justify-space-between">
        <CryptoIcon :token="market.underlySymbol" :name="caption" />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div class="rates-name-color mb-4">{{ description }}</div>
        <div class="d-flex justify-space-between">
          <div class="rates-name-color">Borrow Limits</div>
          <div class="d-flex">
            <div>
              <span class="rates-name-color">{{ borrowLimit | usd }}</span>
              ->
              <span class="font-weight-bold">{{ toBorrowLimit | usd }}</span>
            </div>
          </div>
        </div>
        <v-divider class="my-2" />
        <div class="d-flex justify-space-between">
          <div class="rates-name-color">Used</div>
          <div class="d-flex">
            <div>
              <span class="rates-name-color">{{ (borrowUsedPct / 100) | percent }}</span>
              ->
              <span class="font-weight-bold">{{ (toUsedPercent / 100) | percent }}</span>
            </div>
          </div>
        </div>
        <v-progress-linear :color="progressColor" v-model="toUsedPercent"></v-progress-linear>
        <v-alert class="mt-2" dense v-if="alert.type" :type="alert.type">{{ alert.msg }}</v-alert>
        <v-btn
          class="mt-8"
          :color="className"
          :loading="actionLoading"
          :disabled="actionBtnDisabled"
          depressed
          large
          block
          @click="enterOrExitMarket()"
        >
          {{ btnName }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { BigNumber } from 'bignumber.js'
import CryptoIcon from '@/components/CryptoIcon'

export default {
  name: 'EnterMarketsModal',
  components: {
    CryptoIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        show: false,
        data: {},
      }),
    },
  },
  data() {
    return {
      borrowLimitDelta: new BigNumber(0),
      actionLoading: false,
      alert: {
        type: '',
        msg: '',
      },
    }
  },
  computed: {
    ...mapState('modal', ['collateralSDRToken', 'showCollateral']),
    ...mapState('wallet', ['sumer', 'account']),
    ...mapGetters('market', ['metadataBySDRToken', 'marketBySDRToken', 'eqAssetBySDRToken']),
    ...mapGetters('wallet', ['borrowLimit', 'borrowUsedPct', 'borrowUsed']),
    computedShowCollateral: {
      get() {
        return this.showCollateral
      },
      set() {
        this.close()
      },
    },
    actionBtnDisabled() {
      if (this.market.enteredMarket) {
        return !this.market.borrowed.isZero() || this.toUsedPercent >= 100
      }
      return false
    },
    market() {
      return this.marketBySDRToken(this.collateralSDRToken)
    },
    toUsedPercent() {
      const isZero = BigNumber(this.toBorrowLimit).isLessThanOrEqualTo(0)
      return isZero ? 0 : new BigNumber(this.borrowUsed).times(100).div(this.toBorrowLimit).toNumber()
    },
    toBorrowLimit() {
      if (this.market.enteredMarket) {
        return BigNumber(this.borrowLimit).minus(this.borrowLimitDelta).toFixed()
      } else {
        return BigNumber(this.borrowLimit).plus(this.borrowLimitDelta).toFixed()
      }
    },
    progressColor() {
      const n = Number(this.toUsedPercent)
      if (n > 80) {
        return '#ee5253'
      }
      if (n > 60) {
        return '#feca57'
      }

      return '#b9ce27'
    },
    className() {
      if (this.market.enteredMarket) {
        return 'error'
      } else {
        return 'accent'
      }
    },
    btnName() {
      // if (BigNumber(this.market.borrowedVal).isGreaterThan(0)) {
      //   return 'DISMISS'
      // }
      // if (BigNumber(this.market.suppliedVal).isGreaterThan(0)) {
      //   return 'Disable Collateral'
      // }
      if (this.market.enteredMarket) {
        // return `Disable ${this.market.underlySymbol}`
        return 'Disable Collateral'
      } else {
        // return `Use ${this.market.underlySymbol} as collateral`
        return 'Enable Collateral'
      }
    },
    description() {
      // if (BigNumber(this.market.borrowedVal).isGreaterThan(0)) {
      //   return 'This asset is required to support your borrowed assets. Either repay borrowed assets, or supply another asset as collateral.'
      // }
      
      if (this.market.enteredMarket) {
        if (BigNumber(this.market.suppliedVal).isGreaterThan(0)) {
          return `Please proceed with caution before you disable ${this.market.underlySymbol} as collateral. If you have liabilities, reduced health factor can subject the remaining collateral being seized in liquidation`
        }
        return 'This asset will no longer be used towards your borrowing limit, and can’t be seized in liquidation.'
      } else {
        return 'Each asset used as collateral increases your borrowing limit.'
      }
    },
    caption() {
      // if (BigNumber(this.market.borrowedVal).isGreaterThan(0)) {
      //   return 'Collateral Required'
      // }
      if (this.market.enteredMarket) {
        return `Disable ${this.market.underlySymbol} as Collateral`
      } else {
        return `Enable ${this.market.underlySymbol} as Collateral`
      }
    },
  },
  watch: {
    showCollateral(newValue) {
      if (newValue) {
        const metadata = this.metadataBySDRToken(this.market.sdrTokenAddress)
        // const eqAsset = this.eqAssetBySDRToken(this.market.sdrTokenAddress)
        /**
         * interCRateMantissa
         * interSuRateMantissa
         * intraCRateMantissa
         * intraMintRateMantissa
         * intraSuRateMantissa
         */
        let groupRate = metadata.interRate
        // if (metadata.isCToken) {
        //   groupRate = String(eqAsset.interCRateMantissa)
        // } else {
        //   groupRate = String(eqAsset.interSuRateMantissa)
        // }
        
        this.borrowLimitDelta = this.market.supplied.times(String(this.market.price)).times(groupRate).div(1e18)
      } else {
        this.borrowLimitDelta = new BigNumber(0)
      }
    },
    'market.borrowed': {
      handler(val) {
        if (!BigNumber(val).isZero()) {
          if (this.market && this.market.enteredMarket) {
            this.alert = {
              type: 'error',
              msg: 'You cannot disable collateral for this asset. This asset collateralizes an open borrow position',
            }
          }
        } else {
          this.alert.type = ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      enterMarkets: 'tx/enterMarkets',
      exitMarkets: 'tx/exitMarkets',
    }),
    async enterOrExitMarket() {
      // if (BigNumber(this.market.borrowedVal).isGreaterThan(0)) {
      //   return this.close()
      // }

      const params = { underlySymbol: this.market.underlySymbol, sdrSymbol: this.market.sdrSymbol }
      this.actionLoading = true
      if (this.market.enteredMarket) {
        await this.exitMarkets(params)
      } else {
        await this.enterMarkets(params)
      }
      this.actionLoading = false
    },
    close() {
      this.$store.commit('modal/HIDE_COLLATERAL')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
