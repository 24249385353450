<template>
  <v-dialog v-if="showCheckNetwork" v-model="computedShowCheckNetworkModal" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <span>Switch Support Network</span>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="my-2" />
      <v-card-text>
        <div v-if="computedNetworkList.length">
          <section
            v-for="net in computedNetworkList"
            :key="net.networkId"
            class="d-flex my-2 pa-1 network"
            @click="switchNetwork(net)"
          >
            <v-avatar size="35"><v-img :src="net.url" aspect-ratio="1" contain /></v-avatar>
            <span class="d-flex align-center ml-2">{{ net.name }}</span>
          </section>
        </div>
        <div v-else class="text-center">Stay tuned</div>
      </v-card-text>
      <v-card-title class="d-flex justify-space-between">
        <span>Transaction History</span>
        <v-btn color="error" plain class="pa-0 clear-btn" @click="clear"> Clear </v-btn>
      </v-card-title>
      <v-divider class="my-2" />
      <v-card-text>
        <div v-if="computedTxs.length">
          <section
            v-for="tx in computedTxs"
            :key="tx.id"
            class="d-flex justify-space-between align-center pa-2 network"
          >
            <div>
              <span>{{ `${tx.title} ${tx.amount ? tx.amount : ''} ${tx.symbol}` }}</span>
              <span class="">:{{ tx.status }}</span>
            </div>
            <div>
              <v-btn plain color="primary" @click="viewScan(tx)">View</v-btn>
            </div>
          </section>
        </div>
        <div v-else class="text-center">EMPTY</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { getSupportNetwork } from '@/constants'
import { WalletBoardBus } from '@/WalletBoard'
import { CCIP_EXPLORER, LAYERZERO_SCAN, LAYERZERO_TESTNET_SCAN } from '../../../constants'
import { ethers } from 'ethers'
import { mapActions } from 'vuex/dist/vuex.common.js'

export default {
  name: 'SwitchNetworkModal',
  data() {
    return {}
  },
  computed: {
    ...mapState('modal', ['showCheckNetwork']),
    ...mapState('wallet', ['provider', 'chainId', 'currentNetwork', 'account']),
    ...mapState('tx', ['txInfos']),
    computedTxs() {
      const txs = [...this.txInfos]
      const localTxs = localStorage.getItem('sumer-ui-txs')
      if (localTxs) {
        txs.push(...JSON.parse(localTxs))
      }

      const obj = {}
      return txs.reduce((pre, item) => {
        !obj[item.id] && (obj[item.id] = true && pre.push(item))
        return pre
      }, []).filter(t => !t.msg.includes('rejected'))
    },
    computedShowCheckNetworkModal: {
      get() {
        return this.showCheckNetwork
      },
      set() {
        this.close()
      },
    },
    computedNetworkList() {
      const networkList = getSupportNetwork()
        .filter((net) => {
          if (this.account === ethers.ZeroAddress) {
            return true
          } else {
            return net.networkId !== this.chainId
          }
        })
        .map((net) => {
          return {
            ...net,
            url: require('@/assets/images/network/' + net.networkId + '.png'),
          }
        })
      return networkList
    },
  },
  methods: {
    ...mapActions({
      initWallet: 'wallet/initWallet',
    }),
    close() {
      this.$store.commit('modal/HIDE_CHECK_NETWORK')
    },
    switchNetwork(net) {
      // if (this.account === ethers.ZeroAddress) {
      //   this.initWallet({ accounts: [{
      //     address: ethers.ZeroAddress
      //   }], chainId: net.networkId, provider: new ethers.JsonRpcProvider(net.readRpc) })
      // } else {
      //   WalletBoardBus.$emit('setChain', net.networkId)
      // }
      WalletBoardBus.$emit('setChain', net.networkId)

      this.close()
    },
    viewScan(tx) {
      if (!tx.hash) return

      let url = ''
      if (tx.title === 'ccipTransfer') {
        url = `${CCIP_EXPLORER}/tx/${tx.hash}`
      } else if (tx.title === 'transfer') {
        const baseURL = this.currentNetwork.isTestnet ? LAYERZERO_TESTNET_SCAN : LAYERZERO_SCAN
        url = `${baseURL}/tx/${tx.hash}`
      } else {
        url = `${this.currentNetwork.blockExplorer}/tx/${tx.hash}`
      }
      window.open(url)
    },
    clear() {
      this.$store.commit('tx/clearStoredTx')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';

.network {
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: lightgray;
  }
}
.clear-btn {
  min-width: 0px !important;
}
</style>
