const { VUE_APP_FAUCET_ACCOUNT_PK } = process.env

import * as Sumer from '@meterio/sumer-js'
import { faucetTokens, getNetworkConfig } from '@/constants'
import BigNumber from 'bignumber.js'

const namespaced = true

const state = {
  faucetLoading: false,
  /**
   * {
   *   [symbol]: {
   *      symbol: string,
   *      type: info | warning | error | success,
   *      msg: string
   *   }
   * }
   */
  results: {},
}

const getters = {}

const mutations = {
  setFaucetLoading(state, faucetLoading) {
    state.faucetLoading = faucetLoading
  },
  setResults(state, results) {
    state.results = results
  },
}

const actions = {
  async faucet({ state, rootState, commit, dispatch }, { toAccount, oauth_token, oauth_verifier }) {
    const { sumer, chainId, account } = rootState.wallet
    const config = getNetworkConfig(chainId)
    const walletSigner = new Sumer._ethers.Wallet(
      VUE_APP_FAUCET_ACCOUNT_PK,
      new Sumer._ethers.JsonRpcProvider(config.rpc),
    )
    const walletAddr = walletSigner.address

    commit('setFaucetLoading', true)

    // const accessTokenRes = await accessToken(oauth_token, oauth_verifier)
    // console.log('accessTokenRes', accessTokenRes)

    // const { oauth_token_secret } = accessTokenRes
    // const tweetRes = await createTweet(accessTokenRes.oauth_token, oauth_token_secret, 'metertest faucet')
    // console.log('tweetRes', tweetRes)

    // let tokens = []

    // if (tweetRes.error) {
    //   alert(JSON.stringify(tweetRes.error))
    // } else {
    //   tokens = faucetTokens[chainId].filter(t => !!t.symbol)
    // }

    const tokens = faucetTokens[chainId].filter((t) => !!t.symbol)

    commit('setResults', {})

    for (let token of tokens) {
      try {
        commit('setResults', {
          ...state.results,
          [token.symbol]: {
            symbol: token.symbol,
            type: 'info',
            msg: 'starting...',
          },
        })
        let faucetTokenAddress
        if (token.symbol === 'MTR') {
          faucetTokenAddress = '0x4cb6cef87d8cadf966b455e8bd58fff32aba49d1'
        } else {
          faucetTokenAddress = Sumer.util.getAddress(token.symbol, sumer._network.name)
        }

        console.log('faucetTokenAddress', faucetTokenAddress)
        const contract = new Sumer._ethers.Contract(faucetTokenAddress, Sumer.abi.ERC20MinterBurnerPauser, walletSigner)
        const decimals = await contract.decimals()
        const balance = await contract.balanceOf(walletAddr)

        const isInsufficient = new BigNumber(String(balance)).div(`1e${decimals}`).lt(token.balance)
        if (isInsufficient) {
          commit('setResults', {
            ...state.results,
            [token.symbol]: {
              symbol: token.symbol,
              type: 'warning',
              msg: 'failed: insufficient balance',
            },
          })
          continue
        }
        const amount = Sumer._ethers.parseUnits(token.balance, decimals)
        const tx = await contract.transfer(toAccount, amount)
        await tx.wait()
        commit('setResults', {
          ...state.results,
          [token.symbol]: {
            symbol: token.symbol,
            type: 'success',
            msg: 'success!',
          },
        })
      } catch (e) {
        commit('setResults', {
          ...state.results,
          [token.symbol]: {
            symbol: token.symbol,
            type: 'error',
            msg: e.message,
          },
        })
      }
    }

    commit('setFaucetLoading', false)

    if (account === toAccount.toLowerCase()) {
      setTimeout(() => {
        dispatch('market/initMarket', null, { root: true })
      }, 2000)
    }
  },
}

export const faucet = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
