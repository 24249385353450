import { getSummary, getSummaryByChainid } from "../../api"
import { getSupportNetwork } from "../../constants"

const namespaced = true

const state = {
  summary: [],
  detail: [],
  summaryLoading: false,
  detailLoading: false
}

const getters = {}

const mutations = {
  setSummary(state, summary) {
    state.summary = summary
  },
  setSummaryLoading(state, loading) {
    state.summaryLoading = loading
  },
  setDetail(state, detail) {
    state.detail = detail
  }
}

const actions = {
  async initSummary({ rootState, state, commit }) {
    // const { currentNetwork } = rootState.wallet
    const supportNetwork = getSupportNetwork()
    const supportNetworkIds = supportNetwork.map(net => String(net.networkId))
    const summary = await getSummary()
    console.log('summary', summary.data)
    const _summary = []
    Object.keys(summary.data).filter(networkId => supportNetworkIds.includes(networkId)).map(networkId => {
      const networkName = supportNetwork.find(net => net.networkId == networkId)?.name
      
      if (!networkName) return
      const totalBorrow = summary.data[networkId].totalBorrowsVal
      const totalMinted = summary.data[networkId].totalMintedSyntheticVal
      _summary.push({
        chainId: networkId,
        network: networkName,
        totalCash: summary.data[networkId].totalCashVal,
        totalDeposit: summary.data[networkId].totalDepositVal,
        totalBorrow,
        totalMinted,
        totalLiability: Number(totalBorrow) + Number(totalMinted),
        circulation: summary.data[networkId].circulation
      })
    })

    commit('setSummary', _summary)
  },
  async getSummaryDetail({ state, commit }, { networkId }) {
    const summary = await getSummaryByChainid(networkId)
    console.log('summary detail', summary.data)
    const _summary = []
    summary.data.markets.map(item => {
      _summary.push({
        symbol: item.underlySymbol,
        totalDeposit: item.totalDepositVal,
        totalBorrow: item.totalBorrowsVal,
        totalCash: item.totalCashVal
      })
    })

    commit('setDetail', _summary)
  }
}

export const collect = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}