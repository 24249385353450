export * from './mint'
export * from './interestModal'
export * from './bridge'
export * from './sumer'
export * from './tweet'
export * from './collect'

import * as Sumer from '@meterio/sumer-js'
import { _ethers } from '@meterio/sumer-js'
import { BigNumber } from 'bignumber.js'

import { UNLIMITED_APPROVE_AMOUNT } from '@/constants'

export const getSDRTokenMetaDataAll = async ({ CompoundLens }, ctokenAddrs) => {
  // const contract = Sumer.util.getAddress('CompoundLens', sumer._network.name)

  // const options = {
  //   abi: Sumer.abi.CompoundLens,
  //   _sumerProvider: sumer._provider,
  //   blockTag: 'latest'
  // }

  const metaData = await CompoundLens.cTokenMetadataAll.staticCall(ctokenAddrs)
  return metaData.map((m) => m.toObject())
}

export const getSDRTokenBalancesAll = async ({ CompoundLens }, addrs, account) => {
  // const contract = Sumer.util.getAddress('CompoundLens', sumer._network.name)

  // const options = {
  //   abi: Sumer.abi.CompoundLens,
  //   _sumerProvider: sumer._provider,
  //   blockTag: 'latest'
  // }

  let tokenBalances = await CompoundLens.cTokenBalancesAll.staticCall(addrs, account)
  tokenBalances = tokenBalances.map((t) => t.toObject())
  return tokenBalances
}

export const getAccountLiquidity = async (sumer, account) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  const options = {
    abi: Sumer.abi.Comptroller,
    _sumerProvider: sumer._provider,
    blockTag: 'latest',
  }

  const liquidity = await Sumer.eth.read(comptroller, 'getAccountLiquidity', [account], options)

  return liquidity
}

export const getMarkets = ({ Comptroller }, sdrTokenAddr) => {
  const market = Comptroller.markets(sdrTokenAddr)
  return market
}

const percentScale = 1e14

export const getEqAssetGroup = async ({ Comptroller }, groupId) => {
  // const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)
  // const compOptions = {
  //   abi: Sumer.abi.Comptroller,
  //   _sumerProvider: sumer._provider,
  //   blockTag: 'latest'
  // }
  // const market = Comptroller.callStatic.markets(sdrTokenAddr)
  // const groupId = market.assetGroupId

  try {
    const g = await Comptroller.assetGroup(groupId)
    return {
      groupId: g[0],
      groupName: '',
      intraCRateMantissa: Number(g[1].toString()) * percentScale,
      intraSuRateMantissa: Number(g[2].toString()) * percentScale,
      intraMintRateMantissa: Number(g[3].toString()) * percentScale,
      interCRateMantissa: Number(g[4].toString()) * percentScale,
      interSuRateMantissa: Number(g[5].toString()) * percentScale,
      InterMintRateMantissa: Number(g[6].toString()) * percentScale,
    }
  } catch (e) {
    return (await Comptroller.getAssetGroup(groupId)).toObject()
  }
}

export const getMarketBorrowCap = async (sumer, ctoken) => {
  const contract = Sumer.util.getAddress('Comptroller', sumer._network.name)
  console.log('GET CONTRACE ADDR: ', contract)
  const options = {
    abi: Sumer.abi.Comptroller,
    _sumerProvider: sumer._provider,
    blockTag: 'latest',
  }

  const result = await Sumer.eth.read(contract, '_getMarketBorrowCap', [ctoken], options)
  return result
}

export const getMerketDepositCap = async (sumer, ctoken) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)
  const compOptions = {
    abi: Sumer.abi.Comptroller,
    _sumerProvider: sumer._provider,
    blockTag: 'latest',
  }
  const result = await Sumer.eth.read(comptroller, 'maxSupply', [ctoken], compOptions)

  return result
}

export const getLiquidationIncentiveMantissa = async (sumer, ctoken) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)
  const compOptions = {
    abi: Sumer.abi.Comptroller,
    _sumerProvider: sumer._provider,
    blockTag: 'latest',
  }
  const result = await Sumer.eth.read(comptroller, 'liquidationIncentiveMantissa', [], compOptions)

  return result
}

export const getAccountLimits = async ({ CompoundLens }, sumer, account) => {
  // const contract = Sumer.util.getAddress('CompoundLens', sumer._network.name)
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  // const options = {
  //   abi: Sumer.abi.CompoundLens,
  //   _sumerProvider: sumer._provider,
  //   blockTag: 'latest'
  // }

  const limits = await CompoundLens.getAccountLimits.staticCall(comptroller, account)

  return limits
}

export const getUnderlyingPrice = async (sumer, sdrTokenAddress, decimals) => {
  const contract = Sumer.util.getAddress('FeedPriceOracle', sumer._network.name)
  const options = {
    abi: Sumer.abi.FeedPriceOracle,
    _sumerProvider: sumer._provider,
    blockTag: 'latest',
  }
  const price = await Sumer.eth.read(contract, 'getUnderlyingPrice', [sdrTokenAddress], options)

  const d = 36 - (decimals ? decimals : 18)

  // console.log(
  //   `getUnderlyingPrice: ${sdrTokenAddress} with oracle-decimal:${decimals}, price:${price}, calibratedPrice: ${new BigNumber(
  //     String(price),
  //   )
  //     .div(`1e${d}`)
  //     .toFixed(0)}`,
  // )
  return new BigNumber(String(price)).div(`1e${d}`)
}

export const getUnderlyingPrices = async ({ PriceOracle }, tokens) => {
  // const contract = Sumer.util.getAddress('FeedPriceOracle', sumer._network.name)

  // const options = {
  //   abi: priceOracleAbi,
  //   _sumerProvider: sumer._provider,
  //   blockTag: 'latest'
  // }
  const params = tokens.map((t) => t.address)
  console.log('tokens:', tokens)
  const res = await PriceOracle.getUnderlyingPrices(params)
  // console.log('pricess', res)
  const prices = {}
  for (let i = 0; i < tokens.length; i++) {
    const t = tokens[i]
    const d = 18 - t.decimals
    prices[t.address.toLowerCase()] = new BigNumber(String(res[i])).div(`1e18`).toFixed()
  }

  return prices
}

export const getAllowance = async (sumer, token, account, spender) => {
  const options = {
    abi: Sumer.abi.ERC20MinterBurnerPauser,
    _sumerProvider: sumer._provider,
  }

  const allowance = await Sumer.eth.read(token, 'allowance', [account, spender], options)
  return allowance
}

export const unlimitedApprove = async (sumer, token, spender) => {
  const options = {
    abi: Sumer.abi.ERC20MinterBurnerPauser,
    _sumerProvider: sumer._provider,
  }

  const unlimitedAmount = _ethers.toBigInt(UNLIMITED_APPROVE_AMOUNT)

  const tx = await Sumer.eth.trx(token, 'approve', [spender, unlimitedAmount], options)
  return tx
}

export const getHypoLiquidity = ({ Comptroller }, account, cToken, redeemTokens, borrowAmount) => {
  // const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  // const params = [
  //   account,
  //   cToken,
  //   Sumer._ethers.BigNumber.from(String(redeemTokens)),
  //   Sumer._ethers.BigNumber.from(String(borrowAmount)),
  // ]
  // const options = {
  //   _sumerProvider: sumer._provider,
  //   abi: Sumer.abi.Comptroller,
  //   blockTag: 'latest'
  // }
  const hypoLiquidity = Comptroller.getHypotheticalAccountLiquidity(account, cToken, redeemTokens, borrowAmount)
  return hypoLiquidity
}

export const getAccountSafeLimit = async (sumer, account, cToken) => {
  // console.log('account, cToken', account, cToken)
  const comptroller = Sumer.util.getAddress('CompLogic', sumer._network.name)

  const params = [account, cToken, '995000000000000000', '800000000000000000']
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.CompLogic,
  }
  // const safeLimit = await Comptroller.getAccountSafeLimit(account, cToken, '995000000000000000', '800000000000000000')// 
  const safeLimit = await Sumer.eth.read(comptroller, 'getHypotheticalSafeLimit', params, options)
  console.log('safeLimit', safeLimit)
  return safeLimit
}

export const getMintedSuToken = async (sumer, account, token) => {
  const params = [account]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.CErc20,
  }
  const results = await Sumer.eth.read(token, 'getAccountSnapshot', params, options)
  return results[1]
}

export const getRedemptionRate = async (sumer, cToken, amount, totalBorrow) => {
  const redemptionManager = Sumer.util.getAddress('RedemptionManager', sumer._network.name)

  const params = [cToken, amount, totalBorrow]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.RedemptionManager,
  }
  const results = await Sumer.eth.read(redemptionManager, 'getCurrentRedemptionRate', params, options)
  // console.log('redemption res', results)
  return results
}

export const getHypoLiquidityForSDRToken = async (contracts, account, sdrToken) =>
  getHypoLiquidity(contracts, account, sdrToken, 0, 0)

export const checkIsAddedToTimelock = async (sumer, symbol, amount) => {
  const name = sumer._network.name
  const sdrTokenAddress = Sumer.getAddress(name, `sdr${symbol}`)
  // console.log('sdrTokenAddress', sdrTokenAddress)
  const timelockAddr = Sumer.getAddress(name, 'Timelock')
  // console.log('timelockAddr', timelockAddr)

  const decimals = Sumer.getDecimals(name, symbol)
  // console.log('decimals', decimals)

  const underlyAmount = new BigNumber(amount).times(`1e${decimals}`).toFixed()

  const params = [underlyAmount, sdrTokenAddress]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.Timelock,
  }
  const results = await Sumer.eth.read(timelockAddr, 'consumeValuePreview', params, options)
  console.log('consumeValuePreview', results)
  return !results
}
