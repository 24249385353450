// import Sumer from '@meterio/sumer-js'
import * as Sumer from '@meterio/sumer-js'
import { getSupportNetwork } from '@/constants'
import { getAccountLimits, getTokenBalance } from '@/api'
import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { _ethers } from '@meterio/sumer-js'
import router from '../../router'
import { MulticallWrapper } from 'ethers-multicall-provider'
import { ethers } from 'ethers'

const namespaced = true
const state = {
  provider: null,
  web3Provider: null,
  multiProvider: null,
  signer: null,
  account: '',
  sumer: null,
  chainId: '',
  isWalletReset: true,
  walletIcon: '',
  isSupportNetwork: false,
  currentNetwork: null,

  limits: {
    markets: [],
    liquidity: '0',
    shortfall: '0',
  },
  balances: {
    suUSD: new BigNumber(0),
    sumer: new BigNumber(0),
  },

  initLoading: false,
  accounts: [],

  contracts: {},
}

const getters = {
  liquidityVal: (state) => new BigNumber(String(state.limits.liquidity)).div(1e18),
  shortfallVal: (state) => new BigNumber(String(state.limits.shortfall)).div(1e18),
  borrowLimit: (state, getters, rootState, rootGetters) =>
    new BigNumber(getters.liquidityVal).plus(rootGetters['market/userBorrowedTotalVal']).minus(getters.shortfallVal),
  borrowUsedPct: (state, getters, rootState, rootGetters) => {
    const borrowed = new BigNumber(rootGetters['market/userBorrowedTotalVal'])
    const liquidity = new BigNumber(getters.liquidityVal)
    if (borrowed.isLessThanOrEqualTo(0) && liquidity.isLessThanOrEqualTo(0)) {
      return 0
    }
    const shortfall = getters.shortfallVal
    return borrowed.times(100).div(borrowed.plus(liquidity).minus(shortfall)).toNumber()
  },
  borrowUsed: (state, getters, rootState, rootGetters) => new BigNumber(rootGetters['market/userBorrowedTotalVal']),
  borrowFree: (state, getters) => BigNumber(getters.liquidityVal),
  borrowFreePct: (state, getters) => 100 - getters.borrowUsedPct,
  enteredMarkets: (state) => state.limits.markets,
  enteredBySDRToken: (state) => (sdrTokenAddr) => {
    return state.limits.markets.includes(sdrTokenAddr.toLowerCase())
  },
}

const mutations = {
  setChainId(state, chainId) {
    state.chainId = chainId
  },
  setAccount(state, account) {
    state.account = String(account).toLowerCase()
  },
  setProvider(state, provider) {
    state.provider = provider
  },
  setWalletIcon(state, icon) {
    state.walletIcon = icon
  },
  setSumer(state, sumer) {
    state.sumer = sumer
  },
  clearWalletInfo(state) {
    state.provider = null
    state.accounts = []
    state.account = ''
    state.chainId = ''
    state.balances = {
      suUSD: new BigNumber(0),
      sumer: new BigNumber(0),
    }
    state.limits = {
      markets: [],
      liquidity: '0',
      shortfall: '0',
    }
  },
  setIsWalletReset(state, isWalletReset) {
    state.isWalletReset = isWalletReset
  },
  setIsSupportNetwork(state, isSupportNetwork) {
    state.isSupportNetwork = isSupportNetwork
  },
  setLimits(state, accountLimits) {
    state.limits = accountLimits
  },
  setWeb3Provider(state, web3Provider) {
    state.web3Provider = web3Provider
  },
  setMultiProvider(state, multiProvider) {
    state.multiProvider = multiProvider
  },
  setSigner(state, signer) {
    state.signer = signer
  },
  setCurrentNetwork(state, currentNetwork) {
    state.currentNetwork = currentNetwork
  },

  setInitLoading(state, loading) {
    state.initLoading = loading
  },
  setAccounts(state, accounts) {
    state.accounts = accounts
  },

  setContracts(state, contracts) {
    state.contracts = contracts
  },
}

const actions = {
  async updateLimits({ state, commit }) {
    const { sumer, account, contracts } = state
    console.log('sumer name', sumer._network.name)
    const limits = await getAccountLimits(contracts, sumer, account)
    // commit('setLimits', limits)
    const savedLimits = {
      markets: limits.markets.map((m) => m.toLowerCase()),
      liquidity: BigNumber(String(limits.liquidity)),
      shortfall: BigNumber(String(limits.shortfall)),
    }
    console.log('savedLimits', savedLimits)
    Vue.set(state, 'limits', savedLimits)
  },
  async updateBalances({ state, commit }) {
    const { sumer, account } = state
    const suUSD = Sumer.util.getAddress('suUSD', sumer._network.name)
    const sumerToken = Sumer.util.getAddress('SUMER', sumer._network.name)
    const suusdBalance = await getTokenBalance(sumer, account, suUSD)
    const sumerBalance = await getTokenBalance(sumer, account, sumerToken)
    const balances = {
      suUSD: BigNumber(String(suusdBalance)).div(1e18),
      sumer: BigNumber(String(sumerBalance)).div(1e18),
    }
    console.log('suusd,sumer balance', balances)
    Vue.set(state, 'balances', balances)
  },
  async initWallet({ state, commit, dispatch }, { accounts, chainId, provider, icon, disconnected }) {

    if (disconnected) {
      provider = new ethers.JsonRpcProvider('https://rpc.meter.io')
      chainId = 82
      accounts = [ { address: ethers.ZeroAddress, }, ]
      icon = ''
    }
    
    if (!accounts || !accounts.length) return
    if (accounts.length === 1 && state.initLoading) return

    commit('setAccounts', accounts)

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 1000)
    })

    const account = state.accounts[0]?.address
    if (account !== accounts[0].address) {
      return
    }

    if (accounts && chainId && provider && icon) {
      commit('setInitLoading', true)
    }
    try {
      const currentNetwork = getSupportNetwork().find((net) => net.networkId === chainId)
      
      if (currentNetwork) {
        commit('setIsSupportNetwork', true)
        commit('setCurrentNetwork', currentNetwork)
        commit('setChainId', chainId)
      } else {
        console.log('wrong network')
        commit('setCurrentNetwork', null)
        commit('setIsSupportNetwork', false)
        // commit('clearWalletInfo')
        // commit('market/clearMarkets', null, { root: true })
        commit('setChainId', chainId)

        commit('setProvider', provider)
        commit('setAccount', account)
        commit('modal/SHOW_CHECK_NETWORK', null, { root: true })
      }

      if (provider) {
        commit('setProvider', provider)
        
        const web3Provider = account === ethers.ZeroAddress ? provider : new _ethers.BrowserProvider(provider)
        const signer = account === ethers.ZeroAddress ? provider : await web3Provider.getSigner()

        commit('setWeb3Provider', web3Provider)
        commit('setSigner', signer)
        try {
          let readRpc = 'https://rpc.meter.io'
          let networkId = 82
          let networkName = 'metermain'
          if (currentNetwork) {
            readRpc = currentNetwork.readRpc
            networkId = currentNetwork.networkId
            networkName = currentNetwork.nameInCompoundjs
          }
          const jsonProvider = new _ethers.JsonRpcProvider(readRpc)
          let multiProvider = networkId === 810180 ? jsonProvider : MulticallWrapper.wrap(jsonProvider)

          commit('setMultiProvider', multiProvider)

          const compoundLensAddr = Sumer.util.getAddress('CompoundLens', networkName)
          let CompoundLens = new _ethers.Contract(compoundLensAddr, Sumer.abi.CompoundLens, jsonProvider)
          try {
            await CompoundLens.version()
            CompoundLens = new _ethers.Contract(compoundLensAddr, Sumer.abi.CompoundLens, multiProvider)
          } catch (e) {
            console.log(`compoundlens error: `, e)
            CompoundLens = new _ethers.Contract(compoundLensAddr, Sumer.abi.CompoundLensV2, multiProvider)
          }
          const comptrollerAddr = Sumer.util.getAddress('Comptroller', networkName)
          let Comptroller = new _ethers.Contract(comptrollerAddr, Sumer.abi.Comptroller, jsonProvider)
          try {
            const version = await Comptroller.version()
            console.log('comptroller version: ', version)
            if (version == 3) {
              Comptroller = new _ethers.Contract(comptrollerAddr, Sumer.abi.Comptroller, multiProvider)
            } else {
              Comptroller = new _ethers.Contract(comptrollerAddr, Sumer.abi.ComptrollerV2, multiProvider)
            }
          } catch (e) {
            console.log(`comptroller error`, e)
            Comptroller = new _ethers.Contract(comptrollerAddr, Sumer.abi.ComptrollerV1, multiProvider)
            console.log(`comptroller v1`)
          }
          // const priceOracleAddr = Sumer.util.getAddress('FeedPriceOracle', networkName)
          const priceOracleAddr = await Comptroller.oracle()
          // console.log('priceOracleAddr', priceOracleAddr)
          const PriceOracle = new _ethers.Contract(priceOracleAddr, Sumer.abi.FeedPriceOracle, multiProvider)
          commit('setContracts', {
            CompoundLens,
            Comptroller,
            PriceOracle,
          })
        } catch (e) {
          console.log('init multicall contract', e)
        }

        await dispatch('initSumer', { signer, currentNetwork })
      }

      if (icon) {
        commit('setWalletIcon', icon)
      }

      if (account) {
        console.log('wallet account ', account)
        commit('setAccount', account)
      }

      if (state.provider && state.account && state.chainId) {
        commit('setIsWalletReset', false)
      }

      const route = router.currentRoute
      console.log('route', route.name)
      if (route.name === 'Bridge') {
        await dispatch('suTokenBridge/initChain', null, { root: true })
      } else if (route.name === 'Account') {
        await dispatch('shylock/initShylockAccount', { isGetAllAccount: false }, { root: true })
      } else if (route.name === 'AccountAll') {
        await dispatch('shylock/initShylockAccount', { isGetAllAccount: true }, { root: true })
      } else if (route.name === 'Plan') {
        await dispatch('shylock/initShylockPlan', { account: route.params.account }, { root: true })
      } else if (route.name === 'Summary' || route.name === 'Detail') {
        //
      } else if (route.name === 'GasSwap') {
        //
      } else {
        await dispatch('market/initMarket', true, { root: true })
      }
    } catch (e) {
      console.log('init wallet err', e)
    } finally {
      commit('setInitLoading', false)
    }
  },
  async initSumer({ state, commit }, { signer, currentNetwork }) {
    console.log('currentNetwork', currentNetwork)
    let networkId = 82, nameInCompoundjs = 'metermain'
    if (currentNetwork) {
      networkId = currentNetwork.networkId
      nameInCompoundjs = currentNetwork.nameInCompoundjs
    }
    const sumer = new Sumer(signer, {
      networkId: networkId,
      networkName: nameInCompoundjs,
    })
    commit('setSumer', sumer)
  },
}

export const wallet = { namespaced, state, getters, mutations, actions }
