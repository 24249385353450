import axios from "axios"

const baseUrl = 'https://api.meter.io:8000/api/twitter'
const callbackUrl = 'https://app.sumer.money'

export const requestToken = async () => {
  const url = `${baseUrl}/requestToken?callbackUrl=${callbackUrl}`
  const res = await axios.get(url)
  console.log('requestToken res', res)
  return res.data
}

export const accessToken = async (oauth_token, oauth_verifier) => {
  const url = `${baseUrl}/accessToken/${oauth_token}/${oauth_verifier}`
  const res = await axios.get(url)
  return res.data
}

export const createTweet = async(oauth_token, oauth_token_secret, text) => {
  const url = `${baseUrl}/create/${oauth_token}/${oauth_token_secret}?text=${text}`
  const res = await axios.get(url)
  return res.data
}