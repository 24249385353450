import {
  getPlans,
  getPositions,
  getPaginateAccount,
  getPaginateAccountInsolvent,
  liquidateAccountLimit,
} from '../../api/shylock'
import * as Sumer from '@meterio/sumer-js'
import BigNumber from 'bignumber.js'
import { getTokenPairs } from '@meterio/sumer-js'

const namespaced = true

const state = {
  plans: [],
  accounts: {
    totalRows: 0,
    data: [],
  },
  accountAssets: [],
  userAssets: [],

  planLoading: true,
  accountsLoading: true,
  accountAssetsLoading: true,
  userAssetsLoading: true,

  tokens: {},
}

const getters = {}

const mutations = {
  setPlans(state, plans) {
    state.plans = plans
  },
  setAccounts(state, accounts) {
    state.accounts = accounts
  },
  setAccountAssets(state, assets) {
    state.accountAssets = assets
  },
  setUserAssets(state, assets) {
    state.userAssets = assets
  },

  setPlanLoading(state, planLoading) {
    state.planLoading = planLoading
  },
  setAccountsLoading(state, accountsLoading) {
    state.accountsLoading = accountsLoading
  },
  setAccountAssetsLoading(state, accountAssetsLoading) {
    state.accountAssetsLoading = accountAssetsLoading
  },
  setUserAssetsLoading(state, userAssetsLoading) {
    state.userAssetsLoading = userAssetsLoading
  },

  setSupportTokens(state, tokens) {
    state.tokens = tokens
  },
}

const actions = {
  async initShylockAccount({ rootState, commit, dispatch }, { isGetAllAccount } = { isGetAllAccount: false }) {
    console.log('isGetAllAccount', isGetAllAccount)
    const { metadataMap } = rootState.market
    if (!Object.keys(metadataMap).length) {
      await dispatch('market/updateMetadataMap', null, { root: true })
    }
    await dispatch('market/updateBalanceMap', null, { root: true })

    await dispatch('updateSupportTokens')

    if (isGetAllAccount) {
      await dispatch('getAccountAll')
    } else {
      await dispatch('getAccount', { page: 1 })
    }
  },
  async initShylockPlan({ rootState, dispatch }, { account }) {
    const { metadataMap } = rootState.market

    if (!Object.keys(metadataMap).length) {
      await dispatch('market/updateMetadataMap', null, { root: true })
    }
    await dispatch('market/updateBalanceMap', null, { root: true })

    await dispatch('updateSupportTokens')

    dispatch('getPlans', { account })
    dispatch('getAccountAssets', { account })
    dispatch('getUserAssets')
  },
  async getAccount({ rootState, state, commit, dispatch }, { page }) {
    const { sumer, currentNetwork } = rootState.wallet

    if (!sumer) {
      return console.log('can not get sumer')
    }

    if (!currentNetwork.liquidationBaseUrl) {
      return console.log('no liquidationBaseUrl')
    }

    commit('setAccounts', {
      totalRows: 0,
      data: [],
    })

    commit('setAccountsLoading', true)

    if (!page) {
      page = 1
    }

    // const res = await getPaginateAccount(currentNetwork.liquidationBaseUrl, page)
    let res = await getPaginateAccountInsolvent(currentNetwork.liquidationBaseUrl, 1)
    console.log('res11', res)
    if (res.data.totalRows) {
      res = await getPaginateAccountInsolvent(currentNetwork.liquidationBaseUrl, 1, res.data.totalRows)
    }

    if (res && res.data && res.data.accounts) {
      await dispatch('market/updatePriceMap', null, { root: true })
      const priceMap = rootState.market.priceMap
      const marketInfo = rootState.market.marketList
      console.log('marketInfo', marketInfo)

      const accounts = []
      for (const a of res.data.accounts) {
        const position = a.position
        let suppliedVal = new BigNumber(0)
        let borrowedVal = new BigNumber(0)
        console.log('state.tokens', state.tokens)
        for (const p of position) {
          if (p.tokenAddress in state.tokens) {
            const decimals = state.tokens[p.tokenAddress].underlyDecimal
            suppliedVal = new BigNumber(p.supply).div(`1e${decimals}`).times(priceMap[p.tokenAddress]).plus(suppliedVal)
            borrowedVal = new BigNumber(p.borrow).div(`1e${decimals}`).times(priceMap[p.tokenAddress]).plus(borrowedVal)
          }
        }
        accounts.push({
          ...a,
          shortfall: new BigNumber(a.shortfall),
          suppliedVal,
          borrowedVal,
        })
      }

      commit('setAccounts', {
        totalRows: res.data.totalRows,
        data: accounts
          .sort((a, b) => {
            if (a.shortfall.gt(b.shortfall)) {
              return -1
            } else if (a.shortfall.lt(b.shortfall)) {
              return 1
            } else {
              if (a.borrowedVal.gt(b.borrowedVal)) {
                return -1
              } else if (a.borrowedVal.lt(b.borrowedVal)) {
                return 1
              } else {
                if (a.suppliedVal.gt(b.suppliedVal)) {
                  return -1
                } else if (a.suppliedVal.lt(b.suppliedVal)) {
                  return 1
                } else {
                  return 0
                }
              }
            }
          })
          .splice((page - 1) * liquidateAccountLimit, liquidateAccountLimit),
      })
    } else {
      console.log('cannot get accounts')
    }

    commit('setAccountsLoading', false)
  },
  async getAccountAll({ rootState, state, commit, dispatch }) {
    console.log('getAccountAll')
    const { sumer, currentNetwork } = rootState.wallet

    if (!sumer) {
      return console.log('can not get sumer')
    }

    if (!currentNetwork.liquidationBaseUrl) {
      return console.log('no liquidationBaseUrl')
    }

    commit('setAccounts', {
      totalRows: 0,
      data: [],
    })

    commit('setAccountsLoading', true)

    let res = await getPaginateAccount(currentNetwork.liquidationBaseUrl, 1)
    console.log('res1', res)
    if (res.data.totalRows) {
      res = await getPaginateAccount(currentNetwork.liquidationBaseUrl, 1, res.data.totalRows)
    }

    if (res && res.data && res.data.accounts) {
      await dispatch('market/updatePriceMap', null, { root: true })
      const { priceMap, metadataMap } = rootState.market

      const accounts = []
      for (const a of res.data.accounts) {
        const position = a.position
        let suppliedVal = new BigNumber(0)
        let borrowedVal = new BigNumber(0)
        for (const p of position) {
          if (p.tokenAddress in state.tokens) {
            const price = priceMap[p.tokenAddress]
            const exchangeRate = new BigNumber(String(metadataMap[p.tokenAddress].exchangeRateCurrent)).div('1e18')
            // console.log('exchangeRate', exchangeRate.toFixed())
            const decimals = state.tokens[p.tokenAddress].underlyDecimal
            suppliedVal = new BigNumber(p.supply).div(`1e${decimals}`).times(price).times(exchangeRate).plus(suppliedVal)
            borrowedVal = new BigNumber(p.borrow).div(`1e${decimals}`).times(price).times(exchangeRate).plus(borrowedVal)
          }
        }
        accounts.push({
          ...a,
          shortfall: new BigNumber(a.shortfall),
          suppliedVal,
          borrowedVal,
        })
      }
      commit('setAccounts', {
        totalRows: res.data.totalRows,
        data: accounts.sort((a, b) => {
          if (a.shortfall.gt(b.shortfall)) {
            return -1
          } else if (a.shortfall.lt(b.shortfall)) {
            return 1
          } else {
            if (a.borrowedVal.gt(b.borrowedVal)) {
              return -1
            } else if (a.borrowedVal.lt(b.borrowedVal)) {
              return 1
            } else {
              if (a.suppliedVal.gt(b.suppliedVal)) {
                return -1
              } else if (a.suppliedVal.lt(b.suppliedVal)) {
                return 1
              } else {
                return 0
              }
            }
          }
        }),
      })
    } else {
      console.log('cannot get accounts')
    }

    commit('setAccountsLoading', false)
  },
  async getAccountAssets({ rootState, state, commit, dispatch }, { account }) {
    const { sumer, currentNetwork } = rootState.wallet
    if (!sumer) {
      return console.log('can not get sumer')
    }

    if (!currentNetwork.liquidationBaseUrl) {
      return console.log('no liquidationBaseUrl')
    }

    commit('setAccountAssetsLoading', true)

    const res = await getPositions(currentNetwork.liquidationBaseUrl, account)

    await dispatch('market/updatePriceMap', null, { root: true })
    const { priceMap, metadataMap } = rootState.market

    if (res && res.data && res.data.result) {
      const accountAssets = []
      for (const p of res.data.result) {
        if (!(p.tokenAddress in state.tokens)) {
          continue
        }

        const symbol = state.tokens[p.tokenAddress].underlySym
        const decimals = state.tokens[p.tokenAddress].underlyDecimal

        const price = priceMap[p.tokenAddress]
        const exchangeRate = new BigNumber(String(metadataMap[p.tokenAddress].exchangeRateCurrent)).div('1e18')
        let formatSupply = 0
        let formatBorrow = 0
        if (p.supply !== '0') {
          formatSupply = new BigNumber(p.supply).div(`1e${decimals}`).times(exchangeRate)
        }
        if (p.borrow !== '0') {
          formatBorrow = new BigNumber(p.borrow).div(`1e${decimals}`).times(exchangeRate)
        }
        accountAssets.push({
          ...p,
          price,
          formatSupply,
          formatBorrow,
          symbol,
          decimals,
        })
      }

      console.log('accountAssets', accountAssets)

      commit('setAccountAssets', accountAssets)
    }

    commit('setAccountAssetsLoading', false)
  },

  async getUserAssets({ rootState, state, commit, dispatch }) {
    const { sumer, currentNetwork, account } = rootState.wallet
    if (!sumer) {
      return console.log('can not get sumer')
    }

    if (!currentNetwork.liquidationBaseUrl) {
      return console.log('no liquidationBaseUrl')
    }

    commit('setUserAssetsLoading', true)

    const res = await getPositions(currentNetwork.liquidationBaseUrl, account)

    await dispatch('market/updatePriceMap', null, { root: true })

    const { priceMap, metadataMap } = rootState.market

    if (res && res.data && res.data.result) {
      const accountAssets = []
      for (const p of res.data.result) {
        if (!(p.tokenAddress in state.tokens)) {
          continue
        }

        const symbol = state.tokens[p.tokenAddress].underlySym
        const decimals = state.tokens[p.tokenAddress].underlyDecimal

        const price = priceMap[p.tokenAddress]
        const exchangeRate = new BigNumber(String(metadataMap[p.tokenAddress].exchangeRateCurrent)).div('1e18')
        let formatSupply = 0
        let formatBorrow = 0
        if (p.supply !== '0') {
          formatSupply = new BigNumber(p.supply).div(`1e${decimals}`).times(exchangeRate)
        }
        if (p.borrow !== '0') {
          formatBorrow = new BigNumber(p.borrow).div(`1e${decimals}`).times(exchangeRate)
        }
        accountAssets.push({
          ...p,
          price,
          formatSupply,
          formatBorrow,
          symbol: symbol,
          decimals,
        })
      }

      commit('setUserAssets', accountAssets)
    }

    commit('setUserAssetsLoading', false)
  },

  async getPlans({ rootState, commit, dispatch }, { account }) {
    const { sumer, currentNetwork } = rootState.wallet
    if (!sumer) {
      return console.log('can not get sumer')
    }

    if (!currentNetwork.liquidationBaseUrl) {
      return console.log('no liquidationBaseUrl')
    }

    const { metadataMap, balanceMap } = rootState.market

    commit('setPlanLoading', true)
    const res = await getPlans(currentNetwork.liquidationBaseUrl, account)
    if (res && res.data && res.data.result) {
      const plans = []
      for (const p of res.data.result) {
        if (!(p.repayTokenAddress in state.tokens) || !(p.seizeTokenAddress in state.tokens)) {
          continue
        }

        const repayTokenSymbol = state.tokens[p.repayTokenAddress].ctokenSym
        const repayTokenDecimals = state.tokens[p.repayTokenAddress].ctokenDecimal
        const seizeTokenSymbol = state.tokens[p.seizeTokenAddress].ctokenSym
        const seizeTokenDecimals = state.tokens[p.seizeTokenAddress].ctokenDecimal

        const exchangeRate = new BigNumber(String(metadataMap[p.seizeTokenAddress].exchangeRateCurrent)).div('1e18')

        plans.push({
          ...p,
          repayUnderlyingTokenSymbol: repayTokenSymbol.substring(3),
          repayTokenSymbol: repayTokenSymbol,
          repayTokenDecimals,
          seizeUnderlyingTokenSymbol: seizeTokenSymbol.substring(3),
          seizeCTokenSymbol: seizeTokenSymbol,
          seizeTokenDecimals,
          repayTokens: new BigNumber(p.repayTokens).times(`1e${repayTokenDecimals}`).toFixed(6, 1),
          seizeTokens: new BigNumber(p.seizeTokens).times(`1e${seizeTokenDecimals}`).toFixed(6, 1),
          formatRepayTokens: new BigNumber(p.repayTokens).toFixed(6, 1),
          formatSeizeTokens: new BigNumber(p.seizeTokens).toFixed(6, 1),
          formatSeizeCTokens: new BigNumber(p.seizeTokens).div(exchangeRate).toFixed(6, 1),
          walletBalance: new BigNumber(String(balanceMap[p.repayTokenAddress].tokenBalance)),
          formatWalletBalance: new BigNumber(String(balanceMap[p.repayTokenAddress].tokenBalance))
            .div(`1e${repayTokenDecimals}`)
            .toFixed(6, 1),
        })
      }
      console.log('plans', plans)
      commit('setPlans', plans)
    } else {
      console.log('cannot get plans')
    }
    commit('setPlanLoading', false)
  },
  async updateSupportTokens({ rootState, commit }) {
    const { sumer } = rootState.wallet

    if (!sumer) {
      return console.log('can not get sumer')
    }
    const supportTokens = {}
    const supportedPairs = getTokenPairs(sumer._network.name)
    for (const pair of supportedPairs) {
      const sdrTokenAddr = Sumer.util.getAddress(pair.ctokenSym, sumer._network.name)
      supportTokens[sdrTokenAddr.toLowerCase()] = {
        ...pair,
      }
    }
    commit('setSupportTokens', supportTokens)
  },
}

export const shylock = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
