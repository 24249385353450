<template>
  <div v-if="show" class="mt-8">
    <v-btn color="accent" depressed large block @click="connect()">
      Connect Wallet
    </v-btn>
  </div>
</template>

<script>
import { WalletBoardBus } from '@/WalletBoard'
import { mapState } from 'vuex'
import { ethers } from 'ethers'
export default {
  name: "ConnectWallet",
  computed: {
    ...mapState('wallet', ['account']),
    show() {
      if (this.account === ethers.ZeroAddress) {
        return true
      }
      return false
    }
  },
  methods: {
    connect() {
      WalletBoardBus.$emit('connect')
    }
  }
}
</script>