<template>
  <v-dialog v-if="showProtectedMintSyn" v-model="computedShow" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between">
        <crypto-icon :token="market.underlySymbol" :name="market.underlyName" action="" />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="my-4">
        <!-- Amount Section -->
        <amount-input v-model="mintAmount" label="Mint Amount" :maxVal="maxVal" :focus="true"></amount-input>
        <custom-select
          label="Select Asset to Borrow as Collateral"
          :value="borrowToken"
          :options="assetOptions"
          @selected="tokenSelected"
        />
        <amount-input v-model="borrowAmount" label="Borrow Amount" :maxVal="maxVal" :focus="false"></amount-input>

        <v-btn @click="updateCBorrowAmount" depressed large block elevation="0">
          Calc {{ borrowToken.symbol }} min borrow amount
        </v-btn>
        <v-btn class="mt-2" color="#D4A85F" @click="protectedMintSyn" depressed large block elevation="0">
          Mint {{ market.underlySymbol }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BigNumber from 'bignumber.js'
import { getTokenPairs } from '@meterio/sumer-js'
import CryptoIcon from '@/components/CryptoIcon'
export default {
  name: 'ProtectedMintSyn',
  components: {
    CryptoIcon,
  },
  props: {},
  data() {
    return {
      mintAmount: '0',
      maxVal: '100000',
      borrowAmount: '0',
      borrowToken: { name: 'USDT', symbol: 'USDT' },
    }
  },
  computed: {
    ...mapState('modal', ['showProtectedMintSyn', 'protectedMintSynToken']),
    ...mapState('market', 'cMinBorrowAmount'),
    ...mapState('wallet', ['sumer']),
    ...mapGetters('market', ['marketBySDRToken']),

    computedShow: {
      get() {
        return this.showProtectedMintSyn
      },
      set() {
        this.close()
      },
    },

    market() {
      return this.marketBySDRToken(this.protectedMintSynToken)
    },
    assetOptions() {
      const pairs = getTokenPairs(this.sumer._network.name)
      return pairs
        .map((p) => p.underlySym)
        .filter((p) => p != this.market.underlySymbol)
        .map((p) => ({ name: p, symbol: p }))
    },
  },
  methods: {
    ...mapActions({
      protectedMintSynAction: 'tx/protectedMintSynAction',
      updateCMinBorrowAmount: 'market/updateCMinBorrowAmount',
    }),
    tokenSelected(data) {
      // this.updateCurrentToken({ currentToken: data })
      this.borrowToken = data
    },
    close() {
      this.$store.commit('modal/HIDE_PROTECTED_MINT_SYN')
    },
    async updateCBorrowAmount() {
      if (this.mintAmount.trim() === '' && Number(this.mintAmount) > 0) {
        return (this.notEmpty = true)
      }

      const result = await this.updateCMinBorrowAmount({
        underlySymbol: this.market.underlySymbol,
        collateralSymbol: this.borrowToken.symbol,
        mintAmount: new BigNumber(this.mintAmount).toFixed(),
      })
      console.log('result', result)
      this.borrowAmount = String(result)
    },
    async protectedMintSyn() {
      try {
        if (this.mintAmount.trim() === '' && Number(this.mintAmount) > 0) {
          return (this.notEmpty = true)
        }
        this.notEmpty = false

        console.log('market', this.market)

        await this.protectedMintSynAction({
          underlySymbol: this.market.underlySymbol,
          collateralSymbol: this.borrowToken.symbol,
          borrowAmount: new BigNumber(this.borrowAmount).toFixed(0),
          mintAmount: new BigNumber(this.mintAmount).toFixed(0),
        })
      } catch (e) {
        console.log('redeemFaceValue error: ', e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
