<template>
  <div
    class="chain-item-container d-flex justify-space-between align-center pa-1"
    :class="{'border-bottom': borderBottom }"
    @click="click">
    <div class="d-flex">
      <v-img class="d-flex justify-center" :src="url" :max-width="35" :min-width="35" aspect-ratio="1" contain />
      <span class="ml-2 d-flex align-center">{{ networkName }}</span>
    </div>
    <!-- <v-icon>mdi-chevron-right</v-icon> -->
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    data: {
      type: Object,
      require: true,
    },
    borderBottom: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    url() {
      try {
        let logo = require(`@/assets/images/crypto/${this.data.symbol.toLowerCase()}.png`)
        if (this.data.networkId) {
          logo = require(`@/assets/images/network/${this.data.networkId}.png`)
        }
        return logo
      } catch (e) {
        return require(`@/assets/images/logo.png`)
      }
    },
    networkName() {
      return this.data.name
    },
  },
  methods: {
    click() {
      this.$emit('click', this.data)
    },
  },
}
</script>

<style lang="scss" scoped>
.chain-item-container {
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: lightgray;
  }
}
.border-bottom {
  border-bottom: 1px solid #dadddf;
}
</style>