<template>
  <div class="table-container">
    <slot name="title"></slot>
    
    <section v-if="data.length">
      <div v-if="$vuetify.breakpoint.name !== 'xs'">
        <v-row dense class="font-weight-regular flex-nowrap font-size-xs secondary--text">
          <v-col
            class="d-flex justify-start align-center"
            :class="{
              'justify-start': h.align === 'start',
              'justify-center': h.align === 'center',
              'justify-end': h.align === 'end',
            }"
            v-for="(h, i) in headers"
            :key="i"
            :cols="getCols(h.width)"
          >
            <tooltip-label :tooltip="h.title" size="small">
              <span>{{ h.text }}</span>
            </tooltip-label>
          </v-col>
        </v-row>
        <c-divider class="my-2" />
        <v-row dense v-for="(d, i) in data" :key="i" class="table-row py-2">
          <v-col
            class="d-flex align-center justify-start py-0"
            :class="{
              'justify-start': h.align === 'start',
              'justify-center': h.align === 'center',
              'justify-end': h.align === 'end',
              clickable: h.clickable,
              underscore: h.underscore,
              'text-start': h.align === 'start',
              'text-center': h.align === 'center',
              'text-end': h.align === 'end',
            }"
            v-for="(h, j) in headers"
            :key="j"
            :cols="getCols(h.width)"
            @click="clickRow(d, h)"
          >
            <div v-if="d[h.value] && d[h.value].type == 'balanceAndValue'">
              <div class="text-color">{{ d[h.value].balance }}</div>
              <div v-if="d[h.value].balance != 0" class="font-size-sm text-color-light">{{ d[h.value].value }}</div>
            </div>
            <div v-else class="text-color">
              <slot :name="h.value" v-bind:row="d">{{ d[h.value] }}</slot>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div v-for="(d, i) in data" :key="i">
          <v-row dense v-for="h in headers" :key="h.value" class="table-row" @click="clickRow(d, h)">
            <v-col align-self="center" class="font-weight-bold">{{ h.text }}</v-col>
            <v-col>
              <div v-if="d[h.value] && d[h.value].type == 'balanceAndValue'">
                <div class="text-color">{{ d[h.value].balance }}</div>
                <div v-if="d[h.value].balance != 0" class="font-size-sm text-color-light">{{ d[h.value].value }}</div>
              </div>
              <div v-else class="text-color">
                <slot :name="h.value" v-bind:row="d">{{ d[h.value] }}</slot>
              </div>
            </v-col>
          </v-row>
          <c-divider v-if="i < data.length - 1" height="1" class="my-2" />
        </div>
      </div>
    </section>
    <section v-else class="text-center my-4">
      <div v-if="loading" :style="{ color: '#aaa69d' }">
        <v-progress-circular :size="15" :width="3" indeterminate></v-progress-circular>
        Loading...
      </div>
      <div v-else>{{ emptyText }}</div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
    emptyText: {
      type: String,
      default: 'No Data',
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    clickRow(row, header) {
      if (header.clickable) {
        this.$emit('clickRow', row)
      }
    },
    getCols(width) {
      if (width) {
        return width
      }
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.color-headline {
  color: #020826;
}
h3 {
  font-size: 1rem;
}
.table-row {
  &:hover {
    background-color: #f7f1e3;
    border-radius: 6px;
    .underscore {
      text-decoration: underline;
    }
    .clickable {
      cursor: pointer;
    }
  }
}
.table-row + .table-row {
  margin-top: 12px !important;
}
</style>
