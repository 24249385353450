<template>
  <div class="set-font pt-6">
    <div v-if="approved" class="d-flex justify-space-between text-color font-weight-bold">
      <div>Borrowing:</div>
      <div>{{ computedLiability }}</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      v-if="approved"
      v-model="amount"
      label="Repay Amount"
      :maxVal="maxVal"
      :hint="amountHint"
      hideDetails
      :focus="focus"
      @maxClicked="maxClicked"
    ></amount-input>

    <div class="d-flex justify-space-between my-4 text-color">
      <div>Wallet balance</div>
      <div>{{ amountHint }}</div>
    </div>

    <!-- Borrow Limit Section -->
    <borrow-limit-section
      class="text-color"
      v-if="approved"
      :borrowAPY="borrowAPY"
      :rewardAPY="rewardAPY"
      :usedDelta="usedDelta"
      :borrowedVal="String(borrowedVal)"
      :liquidity="String(liquidity)"
      :token="value"
    />

    <connect-wallet></connect-wallet>

    <div class="red--text mt-8">
      <span v-if="tip">{{ tip }}</span>
    </div>
    <v-btn v-if="approved" color="accent" :disabled="actionBtnDisabled" large block @click="repay(amount)">
      Repay {{ value.underlySymbol }}
    </v-btn>

    <!-- Enable Btn -->
    <v-btn v-else class="mt-8" color="accent" depressed large block :loading="enableLoading" @click="enable">
      <span>Enable {{ value.underlySymbol }}</span>
    </v-btn>
  </div>
</template>
<script>
import { BigNumber } from 'bignumber.js'
import { mapActions, mapGetters } from 'vuex'
import { UNLIMITED_APPROVE_AMOUNT } from '@/constants'
import BorrowRateSection from './borrowRateSection.vue'
import BorrowLimitSection from './borrowLimitSection.vue'
import { formatNumReadable } from '@/utils'

export default {
  name: 'RepayTab',
  components: { BorrowRateSection, BorrowLimitSection },
  props: {
    value: {
      type: Object,
      require: true,
    },
    focus: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      amount: '',
      enableLoading: false,
      clickedMax: false,
    }
  },
  created() {
    this.clickedMax = false
  },
  watch: {
    amount(newVal) {
      if (BigNumber(newVal).lt(this.maxVal)) {
        this.clickedMax = false
      }
    },
  },
  computed: {
    ...mapGetters('wallet', ['borrowUsed', 'borrowFree']),
    ...mapGetters('market', ['balanceBySDRToken', 'hypoLiquidityBySDRToken']),
    rewardAPY() {
      return '0'
    },
    borrowAPY() {
      return this.value.borrowAPY
    },
    borrowedVal() {
      return this.value.borrowedVal
    },
    amountHint() {
      return `${this.value.underlyBalance} ${this.value.underlySymbol}`
    },
    maxVal() {
      const w = new BigNumber(this.value.underlyBalance)
      const b = new BigNumber(this.value.borrowed)
      if (w.isLessThan(b)) {
        return w.toFixed()
      }
      return b.toFixed()
    },
    usedDelta() {
      return new BigNumber(this.amount).times(this.value.price).times(-1).toFixed()
    },
    borrowLimitDelta() {
      return '0'
    },
    tip() {
      if (Number(this.amount) > Number(this.value.underlyBalance)) {
        return 'Insufficient balance'
      }
    },
    actionBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) <= 0 ||
        Number(this.amount) > Number(this.value.underlyBalance) ||
        Number(this.amount) > Number(this.value.borrowed)
      )
    },
    liquidity() {
      const hypo = this.hypoLiquidityBySDRToken(this.value.sdrTokenAddress)
      return hypo.liquidity
    },
    approved() {
      return true
      // return this.value && new BigNumber(String(this.value.tokenAllowance)).isGreaterThan(0)
    },
    computedLiability() {
      return `${this.value.borrowed} ${this.value.underlySymbol}`
    },
  },
  methods: {
    ...mapActions({
      repayBorrow: 'tx/repayBorrow',
      approve: 'tx/approve',
    }),
    maxClicked() {
      console.log(this.value)
      const w = new BigNumber(this.value.underlyBalance)
      const b = new BigNumber(this.value.borrowed)
      if (w.isGreaterThanOrEqualTo(b)) {
        this.clickedMax = true
      }
    },
    async repay(amount) {
      let _amount = new BigNumber(amount).times(`1e${this.value.underlyDecimals}`)
      if (this.clickedMax) {
        if (this.value.isCEther) {
          _amount = new BigNumber(amount).times(`1e${this.value.underlyDecimals}`).times(1001).div(1000)
        } else {
          _amount = UNLIMITED_APPROVE_AMOUNT
        }
      }
      const params = {
        underlySymbol: this.value.underlySymbol,
        amount: _amount,
        isCEther: this.value.isCEther,
        isMax: this.clickedMax,
        noDecimalsAmount: new BigNumber(amount).toFixed(),
      }
      await this.repayBorrow(params)
    },
    async enable() {
      this.enableLoading = true
      const params = {
        symbol: this.value.underlySymbol,
        token: this.value.underlyAddr,
        spender: this.value.sdrTokenAddress,
        flag: true
      }
      await this.approve(params)
      this.enableLoading = false
    },
  },
}
</script>
