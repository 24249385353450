<template>
  <v-dialog v-if="showDeposit" v-model="computedShowDeposit" max-width="450">
    <v-card outlined class="modal-card" rounded="lg">
      <v-card-title class="d-flex justify-space-between flex-nowrap">
        <div class="d-flex align-center">
          <crypto-icon :token="market.underlySymbol" :name="market.underlyName" :info="market" />
          <v-btn class="ml-1" outlined x-small color="secondary" @click="goDetail">detail</v-btn>
        </div>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-tabs fixed-tabs color="#7A573B" class="sumer" @change="tabChanged">
          <v-tab class="left-tab"><span class="font-weight-bold">Deposit</span></v-tab>
          <v-tab class="right-tab"><span class="font-weight-bold">Withdraw</span></v-tab>

          <v-tab-item>
            <deposit-tab v-model="market" :focus="selectedTab === 0" />
          </v-tab-item>
          <v-tab-item>
            <withdraw-tab v-model="market" :focus="selectedTab === 1" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import CryptoIcon from '@/components/CryptoIcon'
import WithdrawTab from './withdrawTab.vue'
import DepositTab from './depositTab.vue'
export default {
  name: 'DepositModal',
  components: {
    CryptoIcon,
    DepositTab,
    WithdrawTab,
  },
  props: {},
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    ...mapState('modal', ['showDeposit', 'depositSDRToken']),
    ...mapGetters('market', ['marketBySDRToken']),
    computedShowDeposit: {
      get() {
        return this.showDeposit
      },
      set() {
        this.close()
      },
    },
    market() {
      return this.marketBySDRToken(this.depositSDRToken)
    },
  },
  methods: {
    close() {
      this.$store.commit('modal/HIDE_DEPOSIT')
    },
    tabChanged(index) {
      // console.log('change tab to ', index)
      this.selectedTab = index
    },
    goDetail() {
      window.open(`${window.location.protocol}//${window.location.host}/market/detail/${this.market.underlyAddr}`)
      // this.close()
      // this.$router.push({
      //   name: 'TokenDetail',
      //   params: {
      //     address: this.market.underlyAddr
      //   }
      // })
    },
  },
}
</script>
<style lang="scss">
@import '@/style/_variable.scss';

.v-tabs-bar {
  height: 2.4rem !important;
}
</style>
