import { BigNumber } from 'bignumber.js'
import { getTokenBalance } from '@/api'
import * as Sumer from '@meterio/sumer-js'
import { getHypoLiquidity } from '../../api'
const namespaced = true

const state = {
  mintedSuUSD: 0,
}

const getters = {}

const mutations = {
  setMintedSuUSD(state, mintedSuUSD) {
    state.mintedSuUSD = mintedSuUSD
  },
}

const actions = {
  async getMintedSuUSDBalance({ commit, rootState }) {
    const suUSD = Sumer.util.getAddress('suUSD', rootState.wallet.sumer._network.name)

    const balance = await getTokenBalance(rootState.wallet.sumer, rootState.wallet.account, suUSD)
    commit('setMintedSuUSD', new BigNumber(String(balance)).div(1e18).toFixed())
  },
  async getHypoLiquidity({ rootState, commit }) {
    const sumer = rootState.wallet.sumer
    const comptroller = Sumer.util.getAddress('Comptroller', this.sumer._network.name)

    const params = [
      this.account,
      Sumer.util.getAddress('csuUSD', sumer._network.name),
      Sumer._ethers.toBigInt('0'),
      Sumer._ethers.toBigInt('0'),
    ]

    const options = {
      _sumerProvider: this.sumer._provider,
      abi: Sumer.abi.Comptroller,
    }
    console.log('BEFORE ####')
    const hypoLiquidity = await Sumer.eth.read(comptroller, 'getHypotheticalAccountLiquidity', params, options)
    console.log(hypoLiquidity)
    if (hypoLiquidity.length == 3) {
      return {
        err: hypoLiquidity['0'],
        liquidity: hypoLiquidity['1'],
        shortfall: hypoLiquidity['2'],
      }
    } else {
      return {
        liquidity: hypoLiquidity['0'],
        shortfall: hypoLiquidity['1'],
      }
    }
  },
}

export const mint = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
