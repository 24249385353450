var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_vm._t("title"),(_vm.data.length)?_c('section',[(_vm.$vuetify.breakpoint.name !== 'xs')?_c('div',[_c('v-row',{staticClass:"font-weight-regular flex-nowrap font-size-xs secondary--text",attrs:{"dense":""}},_vm._l((_vm.headers),function(h,i){return _c('v-col',{key:i,staticClass:"d-flex justify-start align-center",class:{
            'justify-start': h.align === 'start',
            'justify-center': h.align === 'center',
            'justify-end': h.align === 'end',
          },attrs:{"cols":_vm.getCols(h.width)}},[_c('tooltip-label',{attrs:{"tooltip":h.title,"size":"small"}},[_c('span',[_vm._v(_vm._s(h.text))])])],1)}),1),_c('c-divider',{staticClass:"my-2"}),_vm._l((_vm.data),function(d,i){return _c('v-row',{key:i,staticClass:"table-row py-2",attrs:{"dense":""}},_vm._l((_vm.headers),function(h,j){return _c('v-col',{key:j,staticClass:"d-flex align-center justify-start py-0",class:{
            'justify-start': h.align === 'start',
            'justify-center': h.align === 'center',
            'justify-end': h.align === 'end',
            clickable: h.clickable,
            underscore: h.underscore,
            'text-start': h.align === 'start',
            'text-center': h.align === 'center',
            'text-end': h.align === 'end',
          },attrs:{"cols":_vm.getCols(h.width)},on:{"click":function($event){return _vm.clickRow(d, h)}}},[(d[h.value] && d[h.value].type == 'balanceAndValue')?_c('div',[_c('div',{staticClass:"text-color"},[_vm._v(_vm._s(d[h.value].balance))]),(d[h.value].balance != 0)?_c('div',{staticClass:"font-size-sm text-color-light"},[_vm._v(_vm._s(d[h.value].value))]):_vm._e()]):_c('div',{staticClass:"text-color"},[_vm._t(h.value,function(){return [_vm._v(_vm._s(d[h.value]))]},{"row":d})],2)])}),1)})],2):_c('div',_vm._l((_vm.data),function(d,i){return _c('div',{key:i},[_vm._l((_vm.headers),function(h){return _c('v-row',{key:h.value,staticClass:"table-row",attrs:{"dense":""},on:{"click":function($event){return _vm.clickRow(d, h)}}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"align-self":"center"}},[_vm._v(_vm._s(h.text))]),_c('v-col',[(d[h.value] && d[h.value].type == 'balanceAndValue')?_c('div',[_c('div',{staticClass:"text-color"},[_vm._v(_vm._s(d[h.value].balance))]),(d[h.value].balance != 0)?_c('div',{staticClass:"font-size-sm text-color-light"},[_vm._v(_vm._s(d[h.value].value))]):_vm._e()]):_c('div',{staticClass:"text-color"},[_vm._t(h.value,function(){return [_vm._v(_vm._s(d[h.value]))]},{"row":d})],2)])],1)}),(i < _vm.data.length - 1)?_c('c-divider',{staticClass:"my-2",attrs:{"height":"1"}}):_vm._e()],2)}),0)]):_c('section',{staticClass:"text-center my-4"},[(_vm.loading)?_c('div',{style:({ color: '#aaa69d' })},[_c('v-progress-circular',{attrs:{"size":15,"width":3,"indeterminate":""}}),_vm._v(" Loading... ")],1):_c('div',[_vm._v(_vm._s(_vm.emptyText))])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }