<template>
  <v-app-bar app flat dense color="#fffffe">
    <v-container fluid>
      <v-slide-group>
        <v-avatar class="mr-md-10 mr-2" size="32">
          <img :src="logo" alt="" />
        </v-avatar>
        <v-slide-item class="d-flex align-center">
          <v-btn :class="['text-color']" small elevation="0" rounded :text="currentLinkName !== 'Dashboard'" @click="jump({name: 'Dashboard', path: '/'})">
            Dashboard
          </v-btn>
        </v-slide-item>
        <v-slide-item class="d-flex align-center">
          <v-btn :class="['text-color', { 'highlight-btn': currTutorial === 'bridge' }]" small elevation="0" rounded :text="currentLinkName !== 'Bridge'" @click="jump({name: 'Bridge', path: '/bridge'})">
            Bridge
          </v-btn>
        </v-slide-item>
        <v-slide-item class="d-flex align-center">
          <v-btn :class="['text-color']" small elevation="0" rounded :text="currentLinkName !== 'Defi'" @click="jump({name: 'Defi', path: '/defi'})">
            Defi
          </v-btn>
        </v-slide-item>
        <v-slide-item class="d-flex align-center">
          <v-btn :class="['text-color', 'font-italic']" small elevation="0" rounded text @click="goPoints">
            Points
            <v-icon size="large" color="blue">mdi-rocket-launch</v-icon>
          </v-btn>
        </v-slide-item>
        <v-slide-item class="d-flex align-center">
          <v-btn :class="['text-color']" small elevation="0" rounded :text="currentLinkName !== 'Analytics'" @click="jump({name: 'Analytics', path: '/analytics'})">
            Analytics
          </v-btn>
        </v-slide-item>
        <v-spacer></v-spacer>
        <!-- <div>
          <v-btn v-if="network.isTestnet" rounded class="ml-1 text-color" small elevation="0" @click="showFaucetModal">Faucet</v-btn>
        </div> -->

        <div v-if="shortAccount">
          <v-menu offset-y open-on-click nudge-top="-9" bottom rounded="xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small elevation="0" class="ml-2 px-2 pr-1 text-color" rounded>
                <span class="wallet-img d-flex align-center" v-html="computedIcon"></span>
                <span class="pl-1 text-color">{{ shortAccount }}</span>
                <v-icon size="large">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-sheet class="pa-3" width="300">
              <div>
                <div class="text-center">
                  <v-avatar>
                    <span class="wallet-img-large" v-html="computedIcon"></span>
                  </v-avatar>
                </div>
                <div class="d-flex justify-space-between">
                  <div>
                    <span class="text-color font-weight-bold">{{ shortAccount }}</span>
                    <v-btn @click.stop="copyAccount" icon x-small><v-icon x-small>{{ copyIcon }}</v-icon></v-btn>
                  </div>
                  <span>
                    <span>{{ gasBalance }}</span>
                    <span> {{ currentNetwork?.symbol }}</span>
                  </span>
                </div>
              </div>
              <v-btn block color="primary" class="mt-2" @click="selectWallet(true)">Switch wallet</v-btn>
              <v-btn block color="error" class="mt-2" @click="disconnectWallet"><v-icon>mdi-logout</v-icon> Disconnect</v-btn>
            </v-sheet>
          </v-menu>
        </div>

        <v-btn small elevation="0" class="ml-2 text-color px-2 pr-1" @click="selectNetwork" rounded>
          <div v-if="!isTxPending">
            <span class="d-flex align-center">
              <span v-if="network.url">
                <v-img :src="network.url" :max-width="20" :min-width="20" aspect-ratio="1" contain />
              </span>
              <span class="ml-1 d-flex align-center">{{ network.name }}</span>
              <v-icon size="large">mdi-chevron-down</v-icon>
            </span>
          </div>
          <div v-else class="d-flex">
            <v-progress-circular size="18" width="3" color="#adbec4" indeterminate></v-progress-circular>
            <span class="ml-1 d-flex align-center">pending</span>
          </div>
        </v-btn>
      </v-slide-group>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { WalletBoardBus } from '@/WalletBoard'
import * as clipboard from 'clipboard-polyfill'
import { calculateComp, claimSumer } from '@/api'
import { BigNumber } from 'bignumber.js'
import { ethers } from 'ethers'

const { VUE_APP_PRODUCTION } = process.env

export default {
  name: 'Top',
  data() {
    return {
      logo: require('@/assets/images/logo.png'),
      currentLinkName: 'Dashboard',
      copyIcon: 'mdi-content-copy',
      claimAmount: '0.00',
      timer: null,
      claimLoading: false
    }
  },
  mounted() {
    this.selectWallet()
  },
  computed: {
    ...mapState('wallet', ['walletIcon', 'account', 'accounts', 'chainId', 'isWalletReset', 'currentNetwork', 'signer']),
    ...mapState('tx', ['txInfos']),
    ...mapState('tutorial', ['currTutorial']),
    isTxPending() {
      return this.txInfos.find((t) => t.status === 'pending')
    },
    computedIcon() {
      if (this.walletIcon) {
        if (JSON.stringify(this.walletIcon).includes('base64')) {
          return `<img src="${this.walletIcon}" />`
        }
        return this.walletIcon
      }
      return '<img src="/svg/wallet.svg" />'
    },
    shortAccount() {
      if (this.account === ethers.ZeroAddress) return ''
      if (this.account !== '') {
        return this.account.substr(0, 4) + '...' + this.account.substr(-4)
      } else {
        return ''
      }
    },
    network() {
      if (this.currentNetwork) {
        return {
          ...this.currentNetwork,
          url: require('@/assets/images/network/' + this.currentNetwork.networkId + '.png'),
        }
      }
      return {
        name: 'Unsupport network'
      }
    },
    gasBalance() {
      if (this.accounts && this.accounts.length && this.accounts[0].balance) {
        return Object.values(this.accounts[0].balance)[0]
      }
      return '0.00'
    }
  },
  created() {
    this.setCurrentLinkName(this.$route.name)

    // this.timer = setInterval(async () => {
    //   if (this.account && this.signer) {
    //     const res = await calculateComp(this.account, this.signer, this.chainId)
    //     this.claimAmount = new BigNumber(String(res)).div(1e18).toFixed(2)
    //   }
    // }, 5000);
  },
  watch: {
    '$route.name'(routeName) {
      this.setCurrentLinkName(routeName)
    },
    'account'(account) {
      if (account) {
        const { oauth_token, oauth_verifier } = this.$route.query
          if (oauth_token && oauth_verifier) {
            this.showFaucet()
          }
      }
    },
  },
  methods: {
    ...mapMutations({
      showFaucet: 'modal/SHOW_FAUCET',
    }),
    ...mapActions({
      initBridge: 'suTokenBridge/initChain',
      initMarket: 'market/initMarket',
      initShylock: 'shylock/initShylockAccount',
      initSummary: 'collect/initSummary',
    }),
    setCurrentLinkName(routeName) {
      console.log('routeName', routeName)
      if (['Account', 'Plan'].includes(routeName)) {
        this.currentLinkName = 'Liquidation'
      } else if (['TokenList', 'TokenDetail'].includes(routeName)) {
        this.currentLinkName = 'Market'
      } else if (['Summary', 'CollectDetail'].includes(routeName)) {
        this.currentLinkName = 'Analytics'
      } else {
        this.currentLinkName = routeName
      }
    },
    jump(link) {
      if (link.name !== this.currentLinkName) {
        this.currentLinkName = link.name
        if (link.name === 'Liquidation') {
          this.$router.push({
            name: 'Account',
          })
        } else if (link.name === 'Market') {
          this.$router.push({
            name: 'TokenList',
          })
        } else if (link.name === 'Analytics') {
          this.$router.push({
            name: 'Summary'
          })
        } else {
          this.$router.push({
            name: link.name,
          })
        }
        console.log('link.path', link.path)
        if (!this.isWalletReset) {
          if (link.path === '/bridge') {
            this.initBridge()
          } else if (link.path === '/liquidation') {
            this.initShylock()
          } else if (link.path.includes('collect')) {
            this.initSummary()
          } else if (link.path === '/gasSwap') {

          } else if (link.path === '/defi') {
            
          } else {
            this.initMarket()
          }
        }
      }
    },
    showFaucetModal() {
      this.showFaucet()
    },
    async selectWallet(flag = false) {
      WalletBoardBus.$emit('connect', flag)
      // if (this.currentLinkName !== 'Analytics') {
      //   WalletBoardBus.$emit('connect')
      // }
    },
    async selectNetwork() {
      if (ethers.ZeroAddress === this.account) {
        await this.selectWallet()
      } else {
        this.$store.commit('modal/SHOW_CHECK_NETWORK')
      }
    },
    disconnectWallet() {
      WalletBoardBus.$emit('disconnect')
    },
    copyAccount() {
      this.copyIcon = 'mdi-check-all'
      clipboard.writeText(this.account)
      setTimeout(() => {
        this.copyIcon = 'mdi-content-copy'
      }, 2000)
    },
    async doClaimAmount() {
      if (new BigNumber(this.claimAmount).gt(0)) {
        this.claimLoading = true
        await claimSumer(this.account, this.signer, this.chainId)
        this.claimLoading = false
      }
    },
    goPoints() {
      window.open("https://points.sumer.money")
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.wallet-img {
  ::v-deep svg {
    width: 20px !important;
    height: 20px !important;
  }
  ::v-deep img {
    width: 20px !important;
    height: 20px !important;
  }
}
.wallet-img-large {
  ::v-deep svg {
    width: 40px !important;
    height: 40px !important;
  }
  ::v-deep img {
    width: 40px !important;
    height: 40px !important;
  }
}
::v-deep .v-slide-group__content {
  align-items: center;
}
.highlight-btn {
  background: #716040;
  color: white !important;
}
</style>
