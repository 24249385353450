const namespaced = true

const state = {
  systemLoading: true,
}

const getters = {}

const mutations = {
  setSystemLoading(state, systemLoading) {
    state.systemLoading = systemLoading
  },
}

const actions = {}

export const loading = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
