import { ccipBridgeABI, oftABI } from '../abi'
import * as Sumer from '@meterio/sumer-js'

export const getSendFee = async (sumer, { oftAddress, tokenAddress, toAddress, dstChainId, amount }) => {
  const options = {
    abi: oftABI,
    _sumerProvider: sumer._provider,
  }

  const params = [tokenAddress, dstChainId, toAddress, amount]

  const fee = await Sumer.eth.read(oftAddress, 'estimateSendFee', params, options)

  return fee
}

export const getCCIPSendFee = async (
  sumer,
  { ccipBridgeAddr, destinationChainSelector, receiver, tokenAddress, amount },
) => {
  const options = {
    abi: ccipBridgeABI,
    _sumerProvider: sumer._provider,
  }

  const params = [destinationChainSelector, receiver, tokenAddress, amount]

  const fee = await Sumer.eth.read(ccipBridgeAddr, 'getNativeFee', params, options)

  return fee
}

export const sendFrom = async (sumer, { value, oftAddress, tokenAddress, toAddress, dstChainId, amount }) => {
  const options = {
    abi: oftABI,
    _sumerProvider: sumer._provider,
    value,
  }

  const params = [tokenAddress, dstChainId, toAddress, amount]

  const tx = await Sumer.eth.trx(oftAddress, 'sendFrom', params, options)
  return tx
}

export const transferTokensByCCIP = async (
  sumer,
  { ccipBridgeAddr, destinationChainSelector, receiver, tokenAddress, amount, value },
) => {
  const options = {
    abi: ccipBridgeABI,
    _sumerProvider: sumer._provider,
    value,
  }

  const params = [destinationChainSelector, receiver, tokenAddress, amount]

  const tx = await Sumer.eth.trx(ccipBridgeAddr, 'sendToken', params, options)
  return tx
}
