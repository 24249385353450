<template>
  <div>
    <span class="tip-label text-color">{{ label }}</span>
    <v-text-field v-model="amount" solo flat label="0" hide-details>
      <template v-slot:append>
        <v-btn text color="grey darken-1" @click="maxBtn">MAX</v-btn>
        <span v-if="symbol" class="token-symbol text-color">{{ symbol }}</span>
      </template>
    </v-text-field>
    <!-- <span class="hint"
      >Available Balance <span class="green--text text--lighten-2">{{ maxVal }} {{ symbol }}</span></span
    > -->
  </div>
</template>

<script>
export default {
  name: 'AmountInput2',
  props: {
    label: {
      type: String,
      default: '',
    },
    maxVal: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    symbol: {
      type: String | undefined,
      required: true,
    },
  },
  computed: {
    amount: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    maxBtn() {
      this.$emit('input', this.maxVal)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    border: 1px solid #e4e4e4;
  }
  input {
    font-size: inherit;
    text-align: left;
  }
}

.tip-label {
  color: #4f4f4f;
  font-size: 1rem;
}
.hint {
  color: #a2a2a2;
  font-size: 0.8rem;
}
.token-symbol {
  display: flex;
  align-items: center;
  color: #4f4f4f;
  border-left: 1px solid #e4e4e4;
  padding-left: 5px;
}
.c-rounded {
  border-radius: 5px;
}
</style>
