import * as Sumer from '@meterio/sumer-js'
import BigNumber from 'bignumber.js'
import { UNLIMITED_APPROVE_AMOUNT } from '@/constants'

export const checkMembership = async (sumer, account, cToken) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  const params = [account, cToken]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.Comptroller,
  }
  const check = await Sumer.eth.read(comptroller, 'checkMembership', params, options)
  return check
}

export const mint = async (sumer, contract, amount, decimals) => {
  const decimalsAmount = new BigNumber(amount).times(`1e${decimals}`)
  const params = [Sumer._ethers.toBigInt(decimalsAmount.toFixed(0))]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.CErc20,
  }
  const res = await Sumer.eth.trx(contract, 'borrow', params, options)
  return res
}

export const enterMarkets = async (sumer, suToken) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  const params = [[suToken]]
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.Comptroller,
  }
  const res = await Sumer.eth.trx(comptroller, 'enterMarkets', params, options)
  return res
}

export const payback = async (sumer, account, suToken, csuToken, amount, decimals) => {
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.CErc20,
  }

  const decimalsAmount = new BigNumber(amount).times(`1e${decimals}`)
  const bigNumberAmount = Sumer._ethers.toBigInt(decimalsAmount.toFixed(0))

  const allowance = await Sumer.eth.read(suToken, 'allowance', [account, csuToken], options)

  const notEnough = allowance.lt(bigNumberAmount)

  if (notEnough) {
    const unLimitedAmount = Sumer._ethers.toBigInt(UNLIMITED_APPROVE_AMOUNT)
    const approve = await Sumer.eth.trx(suToken, 'approve', [csuToken, unLimitedAmount], options)
    await approve.wait(1)
  }

  const res = await Sumer.eth.trx(csuToken, 'repayBorrow', [bigNumberAmount], options)
  return res
}

export const getTokenBalance = async (sumer, account, tokenAddress) => {
  const options = {
    _sumerProvider: sumer._provider,
    abi: Sumer.abi.CErc20,
  }

  return await Sumer.eth.read(tokenAddress, 'balanceOf', [account], options)
}
