import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#716040',
        primaryLight: '#f7f1e3',
        secondary: '#8c7851',
        accent: '#b9ce27',
        error: '#ee5253',
      },
    },
  },
})
