import axios from 'axios'
import * as Sumer from '@meterio/sumer-js'
import BigNumber from 'bignumber.js'
import { EXP_SCALE } from '../constants'

export const getPlans = async (baseUrl, account) => {
  if (account) {
    return await axios.get(`${baseUrl}/plan/${account}`)
  }
  return await axios.get(`${baseUrl}/plan/all`)
}

export const getPositions = async (baseUrl, account) => {
  if (account) {
    return await axios.get(`${baseUrl}/position/ownedby/${account}`)
  }
  return await axios.get(`${baseUrl}/position/all`)
}

export const liquidateAccountLimit = 10

export const getPaginateAccountInsolvent = async (baseUrl, page, limit) => {
  return await axios.get(`${baseUrl}/position/paginate/insolvent?page=${page}&limit=${limit || liquidateAccountLimit}`)
}

export const getPaginateAccount = async (baseUrl, page, limit) => {
  return await axios.get(`${baseUrl}/position/paginate?page=${page}&limit=${limit || liquidateAccountLimit}`)
}

export const getCloseFactorMantissa = async (sumer) => {
  const comptroller = Sumer.util.getAddress('Comptroller', sumer._network.name)

  const options = {
    abi: Sumer.abi.Comptroller,
    _sumerProvider: sumer._provider,
  }

  const closeFactorMantissa = await Sumer.eth.read(comptroller, 'closeFactorMantissa', [], options)

  return closeFactorMantissa
}

export const mulScalarTruncate = (a, scalar) => {
  return new BigNumber(a).times(scalar).div(EXP_SCALE)
}

export const liquidateBorrow = async (sumer, borrower, sdrTokenAddress, amount, cTokenCollateral) => {
  const tx = await sumer.liquidateBorrow(sdrTokenAddress, borrower, amount, cTokenCollateral)
  return tx
}
