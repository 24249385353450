<template>
  <div :style="computedStyle"></div>
</template>

<script>
export default {
  name: 'CustomizedDivider',
  props: {
    height: {
      type: Number | String,
      default: 1,
    },
    color: {
      type: String,
      default: '#eaddcf',
    },
  },
  computed: {
    computedStyle() {
      return {
        borderTop: `${this.height}px solid ${this.color}`,
      }
    },
  },
}
</script>
