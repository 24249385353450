<template>
  <div class="balance-fm">
    <span class="font-weight-bold"><span v-if="dollar">$ </span>{{ computedInteger }}</span>
    <span class="decimal">{{ computedDecimal }}</span>
  </div>
</template>
<script>
import { balanceFm } from '@/utils'

export default {
  name: 'BalanceFm',
  props: {
    dollar: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: Number | String,
      default: 0,
    },
  },
  computed: {
    computedInteger() {
      return Number.isNaN(Number(this.balance)) ? '-' : balanceFm(this.balance).integer
    },
    computedDecimal() {
      return Number.isNaN(Number(this.balance)) ? '.--' : balanceFm(this.balance).decimals
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
.balance-fm {
  line-height: 40px;
  vertical-align: middle;
  white-space: nowrap;
}
.decimal {
  color: $darkgrey;
}
</style>
