<template>
  <!-- Rates Section -->
  <div>
    <div class="font-weight-bold mb-2">Deposit Rates</div>
    <section class="text-color">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <i class="gray-circle"></i>
          <div class="pl-3">Deposit APY</div>
        </div>
        <div class="font-weight-bold">{{ depositAPY | percent }}</div>
      </div>
      <v-divider class="my-2" />
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <i class="gray-circle"></i>
          <div class="pl-3">Reward APY</div>
        </div>
        <div class="font-weight-bold">{{ rewardAPY | percent }}</div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'DepositRateSection',
  props: {
    depositAPY: {
      type: String,
      default: '0',
    },
    rewardAPY: {
      type: String,
      default: '0',
    },
  },
}
</script>
