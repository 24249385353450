<template>
  <div class="pt-6">
    <div class="d-flex justify-space-between font-weight-bold">
      <div>Minted:</div>
      <div>{{ minted }} {{ value.underlySymbol }}</div>
    </div>

    <!-- Amount Section -->
    <amount-input
      v-model="amount"
      label="Repay Amount"
      :maxVal="computedMaxAmount"
      :hint="amountHint"
      hideDetails
      :focus="true"
      @maxClicked="maxClicked"
    ></amount-input>

    <div class="d-flex justify-space-between my-3">
      <div>Wallet balance</div>
      <div>{{ walletBalance | number }} {{ value.underlySymbol }}</div>
    </div>

    <connect-wallet></connect-wallet>

    <v-btn class="mt-8" color="accent" @click="payback" depressed large block :disabled="paybackBtnDisabled" elevation="0">
      Repay {{ value.underlySymbol }}
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BigNumber } from 'bignumber.js'
import { formatNumReadable } from '@/utils'
import AmountInput from '@/components/AmountInput'
import { UNLIMITED_APPROVE_AMOUNT } from '../../../constants'

export default {
  name: 'PaybackTab',
  components: {
    AmountInput,
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      amount: '',
      clickedMax: false,
    }
  },
  watch: {
    amount(newVal) {
      if (BigNumber(newVal) < BigNumber(this.computedMaxAmount)) {
        this.clickedMax = false
      }
    },
  },
  computed: {
    ...mapState('tx', ['txHashs']),
    paybackBtnDisabled() {
      return (
        Number.isNaN(Number(this.amount)) ||
        Number(this.amount) > Number(this.computedMaxAmount) ||
        Number(this.amount) <= 0
      )
    },
    amountHint() {
      return `Wallet balance: ${this.walletBalance} ${this.value.underlySymbol}`
    },
    minted() {
      return this.value.borrowed.toFixed()
    },
    computedMaxAmount() {
      if (new BigNumber(this.minted).isGreaterThan(this.walletBalance)) {
        return this.walletBalance
      }
      return this.minted
    },
    walletBalance() {
      // return formatNumReadable(this.value.underlyBalance)
      return this.value.underlyBalance.toFixed()
    },
  },
  methods: {
    ...mapActions({
      paybackAction: 'tx/paybackAction',
    }),
    maxClicked() {
      const w = new BigNumber(this.value.underlyBalance)
      const b = new BigNumber(this.value.borrowed)
      if (w.isGreaterThanOrEqualTo(b)) {
        this.clickedMax = true
      }
    },
    async payback() {
      try {
        let _amount = new BigNumber(this.amount).times(`1e${this.value.underlyDecimals}`)
        if (this.clickedMax) {
          _amount = UNLIMITED_APPROVE_AMOUNT
        }
        await this.paybackAction({
          underlySymbol: this.value.underlySymbol,
          amount: _amount,
          noDecimalsAmount: new BigNumber(this.amount).toFixed(),
        })
      } catch (e) {
        console.log('pay back error: ', e)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_variable.scss';
</style>
