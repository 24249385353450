<template>
  <div class="system-loading">
    <v-progress-linear indeterminate :style="systemLoadingStyle"></v-progress-linear>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "SystemLoading",

  computed: {
    ...mapState('loading', ['systemLoading']),

    systemLoadingStyle() {
      const display = this.systemLoading ? "block" : "none"
      return {
        display
      }
    }
  }
}
</script>
<style scoped>
.system-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 999999;
}
</style>
