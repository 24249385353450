<template>
  <div class="tooltip-label d-flex text-right align-center">
    <slot>
      <span v-if="dollar" class="d-flex align-center font-weight-bold">{{ title | usd }}</span>
      <span v-else>{{ title }}</span>
    </slot>
    <span v-if="tooltip" class="d-flex align-center">
      <v-tooltip right max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon dense class="" :size="size" v-bind="attrs" v-on="on"> {{ icon }} </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TooltipLabel',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
    icon: {
      type: String,
      default: 'mdi-alert-circle-outline'
    },
    dollar: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../style/_variable';
// .tooltip-label {
//   line-height: 40px;
//   vertical-align: middle;
// }
// .theme--dark.v-icon {
//   color: #9f9f9f;
// }
</style>
