import { unlimitedApprove, mint, getAllowance, sendFrom, transferTokensByCCIP, checkIsAddedToTimelock } from '../../api'
import BigNumber from 'bignumber.js'
import { v4 as uuidv4 } from 'uuid'
import * as Sumer from '@meterio/sumer-js'
import { liquidateBorrow } from '../../api/shylock'
import router from '../../router'
import { ZERO_ADDRESS, UnknowError } from '../../constants'

// const TimelockInterface = new Interface(Sumer.abi.Timelock)
// const AgreementCreatedTopicHash = TimelockInterface.getEvent('AgreementCreated').topicHash
// const AgreementCreatedTopicHash = '0xd2d686f6b85f6de51ab1c5671f77dc4ca551cd74da2e8a99fdf7b4b9f0f5c0fb'

const namespaced = true

const state = {
  /**
   * {
   *  id: string,
   *  title: string,
   *  hash: string,
   *  symbol: string,
   *  status: string, // ok,fail,pending, confirm
   *  show: boolean,
   *  msg: string,
   *  amount: string,
   * }
   */
  txInfos: [],
}

const getters = {}

const mutations = {
  setTxInfos(state, { id, title, hash, symbol, status, show, msg, amount, timelocked }) {
    const txInfoIndex = state.txInfos.findIndex((t) => t.id === id)
    if (txInfoIndex !== -1) {
      const aimData = { ...state.txInfos[txInfoIndex] }
      let _title = title
      let _hash = hash
      let _symbol = symbol
      let _status = status
      let _show = show
      let _amount = amount
      let _timelocked = timelocked
      if (!_title) {
        _title = aimData.title
      }
      if (!_hash) {
        _hash = aimData.hash
      }
      if (!_symbol) {
        _symbol = aimData.symbol
      }
      if (!_status) {
        _status = aimData.status
      }
      if (_show === undefined) {
        _show = aimData.show
      }
      if (_amount === undefined) {
        _amount = aimData.amount
      }
      if (_timelocked === undefined) {
        _timelocked = aimData.timelocked
      }
      state.txInfos.splice(txInfoIndex, 1, {
        id,
        title: _title,
        hash: _hash,
        symbol: _symbol,
        status: _status,
        show: _show,
        msg,
        amount: _amount,
        timelocked: _timelocked
      })
    } else {
      state.txInfos.unshift({ id, title, hash, symbol, status, msg, show: true, amount, timelocked })
    }

    localStorage.removeItem('sumer-ui-txs')
    localStorage.setItem('sumer-ui-txs', JSON.stringify(state.txInfos))
  },
  clearStoredTx(state) {
    localStorage.removeItem('sumer-ui-txs')
    state.txInfos = []
  },
  clearTxInfos(state) {
    state.txInfos = state.txInfos.map((t) => ({
      ...t,
      show: false,
    }))
  },
}

const actions = {
  async approve({ rootState, commit, dispatch }, { symbol, token, spender, flag }) {
    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Enable',
      symbol: symbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
    })
    try {
      const tx = await unlimitedApprove(rootState.wallet.sumer, token, spender)
      console.log(`sent tx:`, tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log(`approve receipt:`, receipt)
      try {
        await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      if (flag) {
        await dispatch('market/initMarket', null, { root: true })
      }

      return true
    } catch (e) {
      console.log('approve error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async supply({ rootState, commit, dispatch }, { underlySymbol, amount }) {
    console.log('supply underlySymbol', underlySymbol)
    const id = uuidv4()
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }

      const { sumer, account } = rootState.wallet

      const pair = sumer.getTokenPairBySymbol(underlySymbol)

      let needApprove = false

      const underlyAddress = Sumer.util.getAddress(underlySymbol, sumer._network.name)
      if (ZERO_ADDRESS !== underlyAddress) {
        const decimals = Sumer.util.getUnderlyDecimals(underlySymbol, sumer._network.name)
        const sdrTokenAddress = Sumer.util.getAddress(`sdr${underlySymbol}`, sumer._network.name)
        const allowance = await getAllowance(sumer, underlyAddress, account, sdrTokenAddress)
        if (new BigNumber(String(allowance)).div(`1e${decimals}`).lt(amount)) {
          needApprove = true
          if (!(pair && pair.permitEnabled)) {
            const res = await dispatch('approve', {
              symbol: underlySymbol,
              token: underlyAddress,
              spender: sdrTokenAddress,
            })
            if (!res) {
              return
            }
          }
        }
      }

      commit('setTxInfos', {
        id,
        title: 'Deposit',
        symbol: underlySymbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount: new BigNumber(amount).toFixed(),
      })

      // const trxOptions = { mantissa: false }

      let tx
      if (pair && pair.permitEnabled && needApprove) {
        tx = await sumer.supplyWithPermit(underlySymbol, amount)
      } else {
        tx = await sumer.supply(underlySymbol, amount, true) //, trxOptions)
      }
      commit('modal/HIDE_DEPOSIT', null, { root: true })
      console.log('sent tx: ', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('supply receipt: ', receipt)
      
      try {
        await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        await tx.wait()
      }

      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('supply error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async redeem({ rootState, commit, dispatch }, { underlySymbol, underlyOrCTokenSymbol, amount, noDecimalsAmount }) {
    const { sumer } = rootState.wallet

    const timelocked = await checkIsAddedToTimelock(sumer, underlySymbol, noDecimalsAmount)

    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Withdraw',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
      amount: noDecimalsAmount,
      timelocked
    })
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }
      const tx = await sumer.redeem(underlyOrCTokenSymbol, amount, {
        mantissa: true,
      })
      commit('modal/HIDE_DEPOSIT', null, { root: true })
      console.log('sent tx: ', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })

      let receipt = false;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      let msg = 'Transaction success.'

      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('redeem error: ', e)
      let msg = getErrorMsg(e)
      if (msg === 'evm: execution reverted: insufficient liquidity') {
        msg = 'You cannot withdraw this asset. This asset collateralizes an open borrow position'
      }
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async borrowToken({ rootState, commit, dispatch }, { underlySymbol, amount }) {
    const { sumer } = rootState.wallet

    const timelocked = await checkIsAddedToTimelock(sumer, underlySymbol, amount)

    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Borrow',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
      amount: new BigNumber(amount).toFixed(),
      timelocked
    })
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }

      const trxOptions = { mantissa: false }
      const tx = await sumer.borrow(underlySymbol, amount, trxOptions)
      commit('modal/HIDE_BORROW', null, { root: true })
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })

      let receipt = false;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', {
        id,
        hash: tx.hash,
        status: 'ok',
        msg: 'Transaction success.',
      })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('borrow error: ', { ...e })
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async repayBorrow({ rootState, commit, dispatch }, { underlySymbol, amount, noDecimalsAmount, isCEther, isMax }) {
    const id = uuidv4()
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }

      const { sumer, account } = rootState.wallet
      const pair = sumer.getTokenPairBySymbol(underlySymbol)

      const underlyAddress = Sumer.util.getAddress(underlySymbol, sumer._network.name)

      let needApprove = false

      if (underlyAddress !== ZERO_ADDRESS) {
        const sdrTokenAddress = Sumer.util.getAddress(`sdr${underlySymbol}`, sumer._network.name)
        const allowance = await getAllowance(sumer, underlyAddress, account, sdrTokenAddress)
        if (new BigNumber(String(allowance)).lt(amount)) {
          needApprove = true
          if (!(pair && pair.permitEnabled)) {
            const res = await dispatch('approve', {
              symbol: underlySymbol,
              token: underlyAddress,
              spender: sdrTokenAddress,
            })
            if (!res) {
              return
            }
          }
        }
      }

      commit('setTxInfos', {
        id,
        title: 'RepayBorrow',
        symbol: underlySymbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount: noDecimalsAmount,
      })

      let tx
      if (pair && pair.permitEnabled && needApprove) {
        tx = await sumer.repayBorrowWithPermit(underlySymbol, amount, {
          mantissa: true,
        })
      } else {
        tx = await sumer.repayBorrow(underlySymbol, amount, '', true, {
          mantissa: true,
        })
      }
      commit('modal/HIDE_BORROW', null, { root: true })
      console.log(`sent tx:`, tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('repay receipt: ', receipt)
      let receipt;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('repayBorrow error:', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async enterMarkets({ rootState, commit, dispatch }, { underlySymbol, sdrSymbol }) {
    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Enter Markets',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
    })
    try {
      const tx = await rootState.wallet.sumer.enterMarkets(sdrSymbol)
      commit('modal/HIDE_COLLATERAL', null, { root: true })
      console.log(`sent tx:`, tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('enter markets receipt: ', receipt)
      let receipt;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('enter markets error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async exitMarkets({ rootState, commit, dispatch }, { sdrSymbol, underlySymbol }) {
    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Exit Markets',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
    })
    try {
      const tx = await rootState.wallet.sumer.exitMarket(sdrSymbol)
      commit('modal/HIDE_COLLATERAL', null, { root: true })
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('exit markets receipt: ', receipt)

      let receipt;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('exit markets error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async mintAction({ rootState, commit, dispatch }, { underlySymbol, sdrTokenAddress, amount, decimals }) {
    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Mint',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
      amount: new BigNumber(amount).toFixed(),
    })
    try {
      const tx = await mint(rootState.wallet.sumer, sdrTokenAddress, amount, decimals)
      commit('modal/HIDE_MINT_SYN', null, { root: true })
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      let receipt = false;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', {
        id,
        hash: tx.hash,
        status: 'ok',
        msg: 'Transaction success.',
      })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('mint error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },

  async redeemFaceValueAction(
    { rootState, commit, dispatch },
    { underlySymbol, amount, decimals, providers, deadline, signature },
  ) {
    const { sumer, account } = rootState.wallet

    const timelocked = await checkIsAddedToTimelock(sumer, underlySymbol, amount)

    const id = uuidv4()


    try {

      const pair = sumer.getTokenPairBySymbol(underlySymbol)

      let needApprove = false

      const tokenAddress = Sumer.getAddress(sumer._network.name, underlySymbol)
      const sdrTokenAddress = Sumer.getAddress(sumer._network.name, `sdr${underlySymbol}`)
      const allowance = await getAllowance(sumer, tokenAddress, account, sdrTokenAddress)
      if (new BigNumber(String(allowance)).div(`1e${decimals}`).lt(amount)) {
        needApprove = true
        if (!(pair && pair.permitEnabled)) {
          const res = await dispatch('approve', {
            symbol: underlySymbol,
            token: tokenAddress,
            spender: sdrTokenAddress,
          })
          if (!res) {
            return
          }
        }
        // const res = await dispatch('approve', { symbol: underlySymbol, token: tokenAddress, spender: sdrTokenAddress })
        // if (!res) {
        //   return
        // }
      }

      console.log('entered redeem face value')
      console.log(`underly: ${underlySymbol}, amount: ${amount}`)
      commit('setTxInfos', {
        id,
        title: 'RedeemFaceValue',
        symbol: underlySymbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount: new BigNumber(amount).toFixed(),
        timelocked
      })

      let tx
      if (pair && pair.permitEnabled && needApprove) {
        tx = await sumer.redeemFaceValueWithPermit(
          underlySymbol,
          BigNumber(amount).times(`1e${decimals}`).toFixed(0),
          providers,
          deadline,
          signature,
        )
      } else {
        // tx = await sumer.redeemFaceValue(underlySymbol, BigNumber(amount).times(`1e${decimals}`).toFixed(0))
        tx = await sumer.redeemFaceValue(
          underlySymbol,
          BigNumber(amount).times(`1e${decimals}`).toFixed(0),
          providers,
          deadline,
          signature,
        )
      }
      commit('modal/HIDE_REDEMPTION', null, { root: true })
      console.log('redeem face value', underlySymbol, amount, providers, deadline, signature)

      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('redeemFaceValue receipt: ', receipt)

      let receipt = false;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', {
        id,
        hash: tx.hash,
        status: 'ok',
        msg: 'Transaction success.',
      })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('redemption error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },

  async protectedMintSynAction(
    { rootState, commit, dispatch },
    { underlySymbol, collateralSymbol, borrowAmount, mintAmount },
  ) {
    const id = uuidv4()
    console.log('entered protected mint syn')
    console.log(
      `underlySymbol:${underlySymbol}, collateral: ${collateralSymbol}, borrowAmount: ${borrowAmount}, mintAmount: ${mintAmount}`,
    )
    commit('setTxInfos', {
      id,
      title: 'ProtectedMint',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
      amount: mintAmount,
    })
    try {
      const tx = await rootState.wallet.sumer.protectedMint(underlySymbol, collateralSymbol, borrowAmount, mintAmount)
      commit('modal/HIDE_PROTECTED_MINT_SYN', null, { root: true })
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('protectedMint receipt: ', receipt)

      let receipt, agreementCreatedEvent = false;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', {
        id,
        hash: tx.hash,
        status: 'ok',
        msg: 'Transaction success.',
      })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('protectedMint error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },

  async paybackAction({ rootState, commit, dispatch }, { underlySymbol, amount, noDecimalsAmount }) {
    const id = uuidv4()
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }

      const { sumer, account } = rootState.wallet

      let needApprove = false

      const pair = sumer.getTokenPairBySymbol(underlySymbol)
      const underlyAddress = Sumer.util.getAddress(underlySymbol, sumer._network.name)
      const sdrTokenAddress = Sumer.util.getAddress(`sdr${underlySymbol}`, sumer._network.name)
      const allowance = await getAllowance(sumer, underlyAddress, account, sdrTokenAddress)

      if (new BigNumber(String(allowance)).lt(amount)) {
        needApprove = true
        if (!(pair && pair.permitEnabled)) {
          const res = await dispatch('approve', {
            symbol: underlySymbol,
            token: underlyAddress,
            spender: sdrTokenAddress,
          })
          if (!res) return
        }
      }
      commit('setTxInfos', {
        id,
        title: 'Payback',
        symbol: underlySymbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount: noDecimalsAmount,
      })

      let tx
      if (pair && pair.permitEnabled && needApprove) {
        tx = await sumer.repayBorrowWithPermit(underlySymbol, amount, {
          mantissa: true,
        })
      } else {
        tx = await sumer.repayBorrow(underlySymbol, amount, '', true, {
          mantissa: true,
        })
      }
      commit('modal/HIDE_MINT_SYN', null, { root: true })
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('payback receipt: ', receipt)

      let receipt;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
      
      await dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('payback error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async liquidateBorrow(
    { rootState, commit, dispatch },
    { borrower, underlySymbol, sdrTokenAddress, amount, cTokenCollateral },
  ) {
    const { account, sumer } = rootState.wallet
    const decimals = Sumer.getDecimals(sumer._network.name, underlySymbol)
    const underlyAddress = Sumer.getAddress(sumer._network.name, underlySymbol)
    if (underlyAddress !== ZERO_ADDRESS) {
      const allowance = await getAllowance(sumer, underlyAddress, account, sdrTokenAddress)
      if (new BigNumber(String(allowance)).isLessThan(amount)) {
        const res = await dispatch('approve', {
          symbol: underlySymbol,
          token: underlyAddress,
          spender: sdrTokenAddress,
        })
        if (!res) return
      }
    }

    const id = uuidv4()
    commit('setTxInfos', {
      id,
      title: 'Liquidate',
      symbol: underlySymbol,
      status: 'confirm',
      msg: 'Confirm the transaction.',
      amount: new BigNumber(amount).div(`1e${decimals}`),
    })
    try {
      if (amount instanceof BigNumber) {
        amount = amount.toFixed(0)
      }

      console.log(
        `calling liquidate borrow borrower:${borrower}, sdrToken:${sdrTokenAddress}, amount:${amount}, cTokenCollateral:${cTokenCollateral}`,
      )
      const tx = await liquidateBorrow(sumer, borrower, sdrTokenAddress, amount, cTokenCollateral)
      console.log('sent tx:', tx)
      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      // const receipt = await tx.wait()
      // console.log('LiquidateBorrow receipt: ', receipt)
      let receipt;
      try {
        receipt = await tx.wait()
      } catch (e) {
        console.log('tx wait error', e)
        receipt = await tx.wait()
      }

      commit('setTxInfos', {
        id,
        hash: tx.hash,
        status: 'ok',
        msg: 'Transaction success.',
      })
      const planAccount = router.currentRoute.params.account
      await dispatch('shylock/getPlans', { account: planAccount }, { root: true })
    } catch (e) {
      console.log('LiquidateBorrow error: ', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async sendFrom({ rootState, commit, dispatch }, { symbol, tokenAddress, value, toAddress, dstChainId, amount }) {
    const { sumer, currentNetwork, account } = rootState.wallet
    const { oftBridge } = currentNetwork
    const id = uuidv4()

    try {
      const allowance = await getAllowance(sumer, tokenAddress, account, oftBridge)
      if (new BigNumber(String(allowance)).lt(amount)) {
        const res = await dispatch('approve', {
          symbol: symbol,
          token: tokenAddress,
          spender: oftBridge,
        })
        if (!res) {
          return
        }
      }

      commit('setTxInfos', {
        id,
        title: 'transfer',
        symbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount: new BigNumber(amount).div(1e18),
      })

      const tx = await sendFrom(sumer, {
        oftAddress: oftBridge,
        tokenAddress,
        value,
        toAddress,
        dstChainId,
        amount,
      })

      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      try {
        await tx.wait()
      } catch(e) {
        await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
    } catch (e) {
      console.log('bridge error', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async timelockClaim({ rootState, commit, dispatch }, { agreementIds, amount, symbol }) {
    const { sumer, signer } = rootState.wallet
    const timelockAddr = Sumer.getAddress(sumer._network.name, 'Timelock')

    const timelockContract = new Sumer._ethers.Contract(timelockAddr, Sumer.abi.Timelock, signer)

    const id = uuidv4()
    commit('setTxInfos', { id, title: 'Claim', symbol, status: 'confirm', msg: 'Confirm the transaction.', amount })

    try {
      console.log('agreementIds', agreementIds)
      const tx = await timelockContract.claim(agreementIds)

      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      try {
        await tx.wait()
      } catch(e) {
        await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })

      // dispatch('market/getUserAgreements', null, { root: true })
      dispatch('market/initMarket', null, { root: true })
    } catch (e) {
      console.log('timelockClaim error', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
  async bridgeByCCIP(
    { rootState, commit, dispatch },
    { value, symbol, decimals, ccipBridgeAddr, destinationChainSelector, receiver, tokenAddress, amount },
  ) {
    const id = uuidv4()
    try {
      const { sumer, account } = rootState.wallet

      const allowance = await getAllowance(sumer, tokenAddress, account, ccipBridgeAddr)
      if (new BigNumber(String(allowance)).div(`1e${decimals}`).lt(amount)) {
        const res = await dispatch('approve', {
          symbol: symbol,
          token: tokenAddress,
          spender: ccipBridgeAddr,
        })
        if (!res) {
          return
        }
      }

      commit('setTxInfos', {
        id,
        title: 'ccipTransfer',
        symbol,
        status: 'confirm',
        msg: 'Confirm the transaction.',
        amount,
      })

      const tx = await transferTokensByCCIP(sumer, {
        ccipBridgeAddr,
        destinationChainSelector,
        receiver,
        tokenAddress,
        amount: new BigNumber(amount).times(`1e${decimals}`).toFixed(0),
        value,
      })

      commit('setTxInfos', { id, status: 'pending', msg: 'Transaction pending.' })
      try {
        await tx.wait()
      } catch(e) {
        await tx.wait()
      }
      commit('setTxInfos', { id, hash: tx.hash, status: 'ok', msg: 'Transaction success.' })
    } catch (e) {
      console.log('bridge ccip error', e)
      const msg = getErrorMsg(e)
      commit('setTxInfos', { id, status: 'fail', msg })
    }
  },
}

export const tx = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)

const getErrorMsg = (e) => {
  console.log('err', { ...e })

  if (e.error) {
    let msg = e.error.reason || e.error.shortMessage || UnknowError

    if (e.error.data) {
      try {
        const intf = new Sumer._ethers.Interface(Sumer.errorABIs)
        const decodedError = intf.parseError(e.error.data)
        console.log('decodedError', decodedError)
        return camelToSnakeCase(decodedError.name)
      } catch(e) {
        return msg
      }
    }

    return msg
  } else if (e.data) {
    const intf = new Sumer._ethers.Interface(Sumer.errorABIs)
    const decodedError = intf.parseError(e.data)
    console.log('decodedError', decodedError)
    return camelToSnakeCase(decodedError.name)
  } else if (e.shortMessage) {
    return e.shortMessage
  } else {
    return UnknowError
  }
}
