<template>
  <v-switch
    v-model="computedValue"
    inset
    dense
    hide-details
    :readonly="readonly"
    @click.stop="clickThis"
    color="primary"
  ></v-switch>
</template>

<script>
export default {
  name: 'CustomizedSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    clickThis() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input--switch {
  &.v-input--selection-controls {
    margin: 0;
  }
  ::v-deep .v-input--selection-controls__input {
    margin: 0;
    width: 38px !important;
    height: 15px !important;
  }
}
</style>