<template>
  <div v-if="token && name" @click="emitClick">
    <div
      class="d-flex text-no-wrap"
      :class="{
        'click-status': clickable,
        'xs-height': $vuetify.breakpoint.name === 'xs',
        'sm-height': $vuetify.breakpoint.name !== 'xs',
      }"
    >
      <section class="d-flex">
        <span class="d-flex align-center">
          <img :src="url" :width="width" :height="width" class="mr-2" alt="" />
        </span>
        <span :style="{width: `${textWidth}px`}" class="d-inline-block text-truncate" :title="name">{{ computedName }}</span>
      </section>
      <section v-if="info" class="d-flex align-center">
        <span>
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <span v-bind="attrs" v-on="on">
                <tooltip-label icon="mdi-wallet-outline" tooltip="Add token to wallet"></tooltip-label>
              </span>
            </template>

            <v-list>
              <v-list-item link @click="addUnderly2Metamask">
                <v-list-item-title>{{ info.underlySymbol }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="addCToken2Metamask">
                <v-list-item-title>{{ info.ctokenSymbol }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <span>
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <span v-bind="attrs" v-on="on">
                <tooltip-label icon="mdi-open-in-new" tooltip="View token contracts"></tooltip-label>
              </span>
            </template>

            <v-list>
              <v-list-item link @click="viewScan(info.underlyAddr)">
                <v-list-item-title>{{ info.underlySymbol }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="viewScan(info.ctokenAddr)">
                <v-list-item-title>{{ info.ctokenSymbol }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import { _ethers } from '@meterio/sumer-js'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'CryptoIcon',
  props: {
    token: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    textWidth: {
      type: String,
      default: ''
    },
    forceClick: {
      type: Boolean,
      default: false,
    },
    info: {
      /**
       *  underlySymbol: String,
          underlyDecimals: Number,
          underlyAddr: String,
          ctokenSymbol: String,
          ctokenDecimals: Number,
          ctokenAddr: String,
       */
      type: Object,
    },
  },
  computed: {
    ...mapState('wallet', ['provider', 'currentNetwork']),
    url() {
      if (this.token) {
        let t = this.token === 'BTC' ? 'WBTC' : this.token

        t = this.token === 'WETH' ? 'ETH' : this.token

        if (t.includes('USDC') || t.includes('USDT')) {
          t = t.split('.')[0]
        }

        if (t === 'USDbc') t = 'USDC'

        if (t.startsWith('PT-')) {
          const splited = t.split('-')
          t = splited[0] + '-' + splited[1]
        }

        return `https://raw.githubusercontent.com/meterio/token-list/master/data/${t}/logo.png`
      }
      return '#'
    },
    width() {
      return this.$vuetify.breakpoint.name == 'xs' ? 28 : 30
    },
    computedName() {
      if (!this.textWidth) {
        if (this.name.length > 11) {
          return this.name.substring(0, 11) + '...'
        }
      }
      return this.name
    },
    clickable() {
      if (this.$listeners.click) {
        if (this.forceClick) {
          return true
        }
        return true //!this.token.includes('su')
      }
      return false
    },
  },
  methods: {
    addToMetamask(token) {
      try {
        this.provider.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: '',
            },
          },
        })
      } catch (e) {
        console.log('add token to metamask err', e)
      }
    },
    addCToken2Metamask() {
      console.log('market: ', this.info)
      this.addToMetamask({
        address: this.info.ctokenAddr,
        symbol: this.info.ctokenSymbol,
        decimals: _ethers.toNumber(this.info.ctokenDecimals),
      })
    },
    addUnderly2Metamask() {
      this.addToMetamask({
        address: this.info.underlyAddr,
        symbol: this.info.underlySymbol,
        decimals: _ethers.toNumber(this.info.underlyDecimals),
      })
    },
    emitClick() {
      if (this.clickable) {
        this.$emit('click')
      }
    },
    viewScan(address) {
      window.open(`${this.currentNetwork.blockExplorer}/address/${address}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-bg {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #92938d;
}
.click-status {
  cursor: pointer;
  &:hover {
    border-radius: 6px;
    text-decoration: underline;
  }
}
.xs-height {
  line-height: 30px;
}
.sm-height {
  line-height: 50px;
}
.v-tooltip__content {
  pointer-events: initial;
  a {
    color: white;
  }
}
</style>
