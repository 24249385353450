import Vue from 'vue'
import Vuex from 'vuex'

import { wallet } from '@/store/modules/wallet'
import { loading } from './modules/loading'
import { tx } from './modules/tx'
import { modal } from './modules/modal'
import { mint } from './modules/mint'
import { market } from './modules/market'
import { faucet } from './modules/faucet'
import { shylock } from './modules/shylock'
import { suTokenBridge } from './modules/suTokenbridge'
import { collect } from './modules/collect'
import { tutorial } from './modules/tutorial'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    market,
    wallet,
    loading,
    tx,
    modal,
    mint,
    faucet,
    shylock,
    suTokenBridge,
    collect,
    tutorial
  },
})
