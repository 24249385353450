import { interestModelABI } from '@/abi'
import { ethers } from 'ethers'

const modelAddr = {
  83: {
    '0xfdb2af6f99c5c103cfab5d3d982ef7227bd62ecc': ['cUSDT', 'cUSDC', 'cBUSD'],
    '0xf09c2ed5a45cc78ae1975a3503ffd888d791fe9b': ['cETH', 'cMTR', 'cMTRG'],
    '0x8fe57d06e674f37e435aac29c4143392ce4ad585': ['cWBTC'],
    '0x3df4f99ceaea8f3d4dda6e820d88471059409964': ['csuUSD', 'csuETH', 'csuBTC']
  }
}

export const getRateModelAddr = (addrList, sdrSymbol) => {
  for (const addr of Object.keys(addrList)) {
    if (addrList[addr].includes(sdrSymbol)) {
      return addr
    }
  }
}

export const getRatePerBlock = async (web3Provider, rateContract) => {
  try {
    const contract = new ethers.Contract(rateContract.address, interestModelABI, web3Provider)
    if (rateContract.name === 'JumpRateModelV2') {
      const baseRatePerBlock = await contract.baseRatePerBlock()
      const jumpMultiplierPerBlock = await contract.jumpMultiplierPerBlock()
      const multiplierPerBlock = await contract.multiplierPerBlock()
      return {
        ...rateContract,
        baseRatePerBlock,
        jumpMultiplierPerBlock,
        multiplierPerBlock,
      }
    } else if (rateContract.name === 'WhitePaperInterestRateModel') {
      const baseRatePerBlock = await contract.baseRatePerBlock()
      const multiplierPerBlock = await contract.multiplierPerBlock()
      return {
        ...rateContract,
        baseRatePerBlock,
        multiplierPerBlock,
      }
    } else {
      return {
        ...rateContract,
        baseRatePerBlock: '0',
        multiplierPerBlock: '0',
        jumpMultiplierPerBlock: '0'
      }
    }
  } catch(e) {
    console.log('getRatePerBlock error', e)
    return {
      ...rateContract,
      baseRatePerBlock: '0',
      multiplierPerBlock: '0',
      jumpMultiplierPerBlock: '0'
    }
  }
}
