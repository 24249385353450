<template>
  <div class="network-container">
    <span class="tip-label text-color d-block pb-1">{{ label }}</span>
    <v-menu offset-y :disabled="disabled">
      <template v-slot:activator="{ attrs, on }">
        <div
          v-bind="attrs"
          v-on="on">
          
          <section class="network pa-2 d-flex justify-space-between">
            <div class="network-img d-flex text-truncate">
              <v-img :src="url" :max-width="30" :min-width="30" aspect-ratio="1" contain />
              <div class="d-flex align-center ml-2 text-truncate" :title="chainName">{{ chainName }}</div>
            </div>
            <div v-if="!disabled" class="d-flex align-center"><v-icon>mdi-chevron-down</v-icon></div>
          </section>
        </div>
      </template>
      
      <SelectList class="text-color" :data="options" @selected="selected" />
    </v-menu>
  </div>
  
</template>

<script>
import SelectList from './List'
export default {
  name: 'CustomSelect',
  components: {
    SelectList
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          value: null,
        }
      },
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    url() {
      if (this.value) {
        try {
          let logo = require(`@/assets/images/crypto/${this.value.symbol.toLowerCase()}.png`)
          if (this.value.networkId) {
            logo = require(`@/assets/images/network/${this.value.networkId}.png`)
          }
          return logo
        } catch (e) {
          return require(`@/assets/images/logo.png`)
        }
      }
    },
    chainName() {
      if (this.value) {
        return this.value.name
      }
      return ''
    },
  },
  methods: {
    selected(data) {
      this.$emit('selected', data)
    }
  },
}
</script>

<style lang="scss" scoped>
.network-container {
  cursor: pointer;
  .tip-label {
    color: #7b7b7b;
    font-size: 1rem;
  }
  .network {
    border-radius: 5px;
    background-color: #f2f2f2;
  }
}
</style>