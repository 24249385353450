<template>
  <div>
    <ListItem
      v-for="(item, index) in data"
      :key="index"
      :borderBottom="index !== data.length - 1"
      :data="item"
      @click="click" />
  </div>
</template>

<script>
import ListItem from './Item.vue'

export default {
  name: "SelectList",
  components: {
    ListItem
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {},
  methods: {
    click(data) {
      this.$emit('selected', data)
    }
  }
}
</script>