import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    children: [
      {
        name: 'Dashboard',
        path: '/',
        component: () => import('../views/Dashboard'),
        meta: {}
      },
      {
        name: 'Market',
        path: '/market',
        component: () => import('../views/Market'),
        meta: {},
        children: [
          {
            name: 'TokenList',
            path: 'list',
            component: () => import('../views/Market/market'),
            meta: {}
          },
          {
            name: 'TokenDetail',
            path: 'detail/:address',
            props: true,
            component: () => import('../views/Market/detail'),
            mata: {}
          }
        ]
      },
      {
        name: 'Bridge',
        path: '/bridge',
        component: () => import('../views/Bridge'),
        meta: {}
      },
      {
        name: 'Liquidation',
        path: '/liquidation',
        component: () => import('../views/Shylock'),
        meta: {},
        children: [
          {
            name: 'Account',
            path: 'account',
            component: () => import('../views/Shylock/accountTable'),
            meta: {}
          },
          {
            name: 'Plan',
            path: 'plan/:account',
            props: true,
            component: () => import('../views/Shylock/shylockTable'),
            meta: {}
          },
          {
            name: 'AccountAll',
            path: 'account-all',
            component: () => import('../views/Shylock/accountAll'),
            meta: {}
          },
          {
            name: 'Plan1',
            path: 'plan1/:account',
            props: true,
            component: () => import('../views/Shylock/shylockTable'),
            meta: {}
          }
        ]
      },
      {
        name: 'Redemption',
        path: '/redemption',
        component: () => import('../views/Redemption'),
      },
      {
        name: 'Analytics',
        path: '/analytics',
        component: () => import('../views/Analytics'),
        children: [
          {
            name: 'Summary',
            path: '',
            component: () => import('../views/Analytics/summary')
          },
          {
            name: 'Detail',
            path: ':chainId',
            props: true,
            component: () => import('../views/Analytics/detail')
          }
        ]
      },
      {
        name: 'Defi',
        path: '/defi',
        component: () => import('../views/Defi'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
