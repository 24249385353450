<template>
  <!-- Borrow Limit Section -->
  <div class="well-box">
    <!-- Rates Section -->
    <div class="d-flex justify-space-between">
      <div class="rates-name-color">Borrow APY</div>
      <div class="font-weight-bold">{{ borrowAPY | percent }}</div>
    </div>
    <v-divider class="my-2" />
    <div class="d-flex justify-space-between">
      <div class="rates-name-color">Reward APY</div>
      <div class="font-weight-bold">{{ rewardAPY | percent }}</div>
    </div>
    <v-divider class="my-2" />
    <div class="d-flex justify-space-between">
      <div class="rates-name-color">Borrow Balance</div>
      <div class="d-flex">
        <div>
          <!-- <span class="rates-name-color">{{ borrowedPercent }}%</span> / <span class="font-weight-bold">$ {{ borrowedBalance }}</span> -->
          <span>{{ borrowUsed | usd }}</span>
          <span v-show="showTo"
            ><span> -> </span><span class="font-weight-bold">{{ newUsed | usd }}</span></span
          >
        </div>
      </div>
    </div>
    <v-divider class="my-2" />
    <div class="d-flex justify-space-between">
      <div class="rates-name-color">Usage</div>
      <div class="d-flex">
        <div>
          <!-- <span class="rates-name-color">{{ usedPercent }}%</span> / <span class="font-weight-bold">$ {{ used }}</span> -->
          <span class="rates-name-color">{{ usedPct | percent }}</span>
          <span v-show="showTo"
            ><span> -> </span><span class="font-weight-bold">{{ newUsedPct | percent }}</span></span
          >
        </div>
      </div>
    </div>
    <v-progress-linear rounded :color="progressColor" v-model="progressUsedPct"></v-progress-linear>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import { getHypoLiquidity } from '@/api'

export default {
  name: 'BorrowLimitSection',
  props: {
    borrowAPY: {
      type: String,
      default: '0',
    },
    rewardAPY: {
      type: String,
      default: '0',
    },
    usedDelta: {
      type: String,
      default: '0',
    },
    borrowedVal: {
      type: String,
      default: '0',
    },
    liquidity: {
      type: String,
      default: '0',
    },
    token: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      newLiquidity: 0,
    }
  },
  computed: {
    ...mapGetters('wallet', ['borrowUsed', 'borrowLimit']),
    ...mapState('wallet', ['account', 'contracts']),
    ...mapGetters('market', ['hypoLiquidityBySDRToken']),
    newUsed() {
      return BigNumber(this.borrowUsed).plus(this.usedDelta).toFixed()
    },
    usedPct() {
      const used = BigNumber(this.borrowUsed)
      const hypo = this.hypoLiquidityBySDRToken(this.token.ctokenAddr)
      const liquidity = hypo['liquidity'].minus(hypo['shortfall'])
      const limit = liquidity.plus(used)
      if (limit.isGreaterThan(0)) {
        return used.div(limit).toNumber()
      }
      return 0
    },
    newUsedPct() {
      const newUsed = BigNumber(this.newUsed)
      const newLiquidity = BigNumber(this.newLiquidity)
      const delta = BigNumber(this.usedDelta)
      let newLimit = newLiquidity.plus(newUsed)
      if (delta.lt(0)) {
        const hypo = this.hypoLiquidityBySDRToken(this.token.ctokenAddr)
        const liquidity = hypo['liquidity'].minus(hypo['shortfall'])
        newLimit = liquidity.plus(this.borrowUsed)
      }
      if (newLimit.isGreaterThan(0)) {
        return newUsed.div(newLimit).toNumber()
      }
      return 0
    },
    progressUsedPct() {
      if (this.showTo) {
        return this.newUsedPct * 100
      }
      return this.usedPct * 100
    },
    showTo() {
      return !Number.isNaN(Number(this.usedDelta)) && !BigNumber(this.usedDelta).isEqualTo(0)
    },
    progressColor() {
      const n = Number(this.newUsedPct * 100)
      if (n > 80.01) {
        return '#ee5253'
      }
      if (n > 60) {
        return '#feca57'
      }

      return '#b9ce27'
    },
  },
  watch: {
    async usedDelta(val) {
      console.log('usedDelta', val)
      console.log('curr token', this.token)
      const delta = new BigNumber(val)
      if (delta.isNaN() || delta.lt(0)) return
      const decimals = `1e${String(this.token.ctokenDecimals)}`
      const hypo = await getHypoLiquidity(
        this.contracts,
        this.account,
        this.token.ctokenAddr,
        0,
        delta.div(this.token.price).times(decimals).toFixed(0),
      )
      if (hypo.length === 3) {
        this.newLiquidity = BigNumber(String(hypo[1])).minus(String(hypo[2])).div(1e18)
      } else {
        this.newLiquidity = BigNumber(String(hypo[0])).minus(String(hypo[1])).div(1e18)
      }
    },
  },
}
</script>

<style lang="scss">
// .grey-box {
// background-color: #f9f4ef;
// border-radius: 10px;
// padding: 16px 25px;
// }
.well-box {
  background-color: lighten(#cad3c8, 16%);
  padding: 15px;
  border-radius: 10px;
}
</style>
