import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// 字体
import './assets/font/fonts.css'

import BalanceFm from '@/components/BalanceFm'
import TooltipLabel from '@/components/TooltipLabel'
import CryptoIcon from '@/components/CryptoIcon'
import FeatherIcon from '@/components/FeatherIcon'
import CustomizedSwitch from '@/components/Switch'
import CustomizedDivider from '@/components/Divider'
import DataTable from '@/components/DataTable'
import CustomSelect from '@/components/Select'
import AmountInput from '@/components/AmountInput'
import AmountInput2 from '@/components/AmountInput2'
import ConnectWallet from '@/components/ConnectWallet'

import BigNumber from 'bignumber.js'
import { formatNumReadable } from '@/utils'
import { shortAccount } from './utils'
import { computed } from 'vue'

Vue.component(BalanceFm.name, BalanceFm)
Vue.component(TooltipLabel.name, TooltipLabel)
Vue.component(CryptoIcon.name, CryptoIcon)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('CSwitch', CustomizedSwitch)
Vue.component('CDivider', CustomizedDivider)
Vue.component(DataTable.name, DataTable)
Vue.component(CustomSelect.name, CustomSelect)
Vue.component(AmountInput.name, AmountInput)
Vue.component(AmountInput2.name, AmountInput2)
Vue.component(ConnectWallet.name, ConnectWallet)


Vue.config.productionTip = false
Vue.filter('percent', function (val) {
  const bnVal = new BigNumber(val)
  if (!bnVal.isNaN()) {
    return `${new BigNumber(val).times(100).toFixed(2)}%`
  }
  return val
})
Vue.filter('number', function (val) {
  return formatNumReadable(val)
})
Vue.filter('usd', function (val) {
  if (!val) {
    return '$0'
  }
  return `$${formatNumReadable(val, 2, 1)}`
})
Vue.filter('short', function(val) {
  return shortAccount(val)
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
