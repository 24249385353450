<template>
  <!-- Rates Section -->
  <div class="well-box">
    <!-- <div class="font-weight-bold mt-6 mb-2">Borrow Limits</div> -->
    <section class="text-color">
      <div class="d-flex justify-space-between">
        <div>Deposit APY</div>
        <div class="font-weight-bold">{{ depositAPY | percent }}</div>
      </div>
      <v-divider class="my-2" />
      <div class="d-flex justify-space-between">
        <div>Reward APY</div>
        <div class="font-weight-bold">{{ rewardAPY | percent }}</div>
      </div>
    </section>
    <v-divider class="my-2" />
    <section class="text-color">
      <div class="d-flex justify-space-between">
        <div>Available for borrow</div>
        <div class="d-flex">
          <div>
            <span>{{ (borrowFreePct / 100) | percent }}</span>
            /
            <b>{{ borrowFree | usd }}</b>
          </div>
        </div>
      </div>
      <v-divider class="my-2" />
      <div class="d-flex justify-space-between">
        <div>Borrowed</div>
        <div class="d-flex">
          <div>
            <span>{{ (borrowUsedPct / 100) | percent }}</span>
            /
            <b>{{ borrowUsed | usd }}</b>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BorrowLimitSection',
  props: {
    depositAPY: {
      type: String,
      default: '0',
    },
    rewardAPY: {
      type: String,
      default: '0',
    },
  },
  computed: {
    ...mapGetters('wallet', ['borrowUsed', 'borrowUsedPct', 'borrowFree', 'borrowFreePct']),
  },
}
</script>
