<template>
  <!-- Rates Section -->
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <i class="gray-circle"></i>
        <div class="d-flex pl-3 rates-name-color">Borrow APY</div>
      </div>
      <div class="d-flex font-weight-bold">{{ borrowAPY | percent }}</div>
    </div>
    <v-divider class="my-2" />
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <i class="gray-circle"></i>
        <div class="d-flex pl-3 rates-name-color">Reward APY</div>
      </div>
      <div class="d-flex font-weight-bold">{{ rewardAPY | percent }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BorrowRateSection',
  props: {
    borrowAPY: {
      type: String,
      default: '0',
    },
    rewardAPY: {
      type: String,
      default: '0',
    },
  },
}
</script>
