const namespaced = true

const state = {
  currTutorial: 'welcome'
}

const getters = {}

const mutations = {
  setCurrTutorial(state, currTutorial) {
    state.currTutorial = currTutorial
  }
}

const actions = {
  
}

export const tutorial = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}