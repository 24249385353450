import BigNumber from 'bignumber.js'
import * as humanFormat from 'human-format'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}
export function balanceFm(str, precision = 4) {
  if (isNaN(Number(str))) {
    return str
  }
  const balance = Number(Number(str).toFixed(precision))
  const formatted = numberWithCommas(balance)
  const items = formatted.split('.')

  return { integer: items[0], decimals: items[1] || '' }
}

export function formatNumReadable(num, precision = 2, round = 1) {
  if (num instanceof BigNumber) {
    num = Number(num.toFixed())
  }
  if (isNaN(Number(num))) {
    return num
  }

  const bal = Number(num).toString()
  const items = bal.split('.')
  if (items[0] == '0' && items[1] && items[1].length > 0) {
    for (let i = 0; i < items[1].length; i++) {
      const digit = items[1].charAt(i)
      if (digit != '0') {
        precision += i
        break
      }
    }
  }

  const balance = BigNumber(num).toFixed(precision, round)
  return numberWithCommas(balance)
  // let tempBalance = balance.toLocaleString()

  // if (tempBalance.indexOf('.') === -1) {
  //   return tempBalance
  // } else {
  //   const index = tempBalance.indexOf('.')
  //   let decimals = tempBalance.substring(index, index + precision + 1)
  //   for (let i = decimals.length - 1; i > 0; i--) {
  //     if (decimals[i] === '0' || decimals[i] === '.') {
  //       decimals = decimals.substring(0, i)
  //     } else {
  //       break
  //     }
  //   }
  //   return tempBalance.substring(0, index) + decimals
  // }
}

export function formatNumReadable1(num, precision = 2, round = 1) {
  if (num instanceof BigNumber) {
    num = Number(num.toFixed())
  }
  if (isNaN(Number(num))) {
    return num
  }

  const bal = Number(num).toString()
  const items = bal.split('.')
  if (items[0] == '0' && items[1] && items[1].length > 0) {
    for (let i = 0; i < items[1].length; i++) {
      const digit = items[1].charAt(i)
      if (digit != '0') {
        precision += i
        break
      }
    }
  }

  const balance = Number(BigNumber(num).toFixed(precision, round))
  return numberWithCommas(balance)
}

export function formatNum(num, precision = 2, round = 1) {
  if (num instanceof BigNumber) {
    num = Number(num.toFixed())
  }
  if (isNaN(Number(num))) {
    return num
  }

  const bal = Number(num).toString()
  const items = bal.split('.')
  if (items[0] == '0' && items[1] && items[1].length > 0) {
    for (let i = 0; i < items[1].length; i++) {
      const digit = items[1].charAt(i)
      if (digit != '0') {
        precision += i
        break
      }
    }
  }

  const balance = BigNumber(num).toFixed(precision, round)
  return balance
}

export const shortAccount = (account) => {
  if (account) {
    return account.substr(0, 4) + '...' + account.substr(-4)
  } else {
    return ''
  }
}

function forEach(arr, iterator) {
  var i, n
  for (i = 0, n = arr.length; i < n; ++i) {
    iterator(arr[i], i)
  }
}

export const getHumanFormatScale = () => {
  const prefixesList = 'y,z,a,f,p,n,µ,m,,k,M,B,T,P,E,Z,Y'.split(',')
  const base = 1000
  const initExp = -8
  var prefixes = {}
  if (initExp === undefined) {
    initExp = 0
  }
  forEach(prefixesList, function (prefix, i) {
    prefixes[prefix] = Math.pow(base, i + initExp)
  })

  return new humanFormat.Scale(prefixes)
}

export const HumanFormatScale = getHumanFormatScale()

export const humanFormatNumber = (n, dollar = true) => {
  const val = BigNumber(n)
  return val.lt(1000000)
    ? `${dollar ? '$' : ''}${formatNumReadable(val, dollar ? 2 : 3, 1)}`
    : `${dollar ? '$' : ''}${humanFormat(val.toNumber(), { scale: HumanFormatScale })}`
}

export const getBorrowCapReachedTip = (cap, curr) => {
  // row.borrowCap.gt(0) && row.borrowCap.times(0.95).lt(row.totalBorrow)
  if (BigNumber(cap).gt(0) && BigNumber(cap).times(0.95).lt(curr)) {
    return 'This market is reaching its borrow cap. You may not be able to borrow from this market.'
  }
  return ''
}

export const getDepositCapReachedTip = (cap, curr) => {
  // row.borrowCap.gt(0) && row.borrowCap.times(0.95).lt(row.totalBorrow)
  if (BigNumber(cap).gt(0) && BigNumber(cap).times(0.95).lt(curr)) {
    return 'This market is reaching its supply cap. You may not be able to deposit into this market.'
  }
  return ''
}
