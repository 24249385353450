// APY params
export const EXP_SCALE = 1e18
export const YEAR_MS = 60 * 60 * 24 * 365
// export const UTILIZATION_RATE = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
export const UTILIZATION_RATE = new Array(101).fill(0).map((item, i) => i * 0.01)

// unlimited approve amount
export const UNLIMITED_APPROVE_AMOUNT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const UnknowError = 'Unknow Error'

export const CCIP_EXPLORER = 'https://ccip.chain.link'
export const LAYERZERO_TESTNET_SCAN = 'https://testnet.layerzeroscan.com'
export const LAYERZERO_SCAN = 'https://layerzeroscan.com'

import * as Sumer from '@meterio/sumer-js'

export const faucetTokens = {
  4: [
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.WBTC, balance: '3' },
  ],
  322: [
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.BTCK, balance: '3' },
  ],
  321: [
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.BTCK, balance: '3' },
  ],
  83: [
    { symbol: Sumer.MTR, balance: '20' },
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.ETH, balance: '50' },
    { symbol: Sumer.BTC, balance: '3' },
    // { symbol: Sumer.BUSD, balance: '100' }
  ],
  84531: [
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.BTC, balance: '3' },
  ],
  11155111: [
    { symbol: Sumer.USDT, balance: '100' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.BTC, balance: '3' },
  ],
  280: [
    { symbol: Sumer.BTC, balance: '3' },
    { symbol: Sumer.USDC, balance: '100' },
    { symbol: Sumer.DAI, balance: '100' },
    { symbol: Sumer.LINK, balance: '100' },
  ],
}

const { VUE_APP_PRODUCTION } = process.env

console.log('process.env.NODE_ENV', process.env.NODE_ENV)

export const getGroups = (chainId) => {

  return [
    { id: 1, name: 'USD' },  // USD
    { id: 2, name: 'ETH' },       // ETH
    { id: 3, name: 'BTC' },     // BTC
    { id: 4, name: chainId === 1 ? 'SolvBTC' : 'Others' }     // Others
  ]
}

// support network list for switch network
export const supportNetworkList = [
  {
    chainId: 176,
    networkId: 82,
    name: 'Meter Mainnet',
    nameInCompoundjs: 'metermain',
    symbol: 'MTR',
    symbolName: 'MTR',
    decimals: 18,
    rpc: 'https://rpc.meter.io',
    readRpc: 'https://rpc.meter.io',
    blockExplorer: 'https://scan.meter.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/82',
    ccipBridge: '',
    ccipChainSelector: '',
    oftBridge: '0xbb5972e940bba6de48cf35e4b8d9389c713037a1',
    subgraph: 'https://graph.meter.io/subgraphs/name/meterio/sumer',
  },
  {
    chainId: 1,
    networkId: 1,
    name: 'Ethereum',
    nameInCompoundjs: 'ethereum',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://eth.llamarpc.com',
    readRpc: 'https://ethereum.blockpi.network/v1/rpc/public',
    blockExplorer: 'https://etherscan.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/1',
    ccipBridge: '',
    ccipChainSelector: '',
    oftBridge: '',
    subgraph: 'https://api.studio.thegraph.com/query/50539/sumer-eth/version/latest',
  },
  {
    chainId: 110,
    networkId: 42161,
    name: 'Arbitrum',
    nameInCompoundjs: 'arbitrum',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://arbitrum.llamarpc.com',
    readRpc: 'https://arbitrum.blockpi.network/v1/rpc/public',
    blockExplorer: 'https://arbiscan.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/42161',
    ccipBridge: '0x12330179cF1168fF6aAC765A8BeAaF7eD8C0459D',
    ccipChainSelector: '4949039107694359620',
    oftBridge: '0x863656e346d8a42ec7caaad606611b6fd8916f32',
    subgraph: 'https://api.studio.thegraph.com/query/50539/sumer-arbitrum/version/latest',
  },
  {
    chainId: 184,
    networkId: 8453,
    name: 'Base',
    nameInCompoundjs: 'basemain',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://base.publicnode.com',
    readRpc: 'https://base.blockpi.network/v1/rpc/public',
    blockExplorer: 'https://basescan.org',
    liquidationBaseUrl: 'https://app.sumer.money/api/8453',
    ccipBridge: '0x7FaeB1dD9E35603dfE427149B809271ea845d579',
    ccipChainSelector: '15971525489660198786',
    oftBridge: '0x9aa55bcf3e41d0d98fce816c4ec6e791b0f6d154',
    subgraph: 'https://api.studio.thegraph.com/query/50539/sumer-base/version/latest',
  },
  {
    chainId: 810180,
    networkId: 810180,
    name: 'zkLink Nova',
    nameInCompoundjs: 'zklink',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://rpc.zklink.io',
    readRpc: 'https://rpc.zklink.io',
    blockExplorer: 'https://explorer.zklink.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/810180',
    ccipBridge: '',
    ccipChainSelector: '',
    oftBridge: '',
    subgraph: '',
  },
  {
    chainId: 80084,
    networkId: 80084,
    name: '	Berachain bArtio',
    nameInCompoundjs: 'berabartio',
    symbol: 'BERA',
    symbolName: 'BERA',
    decimals: 18,
    rpc: 'https://bartio.rpc.berachain.com',
    readRpc: 'https://bartio.rpc.berachain.com',
    blockExplorer: 'https://bartio.beratrail.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/80084',
    isTestnet: true,
    ccipBridge: '',
    ccipChainSelector: '',
    oftBridge: '',
    subgraph: '',
  },
  {
    chainId: 10156,
    networkId: 83,
    name: 'Meter Testnet',
    nameInCompoundjs: 'metertest',
    symbol: 'MTR',
    symbolName: 'MTR',
    decimals: 18,
    rpc: 'https://rpctest.meter.io',
    readRpc: 'https://rpctest.meter.io',
    blockExplorer: 'https://scan-warringstakes.meter.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/83',
    isTestnet: true,
    ccipBridge: '',
    ccipChainSelector: '',
    oftBridge: '0xaC80EAA19bF652F13c03940da6FC6C6b45A029f5',
    subgraph: 'https://graphtest.meter.io/subgraphs/name/meterio/sumer',
  },
  {
    chainId: 10160,
    networkId: 84531,
    name: 'Base Goerli Testnet',
    nameInCompoundjs: 'basetest',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://goerli.base.org',
    readRpc: 'https://goerli.base.org',
    blockExplorer: 'https://goerli.basescan.org',
    liquidationBaseUrl: 'https://app.sumer.money/api/84531',
    isTestnet: true,
    ccipBridge: '0x43D34E476Bf38E83D8E1cBB13322d131de3Dd691',
    ccipChainSelector: '5790810961207155433',
    oftBridge: '0x8f9EC10F71AFC10b123234e470D625713fC59514',
    subgraph: '',
  },
  {
    chainId: 16015286601757825753,
    networkId: 11155111,
    name: 'Sepolia Testnet',
    nameInCompoundjs: 'sepolia',
    symbol: 'ETH',
    symbolName: 'ETH',
    decimals: 18,
    rpc: 'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
    readRpc: 'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
    blockExplorer: 'https://sepolia.etherscan.io',
    liquidationBaseUrl: 'https://app.sumer.money/api/11155111',
    isTestnet: true,
    ccipBridge: '0x2fF01f01a50E55aB7349DD637BB2229cBBe30B89',
    ccipChainSelector: '16015286601757825753',
    oftBridge: '0xd252ec0c597F0B1B41f4eD32EAD6c0B9046142d7',
    subgraph: '',
  },
]

export const getNetworkConfig = (chainId) => {
  let chainIdNum = 0
  if (typeof chainId === 'number') {
    chainIdNum = chainId
  } else if (typeof chainId === 'string' && chainId.startsWith('0x')) {
    chainIdNum = Number(chainId.at, 16)
  } else {
    throw new Error(`invalid chainId ${chainId}`)
  }
  for (const c of supportNetworkList) {
    if (c.networkId === chainIdNum) {
      return c
    }
  }
  console.log(`could not find network config for chainId ${chainId}, return default meter network.`)
  return supportNetworkList.find(net => net.networkId === 82)
  // throw new Error(`could not find network config for chainId ${chainId}`)
}

export const getSupportNetwork = () => {
  console.log('VUE_APP_PRODUCTION', VUE_APP_PRODUCTION)
  if (VUE_APP_PRODUCTION === 'true') {
    return supportNetworkList.filter((net) => !net.isTestnet)
  }
  return supportNetworkList
}

export const links = [
  {
    href: 'https://sumer.money',
    name: 'About',
  },
  {
    href: 'https://docs.sumer.money',
    name: 'Docs',
  },
  {
    href: 'https://twitter.com/SumerMoney',
    name: 'Twitter',
  },
  {
    href: 'https://discord.gg/ggYBw8EuaN',
    name: 'Discord',
  },
]

export const FailureTopic = '0x45b96fe442630264581b197e84bbada861235052c5a1aadfff9ea4e40a969aa0'

const PointsMultiplierMetertest = {
  ['0x8138530487abaCab0aDe79664451cBd7ee8F6225'.toLowerCase()]: [
    // usdx
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0xD30511008f7a2eA14610E736d7d425bFFBbA7085'.toLowerCase()]: [
    // usdc
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x2398633bee182cad2d0388b41735fd9fb742098d'.toLowerCase()]: [
    // usdt
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x6680B3873AE242714e8801b336ad45cE742c5025'.toLowerCase()]: [
    // eth
    '3x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x2bBCF2769292C06AFc39D82E2aF107c1635D16DE'.toLowerCase()]: [
    // btc
    '1x Sumer Points',
    '4x zkLink Points'
  ],
  ['0x70C638683384BD824af6D98CbC51184A2b66f95F'.toLowerCase()]: [
    // mtr
    '1x Sumer Points',
    '4x zkLink Points'
  ],
}

const PointsMultiplierArb = {
  ['0x59aC82d3EfB5dc6c4389ccfF7AB7ab6C72C6AC05'.toLowerCase()]: ['3x Sumer Points'],
  ['0x32be989F762470473878456aB3fB8f6a5bb0205c'.toLowerCase()]: [
    // solv btc
    '2x Sumer Points',
    '6XP Solv Points'
  ],
  ['0x1a9CFA6c676ebBEd450dB3cef03e399465F1202C'.toLowerCase()]: ['3x Sumer Points'],
  ['0x1167e762541374fEBeeA0f6Ed2AD4473AFa1CcEa'.toLowerCase()]: ['3x Sumer Points'],
  ['0x3C752d0D78BbFddA6BF4b6000a01228B732441aE'.toLowerCase()]: ['4x Sumer Points'],
  ['0xd5BDa72030d9531fb311ddFE09aF5502C3492E0c'.toLowerCase()]: [
    // weETH
    '2x Sumer Points',
    '1x EtherFi Points',
    '1x EigenLayer Points',
  ],
  ['0x873449359d2d99691436E724C6C219a39b159B4a'.toLowerCase()]: ['4x Sumer Points'],
  ['0x4DE3741E1676ed14d661b1398196dC221cA4D37A'.toLowerCase()]: ['4x Sumer Points'],
  ['0xDb7Fe9c415281E383595c262e49568DDc18e8Bd4'.toLowerCase()]: ['4x Sumer Points'],
  ['0x142017b52c99d3dFe55E49d79Df0bAF7F4478c0c'.toLowerCase()]: ['2x Sumer Points'],
  ['0x7eCaC6929fdC7f98395857FC8B460f14C6898609'.toLowerCase()]: [
    // PT-weETH-26SEP2024
    '2x Sumer Points',
  ],
  ['0xaec7D67D07e1f5833a8587fDcb0b7FE50347a8F5'.toLowerCase()]: [
    // PT-rsETH-26SEP2024
    '2x Sumer Points',
  ],
  ['0xEe67DB245248BDC84a6634e9A3e30FF78Eeb6179'.toLowerCase()]: [
    // rsETH
    '2x Sumer Points',
    '1x Kelp Miles',
    '1x EigenLayer Points',
  ],
}

const PointsMultiplierBase = {
  ['0x6345aF6dA3EBd9DF468e37B473128Fd3079C4a4b'.toLowerCase()]: ['3x Sumer Points'],
  ['0x238d804Cb1F4c0c7495e7b7773c54D75E4C99cdd'.toLowerCase()]: ['3x Sumer Points'],
  ['0x2AA93D3142d7327307b770Dba2e87C97b86B95Bc'.toLowerCase()]: ['4x Sumer Points'],
  ['0xb330cae9C6207dF60C2785b29F775DEC0FE20026'.toLowerCase()]: ['2x Sumer Points'],
  ['0xA4578AB5CDA88AaE7603aFAB24b4c0d24a7858D1'.toLowerCase()]: ['4x Sumer Points'],
  ['0x3389eD4dd777b03B95deb2994ACaF6807cf24c2E'.toLowerCase()]: ['4x Sumer Points'],
  ['0x142017b52c99d3dFe55E49d79Df0bAF7F4478c0c'.toLowerCase()]: ['4x Sumer Points'],
}

const PointsMultiplierETH = {
  ['0x42778d0962884510b85d4D1B30DFe9e9Dd270446'.toLowerCase()]: [
    // eth
    '4x Sumer Points',
  ],
  ['0xdCA98947c3c9cf0B3CF448b6A03f991598Fb9460'.toLowerCase()]: [
    // wbtc
    '3x Sumer Points',
  ],
  ['0x1FF86e97b273dE2b1D42F0FDD5Ea7350A66c4857'.toLowerCase()]: [
    // reth
    '3x Sumer Points',
  ],
  ['0x15B5220024c3242F7D61177D6ff715cfac4909eD'.toLowerCase()]: [
    // wsteth
    '3x Sumer Points',
  ],
  ['0x23811C17BAc40500deCD5FB92d4FEb972aE1E607'.toLowerCase()]: [
    // pufeth
    '2x Sumer Points',
    '1x Puffer Points',
    '1x EigenLayer Points',
  ],
  ['0x61561B2E01C69C2906735866C94Cc4a33bB71c85'.toLowerCase()]: [
    // rseth
    '2x Sumer Points',
    '1x Kelp Miles',
    '1x EigenLayer Points',
  ],
  ['0xBc6590A7b15513e4D649b158393175a839F27ED8'.toLowerCase()]: [
    // weeth
    '2x Sumer Points',
    '1x EtherFi Points',
    '1x EigenLayer Points',
  ],
  ['0x5096E5cf4f151052ACD615b2635E7FdB6Db0763C'.toLowerCase()]: [
    // dai
    '2x Sumer Points',
  ],
  ['0xe3502f1c2450Ed1Bb87B87a84AF6742F60f41368'.toLowerCase()]: [
    // usdt
    '4x Sumer Points',
  ],
  ['0x3d592e26050e132Ee3D1504aca74f0F4Ed75e5cC'.toLowerCase()]: [
    // usdc
    '4x Sumer Points',
  ],
  ['0xF5d682D42e16550Cc5D8f48193243103D2CeAF0a'.toLowerCase()]: [
    // usde
    '2x Sumer Points',
  ],
  ['0x549D0CdC753601fbE29f9DE186868429a8558E07'.toLowerCase()]: [
    // susde
    '2x Sumer Points',
  ],
  ['0xE550a6f792a8B6C07555378EA74063021885A33e'.toLowerCase()]: [
    // pt weeth
    '2x Sumer Points',
  ],
  ['0xa46D0328Dfa5822d3E9B4423E2A0A73467c2d2d5'.toLowerCase()]: [
    // pt susde
    '2x Sumer Points',
  ],
  ['0xf7BB299dc8e627eaEc4282FFc236E085aef8FAF3'.toLowerCase()]: [
    // pt pufeth
    '2x Sumer Points',
  ],
  ['0x86208Af42580823401B504B341150c92CC99C69A'.toLowerCase()]: [
    // pt pufeth
    '2x Sumer Points',
  ],
  ['0xA9Ea90899fA648b4Ce49f6aE28174AEAda660118'.toLowerCase()]: [
    // solv btc
    '2x Sumer Points',
    '6XP Solv Points'
  ],
  ['0x5573Fc3650d2a38D1C83faDf682bC379CFAcCFA1'.toLowerCase()]: [
    // solv btc bbn
    '2x Sumer Points',
    '12XP Solv Points'
  ],
}

const PointsMultiplierMeter = {
  ['0x52e245606Dd37A9addcD2Bb523d319596eB4b851'.toLowerCase()]: [
    // eth
    '4x Sumer Points',
    '1x Meter Points',
  ],
  ['0x6Ff2fB3F8F175df0A39C344de81A4aaaB05c49eB'.toLowerCase()]: [
    // mtrg
    '4x Sumer Points',
    '1x Meter Points',
  ],
  ['0x318F6863a8c516dF9C5941AEb328A40f15f525F9'.toLowerCase()]: [
    // wstmtrg
    '4x Sumer Points',
    '1x Meter Points',
  ],
  ['0xC211a250FC30122ee107aC9Acf6686110339520f'.toLowerCase()]: [
    // usdc
    '4x Sumer Points',
    '1x Meter Points',
  ],
  ['0xaCEDC263FF6De824B38cAeAAC1FD877b1a7344a1'.toLowerCase()]: [
    // usdt
    '4x Sumer Points',
    '1x Meter Points',
  ],
}

const PointsMultiplierZklink = {
  ['0x54Dfae480e33dC2BEfd42CAA26A432b11b5a27Bd'.toLowerCase()]: [
    // eth
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x22203549A2Fe6740A3D85f8ed2cdfDE16464B68B'.toLowerCase()]: [
    // usdc
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x45f832bd90b50A97f494A5578ea038CaD03C2b3b'.toLowerCase()]: [
    // usdt
    '4x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x450665dA7c7b72302Ac6fB6b1B20de7bCF38F7c1'.toLowerCase()]: [
    // wbtc
    '3x Sumer Points',
    '20x zkLink Points'
  ],
  ['0x2440414bc0Bf6f7E76CB2f2C5051EdC4a940d441'.toLowerCase()]: [
    // mbtc
    '1x Sumer Points',
    '4x zkLink Points'
  ],
  ['0x22c3E783AC857E205523b59EFC4739949f93EE81'.toLowerCase()]: [
    // solv btc
    '2x Sumer Points',
    '4x zkLink Points'
  ],
  ['0xE143694271a0b34ea6C7928d9B66d31f60B2BfeD'.toLowerCase()]: [
    // puf eth
    '2x Sumer Points',
    '4x zkLink Points',
    '1x Puffer Points',
    '1x EigenLayer Points'
  ],
  ['0x03979c0Fba55EA5A8022c79951fEED1c4a3C8324'.toLowerCase()]: [
    // stone
    '2x Sumer Points',
    '4x zkLink Points'
  ],
  ['0x67CF127b95A6B957aeDEA5B15626311245167979'.toLowerCase()]: [
    // manta
    '2x Sumer Points',
    '4x zkLink Points'
  ]
}

export const PointsMultiplier = {
  ...PointsMultiplierMetertest,
  ...PointsMultiplierArb,
  ...PointsMultiplierBase,
  ...PointsMultiplierETH,
  ...PointsMultiplierMeter,
  ...PointsMultiplierZklink,
}

export const upgradingNetwork = []
