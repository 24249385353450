// import { chains } from '@/constants/chains'
import { _ethers, getTokenPairs, getAddress } from '@meterio/sumer-js'
import * as Sumer from '@meterio/sumer-js'
import { getCCIPSendFee, getSendFee, sendFrom } from '../../api'
import BigNumber from 'bignumber.js'
import { ZERO_ADDRESS, getSupportNetwork } from '../../constants'

const { formatUnits } = _ethers

const namespaced = true

const state = {
  currentFromChain: {
    symbol: '',
  },
  currentToChain: {},
  fromChains: [],
  toChains: [],
  currentFromTokens: [],
  currentToTokens: [],
  currentFromToken: {
    symbol: '',
  },
  currentToToken: {},

  maxTokenBalance: '',

  bridgeFee: 0,

  loading: false,

  errorInfo: '',
}

const getters = {}

const mutations = {
  setCurrentFromChain(state, currentFromChain) {
    state.currentFromChain = currentFromChain
  },
  setCurrentToChain(state, currentToChain) {
    state.currentToChain = currentToChain
  },
  setFromChains(state, fromChains) {
    state.fromChains = fromChains
  },
  setToChains(state, toChains) {
    state.toChains = toChains
  },
  setCurrentToken(state, currentFromToken) {
    if (!currentFromToken) {
      state.currentFromToken = {}
      state.currentToToken = {}
      return
    }
    state.currentFromToken = currentFromToken
    const currentToToken = state.currentToTokens.find((item) => item.symbol === currentFromToken.symbol)
    state.currentToToken = currentToToken
  },
  setCurrentFromTokens(state, currentFromTokens) {
    state.currentFromTokens = currentFromTokens
  },
  setCurrentToTokens(state, currentToTokens) {
    state.currentToTokens = currentToTokens
  },
  setMaxTokenBalance(state, maxTokenBalance) {
    state.maxTokenBalance = maxTokenBalance
  },

  setBridgeFee(state, bridgeFee) {
    state.bridgeFee = bridgeFee
  },
  setLoading(state, loading) {
    state.loading = loading
  },

  setErrorInfo(state, info) {
    state.errorInfo = info
  },
}

const actions = {
  initChain({ rootState, state, commit, dispatch }) {
    commit('setErrorInfo', '')
    const { sumer, currentNetwork } = rootState.wallet
    const tempChains = []
    // console.log('token pairs', getTokenPairs(sumer._network.name))
    const chains = getSupportNetwork()
      .filter((net) => !!net.isTestnet === !!currentNetwork.isTestnet)
      .map((s) => {
        const tokens = getTokenPairs(s.nameInCompoundjs)
          .filter((t) => t.underlySym.includes('su'))
          .map((t) => {
            const address = getAddress(s.nameInCompoundjs, t.underlySym)
            return {
              address,
              name: t.underlySym,
              symbol: t.underlySym,
              decimals: t.underlyDecimal,
            }
          })
        const SumerAddr = getAddress(s.nameInCompoundjs, 'SUMER')
        tokens.push({
          address: SumerAddr,
          name: 'SUMER',
          symbol: 'SUMER',
          decimals: 18,
        })
        return {
          ...s,
          tokens,
        }
      })

    if (chains.length === 1) {
      console.log('no destination chains')
      commit('setErrorInfo', 'Stay tuned')
      return
    }
    for (const c of chains) {
      if (currentNetwork.networkId === c.networkId) {
        commit('setCurrentFromChain', c)
      } else {
        tempChains.push(c)
      }
    }

    const currentToChain = tempChains[0]
    commit('setCurrentToChain', currentToChain)

    commit('setFromChains', [...tempChains])
    commit('setToChains', tempChains.splice(1))

    dispatch('updateCurrentTokens')
  },
  updateCurrentToChain({ rootState, state, commit, dispatch }, currentToChain) {
    const tempToChains = state.fromChains.filter((c) => c.networkId !== currentToChain.networkId)

    commit('setCurrentToChain', currentToChain)
    commit('setToChains', tempToChains)
    dispatch('updateCurrentTokens')
  },
  async updateCurrentTokens({ rootState, state, commit, dispatch }) {
    const { currentFromChain, currentToChain } = state
    const fromTokens = currentFromChain.tokens
    const toTokens = currentToChain.tokens
    const currentFromTokens = []
    const currentToTokens = []
    for (const f of fromTokens) {
      for (const t of toTokens) {
        if (f.symbol === t.symbol) {
          currentFromTokens.push(f)
          currentToTokens.push(t)
          break
        }
      }
    }

    if (currentFromChain.networkId === 11155111 && currentToChain.networkId === 84531) {
      currentFromTokens.push(
        {
          address: '0xFd57b4ddBf88a4e07fF4e34C487b99af2Fe82a05',
          decimals: 18,
          name: 'CCIP-BnM',
          symbol: 'CCIP-BnM',
        },
        {
          address: '0x466D489b6d36E7E3b824ef491C225F5830E81cC1',
          decimals: 18,
          name: 'CCIP-LnM',
          symbol: 'CCIP-LnM',
        },
      )

      currentToTokens.push(
        {
          address: '0xbf9036529123de264bfa0fc7362fe25b650d4b16',
          decimals: 18,
          name: 'CCIP-BnM',
          symbol: 'CCIP-BnM',
        },
        {
          address: '0x73ed16c1a61b098fd6924cce5cc6a9a30348d944',
          decimals: 18,
          name: 'CCIP-LnM',
          symbol: 'CCIP-LnM',
        },
      )
    }
    if (currentFromChain.networkId === 84531 && currentToChain.networkId === 11155111) {
      currentFromTokens.push(
        {
          address: '0xbf9036529123de264bfa0fc7362fe25b650d4b16',
          decimals: 18,
          name: 'CCIP-BnM',
          symbol: 'CCIP-BnM',
        },
        {
          address: '0x73ed16c1a61b098fd6924cce5cc6a9a30348d944',
          decimals: 18,
          name: 'CCIP-LnM',
          symbol: 'CCIP-LnM',
        },
      )

      currentToTokens.push(
        {
          address: '0xFd57b4ddBf88a4e07fF4e34C487b99af2Fe82a05',
          decimals: 18,
          name: 'CCIP-BnM',
          symbol: 'CCIP-BnM',
        },
        {
          address: '0x466D489b6d36E7E3b824ef491C225F5830E81cC1',
          decimals: 18,
          name: 'CCIP-LnM',
          symbol: 'CCIP-LnM',
        },
      )
    }

    commit('setCurrentFromTokens', currentFromTokens)
    commit('setCurrentToTokens', currentToTokens)

    const cft = currentFromTokens[0]
    dispatch('updateCurrentToken', { currentToken: cft })
  },
  async updateCurrentToken({ rootState, commit, dispatch }, { currentToken }) {
    commit('setCurrentToken', currentToken)

    dispatch('getTokenBalance')
  },
  async getTokenBalance({ rootState, state, commit }) {
    const { currentFromToken } = state
    const { web3Provider, account } = rootState.wallet
    if (!currentFromToken.symbol || !account) {
      return
    }

    try {
      const contract = new _ethers.Contract(currentFromToken.address, Sumer.abi.CErc20, web3Provider)
      const b = await contract.balanceOf(account)
      const formatBalance = formatUnits(b, currentFromToken.decimals)
      commit('setMaxTokenBalance', formatBalance)
    } catch (e) {
      console.log('get token balance err', e)
    }
  },
  async transfer({ rootState, state, commit, dispatch }, { amount }) {
    try {
      commit('setLoading', true)

      const { sumer, account } = rootState.wallet
      const { currentFromChain, currentFromToken, currentToChain, bridgeFee } = state

      if (currentFromChain.ccipBridge && currentToChain.ccipBridge) {
        await dispatch(
          'tx/bridgeByCCIP',
          {
            symbol: currentFromToken.symbol,
            decimals: currentFromToken.decimals,
            ccipBridgeAddr: currentFromChain.ccipBridge,
            destinationChainSelector: currentToChain.ccipChainSelector,
            receiver: account,
            tokenAddress: currentFromToken.address,
            amount,
            value: new BigNumber(bridgeFee).times(1e18).toFixed(0),
          },
          { root: true },
        )
      } else {
        await dispatch(
          'tx/sendFrom',
          {
            symbol: currentFromToken.symbol,
            tokenAddress: currentFromToken.address,
            value: new BigNumber(bridgeFee).times(1e18).toFixed(0),
            toAddress: account,
            dstChainId: currentToChain.chainId,
            amount: new BigNumber(amount).times(1e18).toFixed(0),
          },
          { root: true },
        )
      }
    } catch (e) {
      commit('setLoading', false)
    } finally {
      commit('setLoading', false)
    }
  },
  async getBridgeFee({ rootState, state, commit }, { amount }) {
    if (!amount || isNaN(Number(amount))) {
      return
    }
    try {
      const { sumer, account } = rootState.wallet

      if (!sumer || !account) {
        return
      }
      const { currentFromChain, currentFromToken, currentToChain } = state

      let nativeFee = 0
      if (currentFromChain.ccipBridge && currentToChain.ccipBridge) {
        const res = await getCCIPSendFee(sumer, {
          ccipBridgeAddr: currentFromChain.ccipBridge,
          destinationChainSelector: currentToChain.ccipChainSelector,
          receiver: account,
          tokenAddress: currentFromToken.address,
          amount: new BigNumber(amount).times(1e18).toFixed(0),
        })
        console.log('ccip fee', res)
        nativeFee = res
      } else {
        const res = await getSendFee(sumer, {
          oftAddress: currentFromChain.oftBridge,
          tokenAddress: currentFromToken.address,
          toAddress: account,
          dstChainId: currentToChain.chainId,
          amount: new BigNumber(amount).times(1e18).toFixed(0),
        })
        nativeFee = res.nativeFee
      }

      const fee = new BigNumber(String(nativeFee)).div(1e18).toFixed()
      console.log('bridge fee', fee)

      commit('setBridgeFee', fee)
    } catch (e) {
      console.log('get bridge fee', e)
    }
  },
}

export const suTokenBridge = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
