<template>
  <div>
    <router-view />

    <wallet-board :chains="computedChains" @wallets="getWallets" @disconnected="disconnected" />
  </div>
</template>

<script>
import WalletBoard from '@/WalletBoard'

import { mapState, mapActions } from 'vuex'

import { getSupportNetwork } from '@/constants'
import { ethers } from 'ethers'
export default {
  name: 'App',
  components: {
    WalletBoard,
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapState('wallet', ['sumer', 'isWalletReset']),
    ...mapState('tx', ['txHashs']),
    ...mapState('loading', ['systemLoading']),

    isTx() {
      if (this.systemLoading) {
        return true
      }

      if (this.isWalletReset) {
        return true
      }

      let b = false
      for (const item of Object.keys(this.txHashs)) {
        if (this.txHashs[item] !== '') {
          b = true
          break
        }
        b = false
      }
      return b
    },
    computedChains() {
      return getSupportNetwork().map((net) => {
        return {
          id: `0x${Number(net.networkId).toString(16)}`,
          token: net.symbol,
          label: net.name,
          rpcUrl: net.rpc,
          publicRpcUrl: net.rpc,
          blockExplorerUrl: net.blockExplorer,
        }
      })
    },
  },
  watch: {
    // isTx(isTx) {
    //   if (!isTx) {
    //     this.loopInitData()
    //   } else {
    //     this.pauseLoopInitData()
    //   }
    // },
    // sumer(val) {
    //   if (val) {
    //     this.initData()
    //   }
    // }
  },
  async created() {
    // const provider = await detectEthereumProvider()
    // if (provider) {
    //   await this.$store.dispatch(`wallet/${CONNECT_METAMASK}`, { provider })
    // }
    this.initWallet({
      accounts: [
        {
          address: ethers.ZeroAddress,
        },
      ],
      chainId: 82,
      provider: new ethers.JsonRpcProvider('https://rpc.meter.io'),
      icon: '',
    })
  },
  methods: {
    ...mapActions({
      initWallet: 'wallet/initWallet',
    }),
    loopInitData() {
      this.timer = setTimeout(() => {
        this.initData()
      }, 1000 * 60)
    },
    pauseLoopInitData() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    getWallets(wallets) {
      const { accounts, chains, provider, icon, label } = wallets[0]

      const chainId = Number(chains[0].id)

      // const currentNetwork = getSupportNetwork().find((net) => net.networkId === chainId)

      this.initWallet({ accounts: accounts, chainId, provider, icon })

      // if (!currentNetwork) {
      //   this.$store.commit('modal/SHOW_CHECK_NETWORK')
      // }
    },
    disconnected() {
      this.initWallet({ disconnected: true })
    },
  },
}
</script>

<style lang="scss">
@import './style/_variable';

.v-application {
  // font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  // color: $black;
  font-size: $font-sm;
}
.font-size-xs {
  font-size: $font-xs;
}
.font-size-sm {
  font-size: $font-sm;
}
.font-size-lg {
  font-size: $font-lg;
}
.text-color-light {
  color: lighten(#716040, 25%);
}
.row + .row {
  margin-top: 12px !important;
}
.text-color {
  color: #716040 !important;
}
.text-error {
  color: #d2463a;
}

.h-100 {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

// :root {
//   --onboard-modal-z-index: 9999;
// }

.highlight {
  position: relative;
  z-index: 101;
}
</style>
